import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfigurationCardComponent } from './components/configuration-card.component';
import { ConfigurationContainerComponent } from './configuration.container';
import { NgxPermissionsModule } from 'ngx-permissions';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../../../../../material.module';
import { UploadConfigurationComponent } from './components/upload-configuration-dialog.component';

@NgModule({
  declarations: [ConfigurationContainerComponent, ConfigurationCardComponent, UploadConfigurationComponent],
  imports: [
    CommonModule,
    MaterialModule,
    NgxPermissionsModule.forChild(),
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [ConfigurationContainerComponent, ConfigurationCardComponent, ],
  entryComponents: [UploadConfigurationComponent]
})
export class ConfigurationModule { }
