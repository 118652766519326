import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, take, finalize } from 'rxjs/operators';

import { LicenceService } from './request/licence.service';
import { UserGroup } from 'src/app/models/user-group.model';
import { IdentityService } from './request/identity.service';
import { User } from 'src/app/models/user.model';
import { UserDetailData } from 'src/app/models/user-detail-data.model';
import { AuthorizationService } from './request/auth.service';
import { LicenceGroup } from 'src/app/models/licence-group.model';
import { LicenceGroupService } from './request/licence-group.service';
import { UserGroupService } from './request/user-group.service';
import { UserService } from './request/user.service';
import { RoleService } from './request/role.service';
import { Role } from 'src/app/models/role.model';
import { Provider } from 'src/app/models/provider.model';
import { ActiveDirectoryService } from './request/active-directory.service';
import { Licence } from 'src/app/models/licence.model';
import { FileInput } from 'ngx-material-file-input';

@Injectable({
  providedIn: 'root'
})

// TODO: this is a move away from having to manage the data and state all over the place
// it is not perfect and in many cases uses a hammer instead of a scalpel...to be worked on.
// At least it is in one spot right?

// Note: the observable subscriptions that come from the services are http calls
// that should auto unsubscribe when complete.
export class AdminDataService {

  private _userGroupDetails: BehaviorSubject<UserGroup[]>;
  private _currentSelectedUserGroupDetail: BehaviorSubject<UserGroup>;
  private _licence: BehaviorSubject<Licence>;
  private _licenceGroupDetails: BehaviorSubject<LicenceGroup[]>;
  private _roles: BehaviorSubject<Role[]>;
  private _currentSelectedLicenceGroupDetail: BehaviorSubject<LicenceGroup>;
  private _currentLicenceGroupUsers: BehaviorSubject<UserDetailData[]>;
  private _currentUserGroupUsers: BehaviorSubject<UserDetailData[]>;
  private _currentLicenceUserGroups: BehaviorSubject<UserGroup[]>;
  private _userDetails: BehaviorSubject<UserDetailData[]>;
  private _currentSelectedUserDetail: BehaviorSubject<UserDetailData>;
  private _providers: BehaviorSubject<Provider[]>;
  private _currentProvider: BehaviorSubject<Provider>;
  private _currentUserLicenceGroups: BehaviorSubject<LicenceGroup[]>;
  private _currentUserGroupLicenceGroups: BehaviorSubject<LicenceGroup[]>;
  private _currentUserUserGroups: BehaviorSubject<UserGroup[]>;

  private dataStore: {
    userGroupDetails: UserGroup[],
    currentSelectedUserGroupDetail: UserGroup,
    licence: Licence,
    licenceGroupDetails: LicenceGroup[],
    currentSelectedLicenceGroupDetail: LicenceGroup,
    userDetails: UserDetailData[],
    groupDetails: UserGroup[],
    currentSelectedUserDetail: UserDetailData,
    currentSelectedGroupDetail: UserGroup,
    roles: Role[],
    providers: Provider[],
    currentProvider: Provider,
    currentUserGroups: UserGroup[],
    currentUserLicenceGroups: LicenceGroup[],
    currentUserGroupLicenceGroups: LicenceGroup[],
    currentUserUserGroups: UserGroup[],
    currentLicenceGroupUsers: UserDetailData[],
    currentLicenceUserGroups: UserGroup[],
    currentUserGroupUsers: UserDetailData[],
  };

  constructor(
    private authService: AuthorizationService,
    private userService: UserService,
    private licenceGroupService: LicenceGroupService,
    private userGroupService: UserGroupService,
    private roleService: RoleService,
    private licenceService: LicenceService,
    private identityService: IdentityService,
    public activeDirectoryService: ActiveDirectoryService) {
    this._userGroupDetails = <BehaviorSubject<UserGroup[]>>new BehaviorSubject([]);
    this._currentSelectedUserGroupDetail = <BehaviorSubject<UserGroup>>new BehaviorSubject(null);
    this._licence = <BehaviorSubject<Licence>>new BehaviorSubject(null);
    this._licenceGroupDetails = <BehaviorSubject<LicenceGroup[]>>new BehaviorSubject([]);
    this._roles = <BehaviorSubject<Role[]>>new BehaviorSubject([]);
    this._providers = <BehaviorSubject<Provider[]>>new BehaviorSubject([]);
    this._currentProvider = <BehaviorSubject<Provider>>new BehaviorSubject(null);
    this._currentUserUserGroups = <BehaviorSubject<UserGroup[]>>new BehaviorSubject([]);
    this._currentSelectedLicenceGroupDetail = <BehaviorSubject<LicenceGroup>>new BehaviorSubject(null);
    this._userDetails = <BehaviorSubject<UserDetailData[]>>new BehaviorSubject([]);
    this._currentSelectedUserDetail = <BehaviorSubject<UserDetailData>>new BehaviorSubject(null);
    this._currentUserLicenceGroups = <BehaviorSubject<LicenceGroup[]>>new BehaviorSubject([]);
    this._currentUserGroupLicenceGroups = <BehaviorSubject<LicenceGroup[]>>new BehaviorSubject([]);
    this._currentLicenceGroupUsers = <BehaviorSubject<UserDetailData[]>>new BehaviorSubject([]);
    this._currentLicenceUserGroups = <BehaviorSubject<UserGroup[]>>new BehaviorSubject([]);
    this._currentUserGroupUsers = <BehaviorSubject<UserDetailData[]>>new BehaviorSubject(null);

    this.dataStore = {
      userGroupDetails: [],
      currentSelectedUserGroupDetail: null,
      licence: null,
      licenceGroupDetails: [],
      currentSelectedLicenceGroupDetail: null,
      userDetails: [],
      groupDetails: [],
      currentSelectedGroupDetail: null,
      currentSelectedUserDetail: null,
      roles: [],
      providers: [],
      currentProvider: null,
      currentUserGroups: [],
      currentUserLicenceGroups: [],
      currentUserGroupLicenceGroups: [],
      currentUserUserGroups: [],
      currentLicenceGroupUsers: [],
      currentLicenceUserGroups: [],
      currentUserGroupUsers: [],
    };
  }

  initialize() {
    this.authService.hasPermission('groups:*').then(hasPermission => {
      if (hasPermission) {

        this.roleService.initialize()
          .pipe(take(1))
          .subscribe(roles => {
            if (roles) {
              this.updateRoles(roles);
            }
          });

        this.identityService.initialize()
          .pipe(take(1))
          .subscribe(providers => {
            if (providers) {
              this.updateProviders(providers);
            }
          });

        this.licenceGroupService.initialize()
          .pipe(take(1))
          .subscribe(licenceGroups => {
            if (licenceGroups) {
              this.updateLicenceGroupDetails(licenceGroups);
            }
          });

        this.userGroupService.initialize()
          .pipe(take(1))
          .subscribe(userGroups => {
            if (userGroups) {
              this.updateUserGroupDetails(userGroups);
            }
          });

        this.licenceService.initialize()
          .pipe(take(1))
          .subscribe(licence => {
            if (licence) {
              this.updateLicenceDetails(licence);
            }
          });

        this.userService.initialize()
          .pipe(take(1))
          .subscribe(users => {
            if (users) {
              this.updateUserDetails(users);
            }
          });
      }
    });

  }

  setCurrentSelectedLicenceGroupDetails(group: LicenceGroup) {
    this.dataStore.currentSelectedLicenceGroupDetail = group;
    this._currentSelectedLicenceGroupDetail.next(Object.assign({}, this.dataStore).currentSelectedLicenceGroupDetail);
    if (group) {
      this.userService.getAllActiveUserData().subscribe(users => {
        this.dataStore.currentLicenceGroupUsers = users.filter(user => user.licenceGroups.find(g => g.id === group.id));
        this._currentLicenceGroupUsers.next(Object.assign({}, this.dataStore).currentLicenceGroupUsers);
      });

      this.dataStore.currentLicenceUserGroups = this.getLicenceUserGroups(group.id);
      this._currentLicenceUserGroups.next(Object.assign({}, this.dataStore).currentLicenceUserGroups);
    }
  }

  setCurrentSelectedUserGroupDetails(group: UserGroup) {
    this.dataStore.currentSelectedUserGroupDetail = group;
    this._currentSelectedUserGroupDetail.next(Object.assign({}, this.dataStore).currentSelectedUserGroupDetail);

    if (group) {
      this.getUserGroupUsers(group.id).pipe(take(1)).subscribe(users => {
        this.dataStore.currentUserGroupUsers = users;
        this._currentUserGroupUsers.next(Object.assign({}, this.dataStore).currentUserGroupUsers);
      });

      this.dataStore.currentUserGroupLicenceGroups = this.getUserGroupLicenceGroups(group);
      this._currentUserGroupLicenceGroups.next(Object.assign({}, this.dataStore).currentUserGroupLicenceGroups);
    }
  }


  setCurrentSelectedUserDetails(user: UserDetailData) {
    if (user) {

      this.dataStore.currentProvider = this.getProvider(user.directoryId);
      this._currentProvider.next(Object.assign({}, this.dataStore).currentProvider);

      this.dataStore.currentSelectedUserDetail = user;
      this._currentSelectedUserDetail.next(Object.assign({}, this.dataStore).currentSelectedUserDetail);

      this.dataStore.currentUserLicenceGroups = this.getLicenceGroups(user);
      this._currentUserLicenceGroups.next(Object.assign({}, this.dataStore).currentUserLicenceGroups);

      this.dataStore.currentUserUserGroups = this.getUserUserGroups(user);
      this._currentUserUserGroups.next(Object.assign({}, this.dataStore).currentUserUserGroups);
    }
  }



  private updateRoles(roles: Role[]) {
    this.dataStore.roles = roles;
    this._roles.next(Object.assign({}, this.dataStore).roles);
  }

  private updateProviders(providers: Provider[]) {
    this.dataStore.providers = providers;
    this._providers.next(Object.assign({}, this.dataStore).providers);
  }

  private updateLicenceDetails(licence: Licence) {
    this.dataStore.licence = licence;
    this._providers.next(Object.assign({}, this.dataStore).providers);
    this._licence.next(Object.assign({}, this.dataStore).licence);
  }

  private updateLicenceGroupDetails(details: LicenceGroup[]) {
    const previouslySelected: LicenceGroup = this.dataStore.currentSelectedLicenceGroupDetail;

    this.dataStore.licenceGroupDetails = details;
    this._licenceGroupDetails.next(Object.assign({}, this.dataStore).licenceGroupDetails);

    if (this.dataStore.currentSelectedLicenceGroupDetail != null && details != null) {
      const currentSelected = details.find(x => x.id === this.dataStore.currentSelectedLicenceGroupDetail.id);
      if (previouslySelected && currentSelected) {
        currentSelected.users = previouslySelected.users;  // TODO: Workaround for server not having...change it
      }
      this.setCurrentSelectedLicenceGroupDetails(currentSelected);
    }
  }

  private updateUserGroupDetails(userGroups: UserGroup[]) {
    const previouslySelected: UserGroup = this.dataStore.currentSelectedUserGroupDetail;
    if (userGroups) {
      userGroups.forEach(group => {
        this.getUserGroupUsers(group.id).pipe(take(1)).subscribe(users => {
          group.users = users;
        });
      });

      this.dataStore.userGroupDetails = userGroups; // updates from above subscription...not ideal setup
      this._userGroupDetails.next(Object.assign({}, this.dataStore).userGroupDetails);

      if (this.dataStore.currentSelectedUserGroupDetail != null) {
        const currentSelected = this.dataStore.userGroupDetails.find(x => x.id === this.dataStore.currentSelectedUserGroupDetail.id);
        if (previouslySelected && currentSelected) {
          currentSelected.id = previouslySelected.id;
          currentSelected.users = previouslySelected.users;
        }
        this.setCurrentSelectedUserGroupDetails(currentSelected);
      }
    }
  }

  private updateUserDetails(details: UserDetailData[]) {
    this.dataStore.userDetails = details;
    this._userDetails.next(Object.assign({}, this.dataStore).userDetails);
    if (this.dataStore.currentSelectedUserDetail != null && details != null) {
      this.setCurrentSelectedUserDetails(details.find(x => x.emailAddress === this.dataStore.currentSelectedUserDetail.emailAddress));
    }
  }

  getProvider(id: number): Provider {
    if (this.dataStore.providers) {
      return this.dataStore.providers.find(p => p.id === id);
    }

    return null;
  }

  private getLicenceGroups(user: UserDetailData): LicenceGroup[] {
    const filteredUserLicenceGroupArray: LicenceGroup[] = [];
    if (this.dataStore.licenceGroupDetails) {
      this.dataStore.licenceGroupDetails.filter(licenceGroup => {
        if (user.licenceGroups && user.licenceGroups.find(group => group.id === licenceGroup.id)) {
          filteredUserLicenceGroupArray.push(licenceGroup);
        }
      });
    }
    return filteredUserLicenceGroupArray;
  }

  private getUserUserGroups(user: UserDetailData): UserGroup[] {
    const filteredUserGroupArray: UserGroup[] = [];
    if (this.dataStore.userGroupDetails) {
      this.dataStore.userGroupDetails.forEach(group => {
        if (group.users && group.users.some(groupUser => groupUser.emailAddress === user.emailAddress)) {
          filteredUserGroupArray.push(group);
        }
      });
    }
    return filteredUserGroupArray;
  }

  private getUserGroupLicenceGroups(userGroup: UserGroup): LicenceGroup[] {
    const filteredGroupLicenceArray: LicenceGroup[] = [];
    if (this.dataStore.licenceGroupDetails && userGroup.licenceGroups !== null) {
      this.dataStore.licenceGroupDetails.filter(licenceGroup => {
        if (userGroup.licenceGroups.find(group => group.id === licenceGroup.id)) {
          filteredGroupLicenceArray.push(licenceGroup);
        }
      });
    }
    return filteredGroupLicenceArray;
  }

  private getLicenceUserGroups(groupId: number): UserGroup[] {
    const filteredUserGroupArray: UserGroup[] = [];
    if (this.dataStore.userGroupDetails) {
      this.dataStore.userGroupDetails.filter(userGroup => {
        if (userGroup.licenceGroups && userGroup.licenceGroups.find(group => group.id === groupId)) {
          filteredUserGroupArray.push(userGroup);
        }
      });
    }
    return filteredUserGroupArray;
  }


  /*TODO: these are used to keep data in sync...in
  a lot of cases modifying in the store would be better
  than hitting server.  It is a shortcut and should dbe worked out.*/

  refreshUserActivity() {
    this.refreshUserDetails();
    this.refreshLicenceGroupDetails();
  }

  refreshLicenceState() {
    this.authService.hasPermission('groups:*').then(hasPermission => {
      if (hasPermission) {
        this.refreshUserDetails();
        this.refreshLicenceGroupDetails();
        this.refreshLicence();
      }
    });
  }

  private refreshUserGroupDetails() {
    this.userGroupService.getUserGroups().subscribe((data) => {
      this.updateUserGroupDetails(data);
    });
  }

  private refreshUserDetails() {
    this.userService.getAllActiveUserData().subscribe((data) => {
      this.updateUserDetails(data);
    });
  }

  private refreshLicenceGroupDetails() {
    this.licenceService.getLicenceGroups().subscribe((data) => {
      this.updateLicenceGroupDetails(data);
    });
  }

  private refreshLicence() {
    this.licenceService.getCurrentLicence().subscribe((licence) => {
      this.updateLicenceDetails(licence);
    });
  }

  private refreshProviders() {
    this.identityService.getAvailableProviders().subscribe((data) => {
      this.updateProviders(data);
    });
  }

  private getUserGroupUsers(id: number): Observable<UserDetailData[]> {
    return this.userGroupService.getUserGroupUsers(id);
  }

  get licenceGroupDetails() {
    return this._licenceGroupDetails.asObservable();
  }

  get licence() {
    return this._licence.asObservable();
  }

  get roles() {
    return this._roles.asObservable();
  }

  get providers() {
    return this._providers.asObservable();
  }

  get userDetails() {
    return this._userDetails.asObservable();
  }

  get currentSelectedLicenceGroupDetails() {
    return this._currentSelectedLicenceGroupDetail.asObservable();
  }

  get currentUserGroupUsers() {
    return this._currentUserGroupUsers.asObservable();
  }

  get currentLicenceGroupUsers() {
    return this._currentLicenceGroupUsers.asObservable();
  }

  get currentLicenceUserGroups() {
    return this._currentLicenceUserGroups.asObservable();
  }

  get currentSelectedUserDetails() {
    return this._currentSelectedUserDetail.asObservable();
  }

  get currentUserProvider() {
    return this._currentProvider.asObservable();
  }

  get currentUserLicenceGroups() {
    return this._currentUserLicenceGroups.asObservable();
  }

  get currentUserGroupLicenceGroups() {
    return this._currentUserGroupLicenceGroups.asObservable();
  }

  get currentUserUserGroups() {
    return this._currentUserUserGroups.asObservable();
  }

  get userGroupDetails() {
    return this._userGroupDetails.asObservable();
  }

  get currentSelectedUserGroupDetails() {
    return this._currentSelectedUserGroupDetail.asObservable();
  }

  activate(activationFile: FileInput): Observable<any> {
    return this.licenceService.activateWithLicenceFile(activationFile)
      .pipe(finalize(() => {
        this.refreshLicenceState();
      }));
  }

  addLicenceGroup(group: LicenceGroup): Observable<any> {
    return this.licenceService.addLicenceGroup(group).pipe(map(response => {
      this.refreshLicenceGroupDetails();
      return response;
    }));
  }

  addUser(newUser: User): Observable<any> {
    return this.userService.addUser(newUser)
      .pipe(map(result => {
        this.refreshUserDetails();
        return result;
      }));
  }

  addAdminUser(userName: string, password: string): Observable<any> {
    const input = new FormData();
    input.append('adminPassword', password);
    input.append('adminPasswordConfirm', password);
    input.append('adminName', userName);
    return this.userService.addAdminUser(input);
  }

  addUserGroup(group: UserGroup): Observable<number> {
    return this.userGroupService.addUserGroup(group)
      .pipe(map(id => {
        this.refreshUserGroupDetails();

        this.userGroupDetails
          .pipe(take(1))
          .subscribe(() => {
            this.refreshUserDetails();
          });

        return id;
      }));
  }

  connectToProvider(provider: Provider): Observable<Provider> {
    return this.identityService.attemptToChangeProvider(provider).pipe(map(responseDirectory => {
      this.refreshProviders();
      return provider;
    }));

  }

  deleteProvider(id: number) {
    if (id) {
      this.identityService.deleteProvider(id).subscribe(() => {
        this.refreshProviders();
        this.refreshUserGroupDetails();
        this.refreshUserDetails();
        this.refreshLicenceGroupDetails();
      });
    }
  }


  deleteUserGroup(group: UserGroup): Observable<any> {
    return this.userGroupService.deleteUserGroup(group)
      .pipe(map(res => {
        this.refreshUserDetails();
        this.refreshUserGroupDetails();
        this.refreshLicenceGroupDetails();
        return res;
      }));
  }

  deleteLicenceGroup(group: LicenceGroup): Observable<any> {
    return this.licenceService.deleteLicenceGroup(group)
      .pipe(map(response => {
        this.refreshUserGroupDetails();
        this.refreshUserDetails();
        this.refreshLicenceGroupDetails();
        return response;
      }));
  }


  deleteUser(userDetail: UserDetailData) {
    this.userService.deleteUser(userDetail.userId)
      .subscribe(() => {
        this.refreshUserDetails();
        this.refreshLicenceGroupDetails();
      });
  }

  removeUserSession(userDetail: UserDetailData) {
    this.userService.removeUserSession(userDetail).subscribe(details => {
      this.userService.getAllActiveUserData().subscribe(data => {
        this.updateUserDetails(data);
      });
      this.licenceService.getLicenceGroups().subscribe(data => {
        this.updateLicenceGroupDetails(data);
      });
    });
  }

  updateUsers(users: UserDetailData[]) {
    users.forEach(userDetail => {
      const user = this.userService.getUser(userDetail);
      this.userService.editUser(user)
        .pipe(map(response => {
          this.refreshUserDetails();
          this.refreshLicenceGroupDetails();
          return response;
        })).subscribe();
    });
  }


  updateUserGroups(groups: UserGroup[]) {
    this.userGroupService.updateUserGroups(groups)
      .subscribe(
        () => {
          this.refreshUserGroupDetails();
          this.refreshLicenceGroupDetails();
          this.refreshUserDetails();
        },
        error => {
          console.error(error);
        });
  }

  updateLicenceGroups(groups: LicenceGroup[]) {
    this.licenceService.updateLicenceGroups(groups)
      .subscribe(_ => {
        this.refreshUserGroupDetails();
        this.refreshUserDetails();
        this.refreshLicenceGroupDetails();
      },
        error => {
          console.error(error);
        });
  }

  syncUserGroup(group: UserGroup) {
    this.activeDirectoryService.syncUserGroup(group)
      .pipe(map(_ => {
        this.refreshUserDetails();
        this.refreshUserGroupDetails();
        this.refreshLicenceGroupDetails();
        this.setCurrentSelectedUserGroupDetails(group);
      })).subscribe();
  }

}
