import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-token-config-dialog',
  template: `
  <h1 mat-dialog-title>Set Session Duration</h1>
  <div mat-dialog-content fxLayout="column">
    <form [formGroup]="inputForm">
      <div>
        <mat-form-field fxFlex>
          <input
          matInput type="number"
          autocomplete="off"
          min="0"
          placeholder="Expiry length in minutes"
          formControlName="tokenIntervalEntry" required>
        </mat-form-field>
      </div>
     </form>
  </div>
  <footer>
    <div mat-dialog-actions align="end">
      <button mat-button [disabled]="inputForm.invalid"  (click)="onSaveClick()">Save</button>
      <button mat-button (click)="onCloseClick()" cdkFocusInitial>Close</button>
    </div>
  </footer>
  `,
  styleUrls: ['./token-config-dialog.component.scss']
})
export class TokenConfigDialogComponent implements OnInit {

  inputForm: FormGroup;
  initialValue: number;

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<TokenConfigDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data) {

    this.initialValue = data.currentExpiry;
  }

  ngOnInit(): void {
    this.inputForm = this.formBuilder.group({
      tokenIntervalEntry: [
        this.initialValue, [
          Validators.required,
          Validators.pattern('^[0-9]*$'),
          Validators.min(1)
        ]
      ]
    });
  }

  onSaveClick() {
    this.dialogRef.close(this.inputForm.get('tokenIntervalEntry').value);
  }

  onCloseClick(): void {
    this.dialogRef.close();
  }

}
