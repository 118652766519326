import { Component, EventEmitter, Output, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { ActiveDirectoryService } from 'src/app/core/services/data/request/active-directory.service';
import { DrawerService } from 'src/app/core/services/helper/drawer.service';
import { CustomSnackbarService } from 'src/app/core/services/helper/custom-snackbar.service';
import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material';
import { ActiveDirectoryToolsDialogComponent } from './components/active-directory-tools-dialog.component';
import { Provider } from 'src/app/models/provider.model';
import { UserDetailData } from 'src/app/models/user-detail-data.model';
import { UserGroup } from 'src/app/models/user-group.model';
import { LicenceGroup } from 'src/app/models/licence-group.model';
import { Role } from 'src/app/models/role.model';
import { take, takeUntil, map } from 'rxjs/operators';
import { UserAddDialogComponent } from './components/user-add-dialog.component';
import { ActiveDirectoryUser } from 'src/app/models/active-directory-user.model';
import { AddLicenceGroupDialogComponent } from '../licence-management/components/add-licence-group-dialog.component';
import { Subscription, Observable, Subject } from 'rxjs';
import { AdminDataService } from 'src/app/core/services/data/data.service';
import { ProviderType } from 'src/app/models/provider-type.enum';

@Component({
  selector: 'app-identity-provider',
  styleUrls: ['./identity-provider-details.container.scss'],
  template: `
  <br>
  <br>
  <br>
<mat-card  fxLayoutAlign="start start">
  <button mat-button (click)="goBack()">BACK TO SETTINGS DASHBOARD</button>
</mat-card>
  <br>
  <br>
<section class="container">
  <div class="groups">
    <mat-card-title>
          <div>
          Name: {{ this.currentProvider?.name}}
          </div>
          <div fxFlex></div>
          <button mat-button
            *ngIf="this.isAD"
            (click)='this.onSearchAd(this.currentProvider?.id)'>AD Tools
          </button>
        </mat-card-title>
    <mat-card-title>
    </mat-card-title>
  </div>

<div class = "groups">
  <div class="half">
      <app-identity-provider-user-management-card
      [isAD]='this.isAD'
      [userGroups]='this.userGroups | async'
      [currentProvider]='this.currentProvider'
      [directories]='this.providers'
      [userDisplayedColumns]='this.userDisplayedColumns'
      [users]='this.users | async'
      (showUserDetails)='onShowUserDetails($event)'
      (addUser)='onAddUser($event)'
      ></app-identity-provider-user-management-card>
  </div>

  <div class="half" *ngIf="true">
      <app-identity-provider-group-management-card
       *ngIf="this.isAD"
       [currentProvider]='this.currentProvider'
       [directories]='this.providers'
       [groupDisplayedColumns]='this.groupDisplayedColumns'
       [userGroups]='this.userGroups | async'
       (showGroupDetails)='onShowGroupDetails($event)'
       (addUser)='onAddUser($event)'
      ></app-identity-provider-group-management-card>
    </div>
  </div>
</section>
  `
})
export class IdentityProviderDetailsContainerComponent implements OnInit, OnDestroy, AfterViewInit {

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    public dataService: AdminDataService,
    public activeDirectoryService: ActiveDirectoryService,
    public drawerService: DrawerService,
    public snackbarService: CustomSnackbarService,
    private dialog: MatDialog,
  ) { }

  @Output() showDetails = new EventEmitter();
  @Output() shownUserUpdate = new EventEmitter();

  private adToolsDialogRef: MatDialogRef<ActiveDirectoryToolsDialogComponent>;
  private _connectionTestResult = null;
  private routerParamSubscription: Subscription;


  userTabSelected = true;
  metaUserDisplayedColumns = ['name', 'role', 'directory'];
  userDisplayedColumns = ['name', 'role', 'directory'];
  groupDisplayedColumns = ['name', 'role', 'licencegroup'];
  currentlySelectedGroup: LicenceGroup;
  licenceSeats: number;
  isConnectedToExternalIdP = false;
  roles: Role[];
  currentlySelectedUser: UserDetailData;
  licencePools: LicenceGroup[];
  providers: Provider[];
  currentProvider: Provider;
  isAD = false;
  killSubscriptions = new Subject<void>();
  userGroups = new Observable<UserGroup[]>();
  users = new Observable<UserDetailData[]>();

  ngAfterViewInit() { }

  ngOnInit() {
    this.userGroups = this.dataService.userGroupDetails
      .pipe(map(groups => {
        return groups
          .filter(g => g.directoryId === this.currentProvider.id);
      }));

    this.users = this.dataService.userDetails
      .pipe(map(userDetailData => {
        return userDetailData
          .filter(u => u.directoryId === this.currentProvider.id);
      }));

    this.routerParamSubscription = this.route.params.subscribe(params => {
      const providerId = +params['id']; // (+) converts string 'id' to a number
      this.currentProvider = this.dataService.getProvider(providerId);
      this.isAD = this.isActiveDirectory(this.currentProvider);

      this.dataService.providers
        .pipe(takeUntil(this.killSubscriptions))
        .subscribe(knownProviders => {
          this.providers = knownProviders;
        });

    });

    this.dataService.currentSelectedUserDetails
      .pipe(takeUntil(this.killSubscriptions))
      .subscribe(selectedUser => {
        this.currentlySelectedUser = selectedUser;
      });

    this.dataService.currentSelectedLicenceGroupDetails
      .pipe(takeUntil(this.killSubscriptions))
      .subscribe(selectedGroup => {
        this.currentlySelectedGroup = selectedGroup;
      });

    this.dataService.roles
      .pipe(takeUntil(this.killSubscriptions))
      .subscribe(roles => {
        this.roles = roles;
      });
  }

  ngOnDestroy(): void {
    this.routerParamSubscription.unsubscribe();
    this.killSubscriptions.next();
    this.killSubscriptions.complete();
  }

  isActiveDirectory(provider: Provider): boolean {
    if (provider) {
      return provider.type === ProviderType.ActiveDirectory;
    }
    return false;
  }

  onShowUserDetails(user: UserDetailData) {
    this.drawerService.openUserDrawer();
    this.dataService.setCurrentSelectedUserDetails(user);

  }

  onShowGroupDetails(group: UserGroup) {
      this.drawerService.openUserGroupDrawer();
    this.dataService.setCurrentSelectedUserGroupDetails(group);
  }



  onAddUser(id: number) {
    this.dataService.licenceGroupDetails
      .pipe(take(1))
      .subscribe(licencePools => {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.minWidth = '450px';
        dialogConfig.data = {
          roles: this.roles.filter(r => r.name !== 'tenantadmin'),
          licencePools: licencePools,
          directoryId: id,
          directories: this.providers,
          groupOptions: [],
        };

        const addUserDialogRef = this.dialog.open(UserAddDialogComponent, dialogConfig);
        const killSubscriptions = new Subject<void>();

        addUserDialogRef.beforeClosed().subscribe(_ => {
          killSubscriptions.next();
          killSubscriptions.complete();
        });

        addUserDialogRef.componentInstance.addGroup.subscribe((event: any) => {
          this.dataService.addUserGroup(event.group)
            .subscribe(groupId => {
              if (event.sync) {
                event.group.id = groupId;
                this.dataService.syncUserGroup(event.group);
              }
            });
          addUserDialogRef.close();
        });

        addUserDialogRef.componentInstance.addUser
          .subscribe((newUser: any) => {
            this.dataService.addUser(newUser).subscribe(_ => {
              addUserDialogRef.close();
            });
          });

        addUserDialogRef.componentInstance.searchGroups.subscribe(
          (value) => {
            this.activeDirectoryService.searchForGroups(value.id, value.groupName).subscribe(
              (groups: string[]) => {
                addUserDialogRef.componentInstance.updateGroupOptions(groups);
              }
            );
          }
        );


        addUserDialogRef.componentInstance.addLicencePool.subscribe(() => {
          const dialogConfig2 = new MatDialogConfig();
          dialogConfig2.minWidth = '400px';
          dialogConfig2.data = { groupOptions: [] };
          const addGroupDialogRef = this.dialog.open(AddLicenceGroupDialogComponent, dialogConfig2);
          addGroupDialogRef.componentInstance.groupAdded.subscribe(
            (group: LicenceGroup) => {
              this.dataService.addLicenceGroup(group).subscribe(() => {
                this.dataService.licenceGroupDetails
                  .pipe(takeUntil(killSubscriptions))
                  .subscribe(pools => {
                    addUserDialogRef.componentInstance.licencePools = pools;
                  });
              });
              addGroupDialogRef.close();
            }
          );
        }
        );
      });
  }

  onAddLicencePool() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.minWidth = '400px';
    dialogConfig.data = { groupOptions: [] };
    const addGroupDialogRef = this.dialog.open(AddLicenceGroupDialogComponent, dialogConfig);

    const killSubscriptions = new Subject<void>();
    addGroupDialogRef.beforeClosed().subscribe(_ => {
      killSubscriptions.next();
      killSubscriptions.complete();
    });

    addGroupDialogRef.componentInstance.groupAdded.subscribe(
      (group: LicenceGroup) => {
        this.dataService.addLicenceGroup(group)
          .pipe(takeUntil(killSubscriptions))
          .subscribe(() => {
            addGroupDialogRef.close();
          }
          );
      }
    );
  }


  get connectionTestResult(): string {
    return this._connectionTestResult;
  }

  onSearchAd(directoryID: number) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '80%';
    dialogConfig.minWidth = '300px';
    dialogConfig.height = '85vh';
    this.adToolsDialogRef = this.dialog.open(ActiveDirectoryToolsDialogComponent, dialogConfig);

    this.adToolsDialogRef.componentInstance.searchAd.subscribe((search) => {
      this.activeDirectoryService.searchActiveDirectory(directoryID, search).subscribe((response: ActiveDirectoryUser[]) => {
        this.updateAdSearchResults(response);
      });
    });
  }

  updateAdSearchResults(response: ActiveDirectoryUser[]): any {
    if (this.adToolsDialogRef && this.adToolsDialogRef.componentInstance) {
      this.adToolsDialogRef.componentInstance.updateSearchResults(response);
    }
  }

  goBack() {
    this.location.back();
  }
}
