import { LicenceGroup } from './licence-group.model';
import { Role } from './role.model';
import { Session } from './session.model';

export class User {
  directoryId: number;
  userId: number;
  name: string;
  emailAddress: string;
  licenceGroups: LicenceGroup[];
  roles: Role[];
  token: string;
  tokenExpiry: number;
  lastActive: number;
  password: string;
  currentLicenceGroup: string;
  activeSessions: Session[];
}
