import { UserDetailData } from './user-detail-data.model';
import { Role } from './role.model';
import { LicenceGroup } from './licence-group.model';

export class UserGroup {
    id: number;
    name: string;
    roles: Role[];
    licenceGroups: LicenceGroup[];
    directoryId: number;
    users: UserDetailData[];
}
