import { Component, OnInit, ViewChild, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, MatSortable } from '@angular/material';
import { UserDetailData } from 'src/app/models/user-detail-data.model';
import { RoleDisplay } from '../../role-display-helper';

@Component({
  selector: 'app-active-user-licence-table',
  template: `
  <div class='configuration-card-content'>
      <table mat-table #table class="full-width-table" [dataSource]="userDataSource" matSort>
        <ng-container matColumnDef="status">
         <th mat-header-cell header-icon *matHeaderCellDef>
         <mat-icon  matTooltip="User activity status">
          <img src='/assets/images/QuestionMark.png' />
         </mat-icon>
         </th>
          <td mat-cell *matCellDef="let element">
          <mat-icon >
            <img class ="status" [src]="element?.statusImagePath" />
          </mat-icon>
          </td>
        </ng-container>
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
          <td mat-cell class ="top-text" *matCellDef="let element">
            {{element?.name}}
          </td>
        </ng-container>
        <ng-container matColumnDef="role">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Role</th>
          <td mat-cell class ="top-text" *matCellDef="let element">
            {{this.roleDisplay.getDisplayRole(element)}}
          </td>
        </ng-container>
        <ng-container matColumnDef="lastActive">
           <th mat-header-cell *matHeaderCellDef mat-sort-header>Last Active</th>
           <td mat-cell *matCellDef="let element">
              {{element?.lastActive | date:'LLL dd, yyyy h:mm aa (z)'}}
           </td>
         </ng-container>
        <ng-container matColumnDef="tokenExpiry">
        <th mat-header-cell *matHeaderCellDef >
          <div fxLayout="row" fxLayoutAlign="start center" >
            <div mat-sort-header>Session Expiry</div>
            <ng-container *ngIf="this.hasLicenceGroups">
              <app-token-config-container></app-token-config-container>
            </ng-container>
          </div>
        </th>
         <td mat-cell *matCellDef="let element">
            {{element?.tokenExpiry | date:'LLL dd, yyyy h:mm aa (z)'}}
         </td>
      </ng-container>
      <ng-container matColumnDef="group">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>License Pool</th>
       <td mat-cell class ="top-text" *matCellDef="let element">{{element?.assignedPool}}</td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row
      (click)="showDetails(row)"
      *matRowDef="let row; columns: displayedColumns;
      let entry"[ngClass]="{ 'highlight': entry.emailaddress ==  'test@test.com' }"></tr>
    </table>
    <mat-toolbar>
      <mat-icon (click)="this.refresh.emit()"  matTooltip="Refresh session activity data">refresh</mat-icon>
      <div fxFlex></div>
      <mat-paginator
      [pageSizeOptions]="[10, 20, 40]"
      showFirstLastButtons>
      </mat-paginator>
    </mat-toolbar>
  </div>
  `,
  styleUrls: ['./active-user-licence-table.component.scss'],
})

export class ActiveUserLicenceTableComponent implements OnInit, AfterViewInit {

  @Input() userDataSource: MatTableDataSource<UserDetailData>;
  @Input() displayedColumns: string[];
  @Input() hasLicenceGroups: boolean;
  @Output() selectedRow: UserDetailData;
  @Output() showUserDetails = new EventEmitter();
  @Output() refresh = new EventEmitter();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  roleDisplay: RoleDisplay = new RoleDisplay();
  selectedrow: any;

  ngOnInit() {
    this.userDataSource.paginator = this.paginator;
    this.sort.sort(<MatSortable>({id: 'lastActive', start: 'desc'}));
    this.userDataSource.sort = this.sort;
  }

  ngAfterViewInit(): void {
    if (this.userDataSource) {
      this.userDataSource.sortingDataAccessor = (data, sortHeaderId: string) => {
        switch (sortHeaderId) {
          case 'role': {
            return this.roleDisplay.getDisplayRole(data);
          }
          case 'group': {
            return data.assignedPool;
          }
          default: {
            return data[sortHeaderId];
          }
        }
      };
    }
  }

  showDetails(row: UserDetailData) {
    this.selectedRow = row;
    this.showUserDetails.emit(row);
  }

}


