import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatSort, MatTableDataSource, MatPaginator, MatSortable } from '@angular/material';
import { ApplicationEvent } from 'src/app/models/application-event.model';

@Component({
  selector: 'app-application-events-dialog',
  template: `
  <h1 mat-dialog-title>All Events</h1>
  <div mat-dialog-content>
    <mat-table class="full-width-table" [dataSource]="dataSource" matSort aria-label="Elements">
        <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
            <mat-cell *matCellDef="let element">{{element.name}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="createdBy">
            <mat-header-cell  *matHeaderCellDef mat-sort-header>Created By</mat-header-cell>
            <mat-cell *matCellDef="let element">{{element.createdBy}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="description">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Description</mat-header-cell>
            <mat-cell *matCellDef="let element">{{element.description}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="tenantName">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Tenant Name</mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.tenantName}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="created">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Created</mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.created | date:'LLL dd, yyyy h:mm:ss aa (z)'}}</mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
  </div>
  <div class="spacer"></div>
  <div mat-dialog-actions align="end">
    <div class="table-footer-container">
      <mat-form-field>
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
        <img matSuffix src='/assets/images/MagnifyIcon.png'>
      </mat-form-field>
      <mat-paginator class="footer-paginator"
          [pageSizeOptions]="[10, 20, 40]"
          showFirstLastButtons>
      </mat-paginator>
      <button mat-button mat-dialog-close>Close</button>
    </div>
  </div>
  `,
  styleUrls: ['./application-events-dialog.component.scss']
})
export class ApplicationEventsDialogComponent implements OnInit {
  displayedColumns = [ 'name', 'created', 'createdBy', 'description'];
  dataSource = new MatTableDataSource(this.data);

  constructor(@Inject(MAT_DIALOG_DATA) public data: ApplicationEvent[]) { }

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngOnInit() {
    const defaultSort: MatSortable = {
      id: 'created',
      start: 'desc',
      disableClear: false
    };
    this.sort.sort(defaultSort);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
