import { Component, OnInit } from '@angular/core';
import { LicenceGroup } from 'src/app/models/licence-group.model';
import { first } from 'rxjs/operators';
import { AdminDataService } from 'src/app/core/services/data/data.service';

@Component({
  selector: 'app-token-config-container',
  template: `
    <app-token-config
    [currentExpiry]="this.currentExpiry"
    (updateExpiryTime)="this.onTokenChange($event)">
    </app-token-config>
  `
})
export class TokenConfigContainerComponent implements OnInit {
  currentExpiry: number;

  constructor(private dataService: AdminDataService) { }

  ngOnInit() {
      this.dataService.licenceGroupDetails
      .pipe(first())
      .subscribe((groups: LicenceGroup[]) => {
        if (groups)  {
        this.currentExpiry =  groups[0].licenceDuration;
        }
    });
  }

  // this will only take if they have a licence group
  // it should be on a per group level but we put it on
  // the session tab to effect all groups
   onTokenChange(mins: number) {
    if (mins) {
      this.dataService.licenceGroupDetails
      .pipe(first())
      .subscribe((groups: LicenceGroup[]) => {
        this.currentExpiry = mins;
        groups.forEach(g => g.licenceDuration = mins);
        this.dataService.updateLicenceGroups(groups);
      });
    }
  }

}
