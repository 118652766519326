import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { DrawerService } from 'src/app/core/services/helper/drawer.service';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { UserDetailData } from 'src/app/models/user-detail-data.model';
import { AuthorizationService } from 'src/app/core/services/data/request/auth.service';
import { LicenceGroup } from 'src/app/models/licence-group.model';
import { UserGroup } from 'src/app/models/user-group.model';
import { takeUntil, map } from 'rxjs/operators';
import { Provider } from 'src/app/models/provider.model';
import { Role } from 'src/app/models/role.model';
import { AdminDataService } from 'src/app/core/services/data/data.service';
import { Observable, Subject, of } from 'rxjs';
import { ChangeUserPasswordDialogComponent } from './components/change-password-dialog/change-password-dialog.component';
import { UserService } from 'src/app/core/services/data/request/user.service';
import { ProviderType } from 'src/app/models/provider-type.enum';
import { ActiveDirectoryService } from 'src/app/core/services/data/request/active-directory.service';
import { UserActiveDirectoryIdentityDetailData } from 'src/app/models/user-identity-detail.model';
import { GroupDetailComponent } from './components/user-group-detail/user-group-detail.component';
import { UserDetailComponent } from './components/user-detail/user-detail.component';
import { LicenceGroupDetailComponent } from './components/licence-group-detail/licence-group-detail.component';
import { ActiveUserLicenceDetailComponent } from './components/active-user-licence-detail/active-user-licence-detail.component';

/**
 * Container for the user and group detail drawers.
 * Manages the events exposed from detail components.
 */
@Component({
    selector: 'app-admin-drawer',
    template: `
      <app-user-detail #userDetailDrawer *ngIf="this.drawerService._userDetailDrawerVisible"
      [allLicenceGroups]='this.allLicenceGroups | async'
      [provider]='this.currentUsersProvider | async'
      [userDetails]='this.currentUser | async'
      [identityDetails]='this.identityDetails | async'
      [userGroups]='this.currentUserUserGroups | async'
      [licenceGroups]='this.currentUserLicenceGroups |async'
      [roles]='this.roles | async'
      [adminEmail]='this.adminEmail |async'
      (showUserGroupDetails)='this.onShowUserGroupDetails($event)'
      (showLicenceGroupDetails)='this.onShowLicenceGroupDetails($event)'
      (closeUserDetails)='this.drawerService.closeUserDrawer()'
      (deleteUserDetails)='this.onUserDetailsDelete($event)'
      (editUserDetails)='this.onEditUser($event)'
      (changePassword)='this.onChangePassword($event)'>
      </app-user-detail>

      <app-group-detail #groupDetailDrawer *ngIf="this.drawerService._userGroupDetailDrawerVisible"
      [allLicenceGroups]='this.allLicenceGroups | async'
      [groupDetails]='this.currentUserGroup | async'
      [licenceGroups]='this.currentUserGroupLicenceGroups | async'
      [users]='this.currentUserGroupUsers |async'
      [roles]='this.roles |async'
      (showUserDetails)='this.onShowUserDetails($event)'
      (closeGroupDetails)="this.drawerService.closeUserGroupDrawer()"
      (deleteGroupDetails)='this.onGroupDelete($event)'
      (showLicenceGroupDetails)='this.onShowLicenceGroupDetails($event)'
      (sync)='this.onSync($event)'
      (editUserGroup)='this.onEditUserGroup($event)'>
      </app-group-detail>

     <app-user-licence-detail #activeSessionDetailDrawer *ngIf="this.drawerService._activeLicenceDetailDrawerVisible"
       [userDetails]='this.currentUser | async'
       (showGroupDetails)='this.onShowLicenceGroupDetails($event)'
       (closeActiveSessionDetails)='this.drawerService.closeActiveLicenceDrawer()'
       (removeActiveLicence)='this.onRemoveActiveLicence($event)'>
      </app-user-licence-detail>
      <app-licence-group-detail #licenceGroupDetailDrawer *ngIf="this.drawerService._licenceGroupDetailDrawerVisible"
       [userDisplayedColumns]='this.activeUserDisplayColumns'
       [activeUsers]='this.activeUsers | async'
       [currentLicenceGroup]='this.currentLicenceGroup | async'
       [userGroups]='this.currentLicenceUserGroups | async'
       [users]='this.currentLicenceUsers |async'
       [providers]='this.providers |async'
       (showActiveSessionDetails)='this.onShowActiveSessionDetails($event)'
       (showUserDetails)='this.onShowUserDetails($event)'
       (showUserGroupDetails)='this.onShowUserGroupDetails($event)'
       (closeGroupDetails)="this.drawerService.closeLicenceGroupDrawer()"
       (deleteGroupDetails)='this.onLicenceGroupDelete($event)'
       (editLicencePool)='this.onEditLicencePool($event)'
       (editUserGroups)='this.onEditUserGroups($event)'
       (editUsers)='this.onEditUsers($event)'
       (refresh)='onRefresh()'>
      </app-licence-group-detail>
  `,
    styleUrls: ['./admin-drawer.container.scss']
})
export class AdminDrawerContainerComponent implements OnInit, OnDestroy, AfterViewInit {


    @ViewChild('userDetailDrawer') userDetailDrawer: UserDetailComponent;
    @ViewChild('groupDetailDrawer') groupDetailDrawer: GroupDetailComponent;
    @ViewChild('activeSessionDetailDrawer') activeSessionDetailDrawer: ActiveUserLicenceDetailComponent;
    @ViewChild('licenceGroupDetailDrawer') licenceGroupDetailDrawer: LicenceGroupDetailComponent;

    activeUserDisplayColumns = ['status', 'name', 'role', 'lastActive', 'tokenExpiry'];

    allLicenceGroups: Observable<LicenceGroup[]>;
    providers: Observable<Provider[]>;
    roles: Observable<Role[]>;
    adminEmail: Observable<String>;

    currentUser: Observable<UserDetailData>;
    identityDetails: Observable<UserActiveDirectoryIdentityDetailData>;
    currentUsersProvider: Observable<Provider>;
    currentUserUserGroups: Observable<UserGroup[]>;

    currentUserGroup: Observable<UserGroup>;
    currentLicenceGroup: Observable<LicenceGroup>;

    currentUserLicenceGroups: Observable<LicenceGroup[]>;
    currentLicenceUsers: Observable<UserDetailData[]>;
    currentLicenceUserGroups: Observable<UserGroup[]>;

    currentUserGroupLicenceGroups: Observable<LicenceGroup[]>;
    currentUserGroupUsers: Observable<UserDetailData[]>;
    activeUsers: Observable<UserDetailData[]>;

    private killSubscriptions = new Subject<void>();

    constructor(
        public dialog: MatDialog,
        private userService: UserService,
        private activeDirectoryService: ActiveDirectoryService,
        private authService: AuthorizationService,
        private dataService: AdminDataService,
        public drawerService: DrawerService) { }
    ngAfterViewInit(): void {
    }

    ngOnInit() {

        this.activeUsers = this.dataService.userDetails
            .pipe(map(userDetails => {
                const data: UserDetailData[] = [];
                userDetails.forEach(user => {
                    if (user.activeSessions.length > 0) {
                        user.activeSessions.forEach(session => {
                            const activeUser: UserDetailData = Object.assign({}, user);
                            activeUser.tokenExpiry = session.expiry;
                            activeUser.assignedPool = session.licenceGroupAssignment;
                            activeUser.systemId = session.systemId;
                            data.push(activeUser);
                        });
                    }
                });
                return data.filter(u => u.tokenExpiry != null);
            }));

        this.currentUser = this.dataService.currentSelectedUserDetails
            .pipe(takeUntil(this.killSubscriptions))
            .pipe(map((user => {
                if (user && this.authService.getAuthUser().emailAddress) {
                    const adProvider = this.dataService.getProvider(user.directoryId);
                    if (adProvider && adProvider.type === ProviderType.ActiveDirectory) {
                        // this queries user each time...so if we want to mitigate calls will need to cache responses
                        this.identityDetails = this.activeDirectoryService
                            .getActiveDirectoyUserIdentityData(user.directoryId, user.emailAddress);
                    } else {
                        this.identityDetails = null;
                    }
                }
                return user;
            })));
        this.adminEmail = of(this.authService.getAuthUser().emailAddress);
        this.allLicenceGroups = this.dataService.licenceGroupDetails;
        this.roles = this.dataService.roles;
        this.providers = this.dataService.providers;
        this.currentUsersProvider = this.dataService.currentUserProvider;
        this.currentUserLicenceGroups = this.dataService.currentUserLicenceGroups;
        this.currentLicenceGroup = this.dataService.currentSelectedLicenceGroupDetails;
        this.currentLicenceUsers = this.dataService.currentLicenceGroupUsers;
        this.currentLicenceUserGroups = this.dataService.currentLicenceUserGroups;
        this.currentUserGroupLicenceGroups = this.dataService.currentUserGroupLicenceGroups;
        this.currentUserUserGroups = this.dataService.currentUserUserGroups;
        this.currentUserGroup = this.dataService.currentSelectedUserGroupDetails;
        this.currentUserGroupUsers = this.dataService.currentUserGroupUsers;
    }

    ngOnDestroy(): void {
        this.killSubscriptions.next();
        this.killSubscriptions.complete();
    }



    onRefresh() {
        this.dataService.refreshUserActivity();
    }

    onShowActiveSessionDetails(user: UserDetailData) {
        this.dataService.setCurrentSelectedUserDetails(user);
        this.drawerService.openActiveLicenceDrawer();
    }

    onShowUserGroupDetails(userGroup: UserGroup) {
        this.dataService.setCurrentSelectedUserGroupDetails(userGroup);
        this.drawerService.openUserGroupDrawer();
    }

    onShowLicenceGroupDetails(licenceGroup: LicenceGroup) {
        this.dataService.setCurrentSelectedLicenceGroupDetails(licenceGroup);
        this.drawerService.openLicenceGroupDrawer();
    }

    onShowUserDetails(user: UserDetailData) {
        this.dataService.setCurrentSelectedUserDetails(user);
        this.drawerService.openUserDrawer();
    }


    onRemoveActiveLicence(userDetail: UserDetailData) {
        this.dataService.removeUserSession(userDetail);
        this.drawerService.closeActiveLicenceDrawer();
    }

    onLicenceGroupDelete(group: LicenceGroup) {
        this.dataService.deleteLicenceGroup(group).subscribe(_ => {
            this.drawerService.closeLicenceGroupDrawer();
        });
    }

    onUserDetailsDelete(userDetail: UserDetailData) {
        this.dataService.deleteUser(userDetail);
        this.drawerService.closeUserDrawer();
    }

    onGroupDelete(group: UserGroup) {
        this.dataService.deleteUserGroup(group).subscribe(() => {
            this.drawerService.closeUserGroupDrawer();
        });
    }

    onSync(group: UserGroup) {
        this.dataService.syncUserGroup(group);
    }

    onEditLicencePool(licenceGroup: LicenceGroup) {
        this.dataService.updateLicenceGroups([licenceGroup]);
    }

    onEditUserGroup(userGroup: UserGroup) {
        this.onEditUserGroups([userGroup]);
    }

    onEditUserGroups(userGroups: UserGroup[]) {
        this.dataService.updateUserGroups(userGroups);
    }

    onEditUser(user: UserDetailData) {
        this.onEditUsers([user]);
    }

    onEditUsers(users: UserDetailData[]) {
        this.dataService.updateUsers(users);
    }

    onChangePassword(user: UserDetailData) {
        const dialogConfig = new MatDialogConfig();
        const dialogRef = this.dialog.open(ChangeUserPasswordDialogComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(
            newPassword => {
                if (newPassword) {
                    this.userService.resetUserPassword(user.userId, newPassword);
                }
            }
        );

    }

}
