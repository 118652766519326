import { Component, OnInit } from '@angular/core';
import { DrawerService } from 'src/app/core/services/helper/drawer.service';
import { AdminDataService } from 'src/app/core/services/data/data.service';

@Component({
  selector: 'app-admin-dashboard', // TODO:Lay it out properly to be responsive remove line breaks flex small not correct
  template: `
 <div class="container"
  fxLayout="row"
  fxLayout.sm="column"
  fxLayoutWrap fxLayoutGap="36px"
  fxLayoutAlign="center start" fxLayoutGap.xs="0">

  <div class="left-side">
    <div *ngxPermissionsOnly="['groups:*']">
    <br />
       <app-user-management></app-user-management>
   </div>
  </div>
  <div class="right-side">
    <div *ngxPermissionsOnly="['groups:*']">
      <br />
      <app-licence-management></app-licence-management>
    </div>
    <div *ngxPermissionsOnly="['metrics:*', 'metrics:read']">
      <br />
      <app-monitoring></app-monitoring>
    </div>
    <div *ngxPermissionsOnly="['configuration:*']">
      <br />
      <app-configuration></app-configuration>
    </div>
  </div>
</div>
`,
  styleUrls: ['./admin-dashboard.container.scss']
})

export class AdminDashboardComponent implements OnInit {

  constructor(
    private dataService: AdminDataService,
    public drawerService: DrawerService) { }

  ngOnInit(): void {
    console.warn('Initializing admin Console Data');
    this.dataService.initialize();
  }
}
