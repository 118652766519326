import { Component, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-admin-entry',
  template: `
<form class='form' [formGroup]="licenceForm">
 <div fxLayout="column">
 <div class="left-column" fxLayout="column wrap" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
 <div>
 Please Create an Admin user for QTS
 </div>
      <mat-form-field fxFlex>
         <input matInput type="text" placeholder="User Name/Email" formControlName="userName" required>
         <mat-error *ngIf="licenceForm.get('userName')?.errors?.required">
           Please enter valid user name
         </mat-error>
      </mat-form-field>
      <mat-form-field fxFlex>
        <input matInput type="password" placeholder="Password" formControlName="password" required>
        <mat-error *ngIf="licenceForm.get('password')?.errors?.notSame">
          Passwords did not match
        </mat-error>
        <mat-error *ngIf="licenceForm.get('password')?.errors?.required">
          Password is required
        </mat-error>
      </mat-form-field>
  </div>
     <mat-form-field fxFlex>
       <input matInput type="password" placeholder="Confirm Password" formControlName="confirmPassword" required>
      <mat-error *ngIf="licenceForm.get('confirmPassword')?.errors?.notSame">
        Passwords do not match
      </mat-error>
      <mat-error *ngIf="licenceForm.get('confirmPassword')?.errors?.required">
        Confirmation is required
      </mat-error>
     </mat-form-field>
    <button mat-button [disabled]="licenceForm.invalid" (click)="createAccount()">CREATE ADMIN USER</button>
  </div>
</form>
  `,
  styleUrls: ['./admin-entry.component.scss']
})
export class AdminEntryComponent {

  @Output() creatAdminAccount = new EventEmitter();

  licenceForm: FormGroup;

  constructor(private formBuilder: FormBuilder) {
    this.licenceForm = this.formBuilder.group({
      password: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]],
      userName: ['', [Validators.required]]
    }, {
      validator: [
        this.hasMatchingPasswords('password', 'confirmPassword'),
      ]
    });
  }

  createAccount() {
    const credentials = {
      password: this.licenceForm.get('password').value,
      userName: this.licenceForm.get('userName').value,
    };
    this.creatAdminAccount.emit(credentials);
  }

  private hasMatchingPasswords(passwordKey: string, passwordConfirmationKey: string) {
    return (group: FormGroup) => {
      const passwordInput = group.controls[passwordKey],
        passwordConfirmationInput = group.controls[passwordConfirmationKey];
      if (passwordInput.value !== passwordConfirmationInput.value) {
        return passwordConfirmationInput.setErrors({ notSame: true });
      } else {
        return passwordConfirmationInput.setErrors(null);
      }
    };
  }

}
