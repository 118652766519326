import { Injectable, Inject } from '@angular/core';
import { InjectionToken, FactoryProvider } from '@angular/core';
import { environment } from 'src/environments/environment';

export const WINDOW = new InjectionToken<Window>('window');
const windowProvider: FactoryProvider = {
  provide: WINDOW,
  useFactory: () => window
};

export const WINDOW_PROVIDERS = [
    windowProvider
];

@Injectable({
  providedIn: 'root'
})
export class UrlService {

  private baseUrl: string = environment.baseUrl;

  constructor(@Inject(WINDOW) private window: Window) { }

  getHostname(): string {
    if (this.window.location.port === '4200') {
      return this.baseUrl;
    }
    if (!window.location.origin) {
      return window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
    }
    return this.window.location.origin;
  }
}
