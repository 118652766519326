import { UserActiveDirectoryIdentityDetailData } from './user-identity-detail.model';
import { LicenceGroup } from './licence-group.model';
import { Role } from './role.model';
import { Session } from './session.model';

export class UserDetailData {
    userId: number;
    statusImagePath: string; // TODO: bit of hack for now-think of better way
    directoryId: number;
    name: string;
    emailAddress: string;
    roles: Role[];
    lastActive: number;
    tokenExpiry: number;
    licenceGroups: LicenceGroup[];
    identityDetails: UserActiveDirectoryIdentityDetailData;
    assignedPool: string;
    systemId: string;
    activeSessions: Session[];
 }
