import { Component, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { MatPaginator, MatTableDataSource, MatSort, MatDialogConfig, MatDialog } from '@angular/material';
import { ConfigurationSummary } from '../../../../../../../models/configuration-summary.model';
import { UploadConfigurationComponent } from './upload-configuration-dialog.component';


@Component({
  selector: 'app-configuration-card',
  template: `
  <div  *ngxPermissionsOnly="['configuration:*']" class="mat-elevation-z2">
  <mat-card class="dashboard-card">
    <mat-card-header>
      <mat-card-title class="card-container-left">
        {{title}}
      </mat-card-title>
      <mat-card-title class="card-container-right">
      </mat-card-title>
    </mat-card-header>
    <mat-card-content class="configuration-card-content">
    <mat-divider></mat-divider>
      <table mat-table #table [dataSource]="dataSource" matSort >
      <ng-container matColumnDef="configurationName">
         <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
         <td mat-cell *matCellDef="let element" > {{element.configurationName}} </td>
        </ng-container>
        <ng-container matColumnDef="configurationVersion">
          <th mat-header-cell class= "center-text" *matHeaderCellDef mat-sort-header> Version </th>
          <td mat-cell class= "center-text"  *matCellDef="let element" > {{element.configurationVersion}} </td>
        </ng-container>
        <ng-container matColumnDef="configurationSchemaVersion">
          <th mat-header-cell class= "center-text" *matHeaderCellDef mat-sort-header> Schema Version </th>
          <td mat-cell class= "center-text" *matCellDef="let element" > {{element.configurationSchemaVersion }} </td>
        </ng-container>
        <ng-container matColumnDef="modifiedDate">
          <th mat-header-cell  *matHeaderCellDef  mat-sort-header> Modified Date </th>
          <td mat-cell  *matCellDef="let element" > {{element.modifiedDate | date:'LLL dd, yyyy h:mm aa (z)'}} </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns" ></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" ></tr>
      </table>
     <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
    </mat-card-content>
  </mat-card>
  </div>
  `,
  styleUrls: ['./configuration-card.component.scss']
})
export class ConfigurationCardComponent {

  private paginator: MatPaginator;
  title = 'Analysis Configuration';

  @Input() dataSource: MatTableDataSource<ConfigurationSummary>;
  @Input() displayedColumns: string[];

  @Output() uploadConfiguration = new EventEmitter();


  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    if (mp && this.dataSource) {
      this.paginator = mp;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  }

  constructor(public dialog: MatDialog) { }

  loadConfig() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.minWidth = '300px';
    const dialogRef = this.dialog.open(UploadConfigurationComponent, dialogConfig);

    dialogRef.afterClosed()
      .subscribe(
        upload => {
          if (upload) {
            this.uploadConfiguration.emit(upload);
            dialogRef.close();
          } else {
            dialogRef.close();
          }
        });
  }
}