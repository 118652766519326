import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgxPermissionsModule } from 'ngx-permissions';
import { LoggerModule, NgxLoggerLevel, NGXLogger } from 'ngx-logger';
import { NgHttpLoaderModule } from 'ng-http-loader';

import { AppRoutingModule } from './app-routing.module';
import { environment } from './../environments/environment'; // TODO: Get allias working

import { NeedAuthGuard } from './core/guards/auth.guard';
import { AuthorizationService } from './core/services/data/request/auth.service';
import { TokenInterceptor } from './core/interceptors/http-token.interceptor';
import { ErrorInterceptor } from './core/interceptors/http-error.interceptor';
import { HttpErrorHandlerService } from './core/services/helper/http-error-handler.service';
import { FormErrorStateMatcherService } from './core/services/helper/form-error-state-matcher.service';

import { LoginModule } from './modules/feature/login/login.module';
import { DashboardViewModule } from './modules/feature/dashboard/views/dashboard-view/dashboard-view.container.module';

import { AppComponent } from './app.component';
import { CustomSnackbarService } from './core/services/helper/custom-snackbar.service';
import { UrlService, WINDOW_PROVIDERS } from './core/services/helper/url.service';
import { MatSpinner } from '@angular/material';
import { MaterialModule } from './modules/material.module';
import { CommonComponentsModule } from './modules/feature/common/common.module';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPermissionsModule.forRoot(),
    NgHttpLoaderModule.forRoot(),
    LoggerModule.forRoot({ level: environment.production ? NgxLoggerLevel.LOG : NgxLoggerLevel.DEBUG, serverLogLevel: NgxLoggerLevel.OFF }),
    AppRoutingModule,
    LoginModule,
    DashboardViewModule,
    CommonComponentsModule,
    PerfectScrollbarModule
  ],
  providers: [
    NGXLogger,
    AuthorizationService,
    NeedAuthGuard,
    HttpErrorHandlerService,
    FormErrorStateMatcherService,
    WINDOW_PROVIDERS,
    UrlService,
    CustomSnackbarService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    }
  ],
  entryComponents: [
    MatSpinner
  ],

  bootstrap: [AppComponent]
})

export class AppModule {
  constructor(private logger: NGXLogger) {
    this.showEnvironment();
  }

  showEnvironment() {
    this.logger.warn('Environment : ' + environment.name);
  }
}
