import { Component, OnInit } from '@angular/core';
import { AuthorizationService } from './core/services/data/request/auth.service';
import { MatSpinner, MatIconRegistry } from '@angular/material';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  styleUrls: ['./app.component.scss'],
  template: `
  <ng-http-loader
  [entryComponent]="matSpinner"
[filteredUrlPatterns]="[
'/activedirectory/user',
'/qvsconfigurations/',
'/licence/group',
'/activedirectory/identity',
'/activedirectory/user'
]"
  [debounceDelay]="1500"
  [minDuration]="1000"
  [extraDuration]="300">
  </ng-http-loader>
  <router-outlet></router-outlet>
  `
})

/**
 * Main app component class.
 * The router outlet for the application is here.
 * We have a loader here to ad ux for api requests that are taking to long.  It also defineds which patterns should not be caught.
 * It is setup so that those that are polling do not add to the UX as they should be in the background.
 */
export class AppComponent implements OnInit {
  public matSpinner = MatSpinner;
  title = 'app';

  constructor(
    private router: Router,
    private authService: AuthorizationService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer) {
    this.matIconRegistry.addSvgIcon( 'qts-users', this.getSafeUrl('./assets/svg/UsersBlue.svg'));
    this.matIconRegistry.addSvgIcon( 'qts-pool', this.getSafeUrl('./assets/svg/LicencePoolsBlue.svg'));
    this.matIconRegistry.addSvgIcon( 'qts-session', this.getSafeUrl('./assets/svg/LicenceSessionsBlue.svg'));
    this.matIconRegistry.addSvgIcon( 'qts-provider', this.getSafeUrl('./assets/svg/IdentityProvidersBlue.svg'));
  }

  ngOnInit() {
    if (this.authService.isLoggedIn) {
    this.authService.refreshPermissions();
    this.router.navigate(['dashboard']);
    }
  }

  private getSafeUrl(url: string): SafeResourceUrl {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
