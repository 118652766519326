import { Component, EventEmitter, Input, OnInit, Output, ViewChild, AfterViewInit, } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator, MatSort, MatSortable, MatTableDataSource } from '@angular/material';

import { LicenceGroup } from 'src/app/models/licence-group.model';

@Component({
  selector: 'app-group-table',
  template: `
  <form [formGroup]='groupForm'>
    <mat-table class="full-width-table" [dataSource]="groupDataSource" matSort aria-label="Elements">
      <!-- Id Column -->
      <ng-container matColumnDef="id">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Id</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row?.id}}</mat-cell>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row?.name}}</mat-cell>
      </ng-container>

      <!-- Seats Column -->
      <ng-container matColumnDef="seats">
        <mat-header-cell *matHeaderCellDef>
          <span mat-sort-header>Licenses in Pool&nbsp;</span>
          <div *ngIf='this.countEditableSeats() > 0' (click)='this.toggleEditSeats()'>
          <mat-icon matTooltip="Click here to change Seat assignments">
            <img mat-icon class='edit-seat' src='/assets/images/EditIcon.png' />
          </mat-icon>
          </div>
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <div>
            <div *ngIf='!this.isSeatCountEditable(row)'>
              {{row?.seats}}
            </div>
            <mat-form-field *ngIf='this.isSeatCountEditable(row)'>
              <input matInput (focusout)='this.updateRowValue(row)'
                      type='number'
                      min='0'
                      formControlName='{{row?.name}}'/>
            </mat-form-field>
          </div>
        </mat-cell>
      </ng-container>

      <!-- Active Seats Column -->
      <ng-container matColumnDef="activeSeats">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Active Licenses</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <div>{{row?.activeSeats}}</div>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="groupDisplayedColumns"></mat-header-row>
      <mat-row
      (click)="showDetails(row)"
      *matRowDef="let row; columns: groupDisplayedColumns;"></mat-row>
  </mat-table>
  <mat-toolbar>
      <mat-icon (click)="this.refresh.emit()"  matTooltip="Refresh session activity data">refresh</mat-icon>
      <div fxFlex></div>
      <mat-paginator
      [pageSizeOptions]="[10, 20, 40]"
      showFirstLastButtons>
      </mat-paginator>
    </mat-toolbar>
</form>
<mat-error *ngIf="this.groupForm.hasError('invalidSeats')">
  Number of assigned licenses currently exceeds total number of available licenses.
</mat-error>
<mat-error *ngIf="this.groupForm.hasError('activeSeats')">
  Number of assigned pool licenses cannot be less than current active sessions.
</mat-error>
  `,
  styleUrls: ['./licence-group-table.component.scss'],
})
export class LicenceGroupTableComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) groupPaginator: MatPaginator;
  @ViewChild(MatSort) groupSort: MatSort;

  @Input() groupDisplayedColumns: string[];
  @Input() licenceSeats: number;
  @Input()
  set groupDataSource(groupDataSource: MatTableDataSource<LicenceGroup>) {
    if (!this.editingSeats) {
      this._groupDataSource = groupDataSource;
    }
  }

  get groupDataSource(): MatTableDataSource<LicenceGroup> {
    return this._groupDataSource;
  }

  @Output() updateSeats = new EventEmitter();
  @Output() showGroupDetails = new EventEmitter();
  @Output() refresh = new EventEmitter();

  editingSeats = false;
  groupForm: FormGroup;
  selectedRow: any;

  private _groupDataSource: MatTableDataSource<LicenceGroup>;

  constructor(private formBuilder: FormBuilder) {
    this.groupForm = this.formBuilder.group({});
  }

  ngOnInit() {
    this.groupSort.sort(<MatSortable>{ id: 'name', start: 'asc' });
    this.groupDataSource.sort = this.groupSort;
    this.groupDataSource.paginator = this.groupPaginator;
  }


  ngAfterViewInit() {
    this.groupDataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'seats':
        case 'activeSeats': {
          return item[property];
        }
        default: {
          return item[property];
        }
      }
    };
  }


  showDetails(group: LicenceGroup) {
    if (!this.editingSeats) {
      this.selectedRow = group;
      this.showGroupDetails.emit(group);
    }

  }

  toggleEditSeats() {
    if (this.editingSeats) {
      this.saveSeatChanges();
    } else {
      this.enableEditSeatsMode();
    }
  }

  saveSeatChanges() {
    if (this.isValidInput()) {
      if (this.groupForm.dirty) {
        this.updateSeats.emit(this.groupDataSource.data);
      }
      this.groupForm = this.formBuilder.group({});
      this.editingSeats = false;
    }
  }

  isValidInput(): boolean {
    const validSeats = this.isAssignedSeatsValid();
    return this.groupForm.valid && validSeats;
  }

  enableEditSeatsMode() {
    this.editingSeats = true;
    for (const group of this.groupDataSource.data) {
      this.groupForm.addControl(group.name, this.formBuilder.control(group.seats, [Validators.required, Validators.min(0)]));
    }
  }

  isSeatCountEditable(group: LicenceGroup): boolean {
    return this.editingSeats;
  }

  updateRowValue(group: LicenceGroup) {
    const groupControl = this.groupForm.get(group.name);
    group.seats = groupControl.value;
    this.validateAssignedSeats();
  }

  isAssignedSeatsValid(): boolean {
    let assignedSeats = 0;
    for (const group of this.groupDataSource.data) {
      assignedSeats += group.seats;
    }

    return assignedSeats <= this.licenceSeats;
  }

  activeMoreThanAssigned(): boolean {
    return this.groupDataSource.data.filter(group => group.seats < group.activeSeats).length > 0;
  }

  validateAssignedSeats(): boolean {
    if (!this.isAssignedSeatsValid()) {
      this.groupForm.setErrors({ 'invalidSeats': true });
      return false;
    } else if (this.activeMoreThanAssigned()) {
      this.groupForm.setErrors({ 'activeSeats': true });
      return false;
    } else {
      this.groupForm.setErrors(null);
      return true;
    }
  }

  countEditableSeats(): number {
    let count = 0;

    if (!this.groupDataSource || !this.groupDataSource.data) {
      return 0;
    }

    for (const _ of this.groupDataSource.data) {
      count++;
    }
    return count;
  }
}
