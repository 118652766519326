import { Component, OnInit, ViewChild, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { MatTableDataSource } from '@angular/material';

import { FileInput } from 'ngx-material-file-input';
import { Observable, Subject, interval, Subscription } from 'rxjs';
import { startWith, switchMap, map } from 'rxjs/operators';

import { ConfigurationCardComponent } from './components/configuration-card.component';

import { ConfigurationSummary } from 'src/app/models/configuration-summary.model';

import { AuthorizationService } from 'src/app/core/services/data/request/auth.service';
import { ConfigurationService } from 'src/app/core/services/data/request/configuration.service';
import { CustomSnackbarService } from 'src/app/core/services/helper/custom-snackbar.service';

@Component({
  selector: 'app-configuration',
  template: `
  <app-configuration-card
  [dataSource]='this.dataSource'
  [displayedColumns]='this.displayedColumns'
  (uploadConfiguration)='this.onConfigurationUpload($event)'>
  </app-configuration-card>
   `
})

export class ConfigurationContainerComponent implements OnInit, OnDestroy {

  displayedColumns: string[] = [
    'configurationName',
    'configurationVersion',
    'configurationSchemaVersion',
    'modifiedDate'];

  dataSource: MatTableDataSource<ConfigurationSummary>;
  fileReader = new FileReader();
  configPollingTimer = interval(60000);
  configPollSubscription: Subscription;

  @ViewChild(ConfigurationCardComponent) childView: ConfigurationCardComponent;

  constructor(
    public snackbarService: CustomSnackbarService,
    private authService: AuthorizationService,
    private configurationService: ConfigurationService, private changeDetectorRefs: ChangeDetectorRef) {
  }

  ngOnInit() {
    if (this.authService.isLoggedIn()) {
      this.configPollSubscription = this.configPollingTimer // Polling strategy for now...Should investigate introducing websockets
        .pipe(
          startWith(1),
          switchMap(() => this.configurationService.getAllConfigurationSummaries())
        )
        .subscribe(summaries => {
          if (!summaries) {
            return;
          }
          this.dataSource = new MatTableDataSource<ConfigurationSummary>(summaries);
          this.changeDetectorRefs.detectChanges();
        },
          error => console.error(error)
        );
    }
  }

  ngOnDestroy() {
    this.configPollSubscription.unsubscribe();
  }

  onConfigurationUpload(fileInput: FileInput) {
    if (this.authService.isLoggedIn()) {
      const file = fileInput.files[0];
      this.readFile(file)
      .pipe(map(output => {
        this.configurationService.uploadConfiguration(output)
        .flatMap(upload => this.configurationService.getAllConfigurationSummaries())
        .flatMap(configs =>  this.dataSource = new MatTableDataSource<ConfigurationSummary>(configs))
        .subscribe();
      }));
    }
  } //TODO: take me out

  private readFile(file: File): Observable<string> {
    const sub = new Subject<string>();
    const reader = new FileReader();
    reader.onload = () => {
      const content: string = reader.result as string;
      sub.next(content);
      sub.complete();
    };
    reader.readAsText(file);
    return sub.asObservable();
  }

}