import { Injectable } from '@angular/core';
import { HttpRequest,  HttpHandler,  HttpEvent,  HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { HttpErrorHandlerService } from '../services/helper/http-error-handler.service';

@Injectable({
  providedIn: 'root'
})

/**
 * Intercepts Http Response errors and delegates to a handler for approriate response.
 */
export class ErrorInterceptor implements HttpInterceptor {

  constructor(public errorHandler: HttpErrorHandlerService, ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe( tap((event: HttpEvent<any>) => {}, (error: any) => {
      if (error instanceof HttpErrorResponse) {
        this.errorHandler.handleError(error);
      }
      return next.handle(request);
    }));
  }
}
