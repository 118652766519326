import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgxPermissionsService } from 'ngx-permissions';
import * as jwt_decode from 'jwt-decode';

import { User } from '../../../../models/user.model';
import { tap } from 'rxjs/operators';
import { Role } from 'src/app/models/role.model';
import { MatDialog } from '@angular/material';

const USER = 'currentuser';

@Injectable({
  providedIn: 'root'
})

/**
 * Deals with Authorization of a user.  Any time we want to interact with the current user we should access
 * and update through this service.
 */
export class AuthorizationService {

  constructor(
    private permissionsService: NgxPermissionsService,
    private http: HttpClient,
    private dialogRef: MatDialog) { }


  logOut() {
    this.http.get('/token/qts/logout').subscribe(_ => {
      this.invalidateSession();
      this.dialogRef.closeAll();
    });
  }

  login(email: string, password: string) {
    const user = new User();
    return this.http.post<any>('/token/qts/login', {
      username: email,
      password: password,
      token: 'token'
    }).pipe(tap(data => {
      if (data) {
        user.name = data.name;
        user.emailAddress = data.username;
        user.token = data.token;
        const token = this.getDecodedAccessToken(data.token);
        const permissions: string[] = token.permissions.split(',');
        const roles: Role[] = [];
        const role: Role = new Role();
        role.name = token.role;
        role.permissions = permissions;
        roles.push(role);
        user.roles = roles;
        user.tokenExpiry = token.exp;
        user.lastActive = 0;
        this.loadPermissions(permissions);
        localStorage.setItem(USER, JSON.stringify(user));
      }
      return user;
    }));
  }

  private getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch (Error) {
      return null;
    }
  }

  private loadPermissions(permissions: string[]) {
    if (permissions) {
      const permArray = new Array();

      permissions.forEach((element) => {
        const permission = element;
        this.permissionsService.addPermission(permission);
        permArray.push(permission);
      });

      this.permissionsService.flushPermissions();
      this.permissionsService.loadPermissions(permArray);
    }
  }

  invalidateSession() {
    localStorage.removeItem(USER);
    localStorage.clear();
    this.permissionsService.flushPermissions();
  }

  refreshPermissions() {
    const user = this.getAuthUser();
    if (user && user.roles && user.roles.length > 0) {
      if (user && user.roles) {
        const perm: string[] = [];
        user.roles.forEach(r => {
          r.permissions.forEach(p => {
            perm.push(p);
          });
        });
        this.loadPermissions(perm);
      }
    }
  }

  hasPermission(permissions: string) {
    return this.permissionsService.hasPermission(permissions);
  }

  isLoggedIn(): boolean {
    const userData = localStorage.getItem(USER);
    if (userData) {
      const user = JSON.parse(userData);
      return user != null && user.emailAddress != null && user.token != null;
    }
    return false;
  }

  getToken(): string {
    const userData = localStorage.getItem(USER);
    if (userData) {
      const user = JSON.parse(userData);
      return user != null ? user.token : null;
    }
    return null;
  }

  setToken(token: string) {
    let user = JSON.parse(localStorage.getItem(USER));
    user = Object.assign({}, user);
    user.token = token;
    localStorage.setItem(USER, JSON.stringify(user));
  }

  getAuthUser(): User {
    const user = localStorage.getItem(USER);
    if (user) {
      return JSON.parse(user);
    }
    return null;
  }

  isTokenExpired(): boolean {
    const decodedToken = this.getDecodedAccessToken(this.getToken());

    if (!decodedToken) {
      return false;
    }

    const tokenExpiry = decodedToken.exp;
    const currentTime = Math.floor((new Date).getTime() / 1000);
    return tokenExpiry <= currentTime;
  }
}

