import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'src/app/modules/material.module';
import { NgxPermissionsModule } from 'ngx-permissions';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTableModule, MatPaginatorModule, MatSortModule } from '@angular/material';

import { AddUserGroupDialogComponent } from './components/add-group-dialog.component';
import { GroupTableComponent } from './components/user-group-table.component';
import { UserAddDialogComponent } from './components/user-add-dialog.component';
import { UserManagementContainerComponent } from './user-management.container';
import { UserManagementCardComponent } from './components/user-management-card.component';
import { UserTableComponent } from './components/user-table.component';
import { DirectoryEditDialogComponent } from './components/directory-edit-dialog.component';
import { ActiveDirectoryToolsDialogComponent } from './components/active-directory-tools-dialog.component';

import { IdentityProviderDetailsContainerComponent } from './identity-provider-details.container';
import { IdentityProviderUserCardComponent } from './components/identity-provider-user-card.component';
import { IdentityProviderGroupCardComponent } from './components/identity-provider-group-card.component';
import { IdentityProviderManagementComponent } from './components/identity-provider-management-card.component';

@NgModule({
  declarations: [
    UserManagementContainerComponent,
    IdentityProviderManagementComponent,
    UserManagementCardComponent,
    UserTableComponent,
    GroupTableComponent,
    AddUserGroupDialogComponent,
    UserAddDialogComponent,
    DirectoryEditDialogComponent,
    ActiveDirectoryToolsDialogComponent,
    IdentityProviderDetailsContainerComponent,
    IdentityProviderUserCardComponent,
    IdentityProviderGroupCardComponent

  ],
  imports: [
    CommonModule,
    MaterialModule,
    NgxPermissionsModule.forChild(),
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule
  ],

  exports: [UserManagementContainerComponent,
    IdentityProviderDetailsContainerComponent,
    IdentityProviderManagementComponent,
    UserManagementCardComponent],
  entryComponents: [
    DirectoryEditDialogComponent,
    ActiveDirectoryToolsDialogComponent,
    AddUserGroupDialogComponent,
    UserAddDialogComponent
  ]
})
export class UserManagementModule { }
