import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ChangePasswordDialogComponent } from './components/change-password-dialog/change-password-dialog.component';

@Component({
  selector: 'app-toolbar',
  styleUrls: ['./toolbar.component.scss'], // TODO:move style out of inline for font and images
  template: `

<mat-toolbar class="mat-elevation-z2" color="primary" fxLayout="row" fxLayoutAlign="start center" >
  <span>
    <img src="./../../assets/images/QVS-Teams-white-text-01.png" height="auto" width="150"/>
  </span>
  <div class='user-settinga-title-section' fxLayout="row" fxLayoutAlign="center center">
    <img src="./../../assets/images/AdminSettingIcon.png" height="auto" width="auto"/>
    <font size="2.75" style="margin-left:10px">{{toolbarTitle}}</font>
  </div>
  <span fxFlex></span>
  <div class='user-image-section'>
    <img src="./../../assets/images/UserWhiteIcon.png" height="30" width="auto"/>
  </div>
  <div  fxLayout="column">
    <div class='spacer'> </div>
    <font size="2.75" style="margin-left:10px" style='margin: -10px 0; vertical-align: bottom; text-align: left;'>{{userName}}</font>
    <font size="2.75" style='margin-left:10px text-align: left'>{{userRole}}</font>
  </div>
  <button mat-icon-button class="more-button" [matMenuTriggerFor]="menu" aria-label="Toggle menu">
    <mat-icon>expand_more</mat-icon>
  </button>
  <mat-menu #menu="matMenu" xPosition="before">
   <button mat-menu-item (click)= "logOutUser()">Log Out</button>
   <button mat-menu-item (click)= "changePassword()">Change Password</button>
  </mat-menu>
</mat-toolbar>
`
})

export class ToolbarComponent {
  constructor(public dialog: MatDialog) { }
  @Input() userName: string;
  @Input() userRole: string;
  @Input() toolbarTitle: string;

  @Output() passwordChange = new EventEmitter();
  @Output() logOut = new EventEmitter();

  logOutUser() {
    this.logOut.emit();
  }

  changePassword() {
    const dialogConfig = new MatDialogConfig();
    const dialogRef = this.dialog.open(ChangePasswordDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(
      newPassword => {
        if (newPassword) {
          const savePassword: string = newPassword;
          this.passwordChange.emit(savePassword);
        }
      }
    );

  }
}


