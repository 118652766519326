import { Component, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-login-card',
  styleUrls: ['./login-card.component.scss'],
  template: `
<div class='bg'>
  <div class='main-div' flex *ngIf='licenceNeeded !== undefined'>
   <mat-card  class="mat-elevation-z2">
      <div class='login-logo-area'>
        <img class='login-logo' src='/assets/images/QVS-Teams-Colored.png'/>
      </div>
      <div>
          <ng-container *ngIf="licenceNeeded">
            <div>
            <br />
              <app-licence-entry
                [InitSelectedButton]=''
                (generateRequest)='this.generateRequest.emit($event)'
                (activate)='this.activate.emit($event)'>
              </app-licence-entry>
            </div>
            <br />
            <br />
          </ng-container>
          <ng-container *ngIf="!licenceNeeded && adminNeeded">
             <app-admin-entry (creatAdminAccount)='this.creatAdminAccount.emit($event)'></app-admin-entry>
          </ng-container>
          <ng-container *ngIf="!licenceNeeded && !adminNeeded">
             <app-login (loginAttempt)='this.loginAttempt.emit($event)'></app-login>
          </ng-container>
       </div>
    </mat-card>
    </div>
</div>
`})

export class LoginCardComponent {
  @Input() licenceNeeded: boolean;
  @Input() adminNeeded: boolean;

  @Output() loginAttempt = new EventEmitter();
  @Output() creatAdminAccount = new EventEmitter();
  @Output() generateRequest = new EventEmitter();
  @Output() activate = new EventEmitter();
}
