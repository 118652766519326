import { Component, EventEmitter, Inject, OnInit, Output, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { UserGroup } from 'src/app/models/user-group.model';
import { Role } from 'src/app/models/role.model';
import { LicenceGroup } from 'src/app/models/licence-group.model';

@Component({
  selector: 'app-add-group-dialog',
  template: `
  <h1 mat-dialog-title>Add User Group from Active Directory </h1>
  <div mat-dialog-content fxLayout="column">
    <form [formGroup]="addGroupForm">
      <div class="groupNameContainer">
        <div class="groupInput">
          <mat-form-field>
            <input
              #groupNameInput
              matInput
              type="text"
              placeholder="Name"
              [formControl]="groupName"
              [matAutocomplete]="groupAutocomplete"
              required>
            <mat-autocomplete #groupAutocomplete="matAutocomplete">
              <mat-option *ngFor="let option of filteredGroups | async" [value]="option" matTooltip="{{option}}">
                {{option}}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="groupName.hasError('invalidAdGroup')">{{this.groupName.getError('invalidAdGroup')}}</mat-error>
          </mat-form-field>
        </div>
        <div class="groupSearch">
            <mat-icon
              matTooltip="Provide suggestions based on entered group name."
              (click)='this.searchForGroups()'>
                <img src='/assets/images/MagnifyIcon.png'/>
            </mat-icon>
        </div>
      </div>
      <div>
        <mat-form-field fxFlex>
          <mat-select [formControl]="groupRole" placeholder="Role" >
            <mat-option *ngFor="let role of roles" [value]="role">
              {{role?.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div>
      <mat-form-field fxFlex>
        <mat-select [formControl]="groupPools" placeholder="License Pools">
          <mat-option *ngFor="let pool of licencePools" [value]="pool">
            {{pool?.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
      <div>
        <mat-form-field *ngIf='this.displaySeats' fxFlex>
          <input matInput
                  type="number"
                  ng-pattern="onlyNumbers"
                  min="0"
                  autocomplete="off"
                  placeholder="Seats"
                  formControlName="groupSeats"
                  required>
        </mat-form-field>
      </div>
    </form>
  </div>
  <footer>
    <div mat-dialog-actions align="end">
      <button mat-button [disabled]="addGroupForm.invalid"  (click)="onSaveClick()">Save</button>
      <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Close</button>
    </div>
  </footer>
  `,
  styleUrls: ['./add-group-dialog.component.scss']
})
// what is the name of your group...from ad? validate
// do you want to associate all users in this group with a role?
// do you want to assoicat all users in this goroup with particular licence groups?
// done
export class AddUserGroupDialogComponent implements OnInit {
  @ViewChild('groupNameInput') groupNameInput: ElementRef;

  @Output() groupAdded = new EventEmitter();
  @Output() searchGroups = new EventEmitter();

  addGroupForm: FormGroup;
  displaySeats = false;
  validGroup = false;
  roles: Role[];
  licencePools: LicenceGroup[];
  groupOptions: string[] = [];
  filteredGroups: Observable<string[]>;
  groupName: FormControl;
  groupRole: FormControl;
  groupPools: FormControl;
  directoryId: number;

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<AddUserGroupDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: {
      directoryId: number,
      roles: Role[],
      licencePools: LicenceGroup[];
      groupOptions: string[]
    }) {
    this.groupOptions = this.data.groupOptions;
    this.roles = this.data.roles;
    this.licencePools = this.data.licencePools;
    this.directoryId = data.directoryId;
    this.groupName = new FormControl('', [Validators.required, this.validAdGroupName()]);
    this.groupRole = new FormControl('', [Validators.required]);
    this.groupPools = new FormControl('', []);
    this.addGroupForm = this.formBuilder.group({ groupName: this.groupName, groupRole: this.groupRole, licencePools: this.licencePools });


    this.filteredGroups = this.groupName.valueChanges
    .pipe(
      startWith(''),
      map(value => this._filter(value))
    );
  }

  ngOnInit() {
  }

  onSaveClick() {// TODO: build group obect and reflect in api
    const group = new UserGroup();
    group.name = this.groupName.value;
    group.directoryId = this.directoryId;
    group.roles = [this.groupRole.value];
    group.licenceGroups = [this.groupPools.value];

    this.groupAdded.emit(group);
  }

  // onRoleSelection(selectedRole: any) {
  //   if (selectedRole.value.hasSeats) {
  //     if (!this.addGroupForm.contains('groupSeats')) {
  //       this.addGroupForm.addControl('groupSeats', new FormControl(0, [Validators.min(0), Validators.required]));
  //     }
  //     this.displaySeats = true;
  //   } else {
  //     if (this.addGroupForm.contains('groupSeats')) {
  //       this.addGroupForm.removeControl('groupSeats');
  //     }
  //     this.displaySeats = false;
  //   }
  // }

  searchForGroups() {
    const groupName = this.groupName.value;
    if (groupName) {
      this.searchGroups.emit(groupName);
    }
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.groupOptions.filter(group => group.toLowerCase().indexOf(filterValue) >= 0);
  }

    updateGroupOptions(options: string[]) {
    this.groupOptions = options;
    // Trigger valueChanges so autocomplete options display without requiring an input change.
    this.groupName.updateValueAndValidity({ onlySelf: false, emitEvent: true });
    this.groupNameInput.nativeElement.focus();
  }

  validAdGroupName(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (this.groupOptions.indexOf(control.value) < 0) {
        return { 'invalidAdGroup': 'Name must be valid AD group' };
      }
      return null;
    };
  }
}
