import { Component, OnInit, ViewChild, Output, EventEmitter, AfterViewInit, OnDestroy } from '@angular/core';
import { MatTableDataSource, MatDialogConfig, MatDialog } from '@angular/material';

import { LicenceManagementCardComponent } from './components/licence-management-card.component';
import { LicenceService } from 'src/app/core/services/data/request/licence.service';

import { UserDetailData } from 'src/app/models/user-detail-data.model';
import { LicenceGroup } from 'src/app/models/licence-group.model';
import { Licence } from 'src/app/models/licence.model';
import { CustomSnackbarService } from 'src/app/core/services/helper/custom-snackbar.service';
import { AddLicenceGroupDialogComponent } from './components/add-licence-group-dialog.component';
import { first, takeUntil } from 'rxjs/operators';
import { ActiveDirectoryService } from 'src/app/core/services/data/request/active-directory.service';
import { DrawerService } from 'src/app/core/services/helper/drawer.service';
import { AdminDataService } from 'src/app/core/services/data/data.service';
import { Subject } from 'rxjs';
import { FileInput } from 'ngx-material-file-input';

@Component({
  selector: 'app-licence-management',
  template: `
  <app-licence-management-card
  [userDisplayedColumns]='this.activeUserDisplayColumns'
  [userDataSource]='this.activeUserDataSource'
  [groupDataSource]='this.groupDataSource'
  [hasLicenceGroups]='this.hasLicenceGroups'
  [groupDisplayedColumns]='this.groupDisplayedColumns'
  [licenceSeats]='this.licenceSeats'
  [licenceExpiry]='this.licenceExpiry'
  (showUserDetails)='onShowUserDetails($event)'
  (showGroupDetails)='onShowGroupDetails($event)'
  (addExternalGroup)='onAddLicenceGroup()'
  (updateSeats)='this.dataService.updateLicenceGroups($event)'
  (generateRequest)='onGenerateRequest($event)'
  (activate)='onActivate($event)'
  (refresh)='onRefresh()'>
  </app-licence-management-card>
  `
})

export class LicenceManagementContainerComponent implements OnInit, OnDestroy, AfterViewInit {

  constructor(
    public dataService: AdminDataService,
    public activeDirectoryService: ActiveDirectoryService,
    public licenceService: LicenceService,
    public drawerService: DrawerService,
    private dialog: MatDialog,
    private snackBarService: CustomSnackbarService
  ) { }

  @ViewChild(LicenceManagementCardComponent) childView: LicenceManagementCardComponent;

  @Output() showDetails = new EventEmitter();
  @Output() shownUserUpdate = new EventEmitter();

  activeUserDisplayColumns = ['status', 'name', 'role', 'lastActive', 'tokenExpiry', 'group'];
  groupDisplayedColumns = ['name', 'seats', 'activeSeats'];
  activeUserDataSource = new MatTableDataSource<UserDetailData>();
  licenceSeats: number;
  licenceExpiry: string;
  hasLicenceGroups: boolean;
  groupDataSource = new MatTableDataSource<LicenceGroup>();
  currentlySelectedUser: UserDetailData;
  currentlySelectedGroup: LicenceGroup;
  killSubscriptions = new Subject<void>();

  ngAfterViewInit(): void { }

  ngOnInit() {
    this.dataService.currentSelectedUserDetails
      .pipe(takeUntil(this.killSubscriptions))
      .subscribe(user => {
        this.currentlySelectedUser = user;
      });

    this.dataService.currentSelectedLicenceGroupDetails
      .pipe(takeUntil(this.killSubscriptions))
      .subscribe(group => {
        this.currentlySelectedGroup = group;
      });

    this.dataService.userDetails
      .pipe(takeUntil(this.killSubscriptions))
      .subscribe(userDetails => {
        const data: UserDetailData[] = [];
        userDetails.forEach(user => {
          if (user.activeSessions.length > 0) {
            user.activeSessions.forEach(session => {
              const activeUser: UserDetailData = Object.assign({}, user);
              activeUser.tokenExpiry = session.expiry;
              activeUser.assignedPool = session.licenceGroupAssignment;
              activeUser.systemId = session.systemId;
              data.push(activeUser);
            });
          }
        });
        this.activeUserDataSource.data = data.filter(u => u.tokenExpiry != null);
      });

    this.dataService.licenceGroupDetails
      .pipe(takeUntil(this.killSubscriptions))
      .subscribe(groupDetails => {
        if (groupDetails) {
          this.hasLicenceGroups = groupDetails.length > 0;
          this.groupDataSource.data = groupDetails;
        }
        this.licenceSeats = this.licenceService.seatCount;
      });

    this.dataService.licence
      .pipe(takeUntil(this.killSubscriptions))
      .subscribe((licence: Licence) => {
        if (licence) {
          this.licenceSeats = licence.totalSeats;
          this.licenceExpiry = licence.expiry;
        }
      }, () => {
        this.licenceSeats = 0;
      });
  }

  ngOnDestroy(): void {
    this.killSubscriptions.next();
    this.killSubscriptions.complete();
  }

  onGenerateRequest(userKey: String) {
    this.licenceService.getOfflineActivationRequestFile(userKey).subscribe((data: any) => {
      const blob = new Blob([data], { type: 'application/octet-stream' });
      const a = document.createElement('a');
      a.href = URL.createObjectURL(blob);
      a.download = 'qts_licence_request.dat';
      a.click();
      this.snackBarService.openSnackBar('Please give this request file to your QRA contact.',
        {
          verticalPosition: 'bottom',
          horizontalPosition: 'left',
          duration: 10000,
          panelClass: ['download']
        });
    });

  }

  onActivate(file: FileInput) {
    this.dataService.activate(file).subscribe();
  }

  onShowUserDetails(user: UserDetailData) {
    this.drawerService.openActiveLicenceDrawer();
    this.dataService.setCurrentSelectedUserDetails(user);
  }

  onRefresh() {
    this.dataService.refreshUserActivity();
  }

  onShowGroupDetails(group: LicenceGroup) {
    this.drawerService.openLicenceGroupDrawer();
    this.dataService.setCurrentSelectedLicenceGroupDetails(group);
  }

  onAddLicenceGroup() {
    this.dataService.providers.pipe(first()).subscribe(providers => {
      if (!providers) {
        return;
      }

      const dialogConfig = new MatDialogConfig();
      dialogConfig.minWidth = '400px';
      dialogConfig.data = { groupOptions: [] };
      const addGroupDialogRef = this.dialog.open(AddLicenceGroupDialogComponent, dialogConfig);

      addGroupDialogRef.componentInstance.groupAdded.subscribe(
        (group: LicenceGroup) => {
          this.dataService.addLicenceGroup(group).pipe(first()).subscribe(() => {
            addGroupDialogRef.close();
          }
          );
        }
      );
    });
  }
}
