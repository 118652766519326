import { Component, Output, EventEmitter } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-update-licence-dialog',
  template: `
<div mat-dialog-content fxLayout="column">
  <app-licence-entry
     [InitSelectedButton]='REQUEST_LICENCE'
     (generateRequest)='this.generateRequest.emit($event)'
     (activate)='this.activate.emit($event)'>
  </app-licence-entry>
</div>
<footer>
  <div mat-dialog-actions align="end">
    <button mat-button (click)="this.dialogRef.close()">Close</button>
  </div>
</footer>
`,
  styleUrls: ['./update-licence-dialog.component.scss']
})

export class UpdateLicenceDialogComponent {
  readonly  REQUEST_LICENCE = 'REQUEST LICENSE';

  @Output() generateRequest = new EventEmitter();
  @Output() activate = new EventEmitter();

  constructor(
    public dialogRef: MatDialogRef<UpdateLicenceDialogComponent>) {
  }

}
