import { Component, Inject, Output, EventEmitter, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';

import { Provider } from 'src/app/models/provider.model';


@Component({
  selector: 'app-directory-edit-dialog',
  template: `
<mat-horizontal-stepper [linear]="isLinear" #stepper>
<mat-step [stepControl]="createFormGroup">
<form [formGroup]="createFormGroup">
<br>
  <ng-template matStepLabel>Identify</ng-template>
  <div mat-dialog-content fxLayout="column">
  <mat-form-field>
    <input matInput placeholder="Directory Name" formControlName="directoryName" required>
  </mat-form-field>
  <mat-form-field>
  <input matInput placeholder="Directory Type" formControlName="directoryType" required>
</mat-form-field>
</div>
<div class ="spacer"></div>
<mat-divider></mat-divider>
<footer>
<div fxFlex></div>
  <div>
    <button mat-button [disabled]="createFormGroup.invalid" matStepperNext>Next</button>
  </div>
  </footer>
</form>
</mat-step>
<mat-step [stepControl]="configureFormGroup">
<ng-template matStepLabel>Configure</ng-template>
<div mat-dialog-content fxLayout="column">
  <form [formGroup]="configureFormGroup">
    <div>
      <mat-form-field fxFlex>
        <input matInput
        type="search"
        matTooltip="The distinguished name of the user that the application will use when connecting to the directory server. Examples:

       - cn=administrator,cn=users,dc=ad,dc=example,dc=com
       - cn=user,dc=domain,dc=name
       - user@domain.name

       The specific privileges required by the user to connect to LDAP are 'Bind' and 'Read'
       (user info, group info, group membership, update sequence number, deleted objects),
       which the user can obtain by being a member of the Active Directory's built-in administrators group."
        placeholder="Username"
        formControlName="bindUser"
        autocomplete="off"
        required>
      </mat-form-field>
    </div>
    <div class="input-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
	    <mat-form-field fxFlex>
        <input matInput
        type="password"
        matTooltip="The password of the user specified above.

        Note: Connecting to an LDAP server requires that this application log
                in to the server with the username and password configured here.
                As a result, this password cannot be one-way hashed - it must be
                recoverable in the context of this application. The password is
                currently stored in the database with obfuscation.
                To further guarantee its security, you need to ensure that other processes
                do not have OS-level read permissions for this application's database
                or configuration files"
        placeholder="Password"
        formControlName="password"
        autocomplete="off"
        required>
      </mat-form-field>
      <mat-form-field fxFlex>
        <input matInput
        matTooltip="The host name of your directory server and the port on which your directory server is listening. Examples:

        - ad.example.com:389
        - ldap.example.com:636 (for example, for SSL)"
        type="text"
        placeholder="Host:port"
        formControlName="host"
        autocomplete="off"
        required>
      </mat-form-field>
    </div>
    <div inline-flex>
      <mat-form-field  style="width:45%">
       <input matInput
       type="text"
       matTooltip="The root distinguished name (DN) to use when running queries against the directory server. Examples:

       - O=example,C=com
       - CN=users,DC=ad,DC=example,DC=com

       For Microsoft Active Directory, specify the base DN in the following format: DC=domain1,DC=local.
       You will need to replace the domain1 and local for your specific configuration. Microsoft Server
       provides a tool called ldp.exe which is useful for finding out and configuring the the LDAP structure
       of your server."
       placeholder="BaseDN"
       formControlName="baseDN"
       autocomplete="off"
       required>
      </mat-form-field>
      <mat-checkbox
      type="checkbox"
      matTooltip="Check this if the connection to the directory server is an SSL (Secure Sockets Layer) connection.
       Note that you will need to configure an SSL certificate in order to use this setting"
       [checked]="this.useSSL"
       (change)="this.useSSL=!this.useSSL">SSL</mat-checkbox>
    </div>
    <div *ngIf="useSSL">
    <mat-form-field fxFlex>
     <input matInput type="text"
     placeholder= "Certificate Path for SSL"
     formControlName="trustStore"
     autocomplete="off">
    </mat-form-field>
  </div>
  </form>
</div>
<div class ="spacer"></div>
<mat-divider></mat-divider>
<footer>
  <div mat-dialog-actions align="end">
    <button mat-button [disabled]="configureFormGroup.invalid"  (click)="onTestClick()">Test</button>
    <div  *ngIf='this.connection' fxLayout="row">
      <div  *ngIf="this.connection =='Fail'; else pass" #failure>
        <div  class="center" fxLayout="row">
          <img src= './../../../../../assets/images/RedX.png' height="30" width="auto"/>
        </div>
      </div>
      <ng-template #pass>
        <div  class="center" fxLayout="row">
          <img src= './../../../../../assets/images/GreenCheckmark.png' height="30" width="auto"/>
        </div>
      </ng-template>
    </div>
    <div fxFlex></div>
  <button mat-button matStepperPrevious>Back</button>
  <div *ngIf="this.connection ==='Pass'">
  <button mat-button matStepperNext>Next</button>
  </div>
  </div>
  <div>

</div>
</footer>
</mat-step>
<mat-step>
<ng-template matStepLabel>Save</ng-template>
<div class ="spacer"></div>
<div fxLayout="row">
  <img src= './../../../../../assets/images/GreenCheckmark.png' height="60" width="auto"/>
    <h2>&nbsp;&nbsp;Configuration Complete.</h2>
</div>

<div>
<div class ="spacer"></div>
<mat-divider></mat-divider>
<footer>
<div fxFlex></div>
  <button mat-button matStepperPrevious>Back</button>
  <button mat-button  (click)="onSaveClick()">Save</button>
  </footer>
</div>
</mat-step>
</mat-horizontal-stepper>
`,
  styleUrls: ['./directory-edit-dialog.component.scss']
})

export class DirectoryEditDialogComponent implements OnInit {
  isLinear = true;
  createFormGroup: FormGroup;
  configureFormGroup: FormGroup;
  connection = null;
  useSSL: Boolean = false;

  @Output() testConnection = new EventEmitter();
  @Output() updateProvider = new EventEmitter();
  directory: Provider;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { directory: Provider, connection: string },
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<DirectoryEditDialogComponent>) {
    this.connection = this.data.connection;
    this.directory = this.data.directory;
    this.useSSL = JSON.parse(this.directory.attributes['secure'] || false);
    this.createFormGroup = this.formBuilder.group({
      directoryName: [this.data.directory.name || '', [Validators.required]],
      directoryType: [this.data.directory.type || '', [Validators.required]],
    });
    this.configureFormGroup = this.formBuilder.group({
      bindUser: [this.data.directory.attributes['clientId'] || '', [Validators.required]],

      password: [this.data.directory.attributes['clientSecret'] ?
        atob(this.data.directory.attributes['clientSecret']) : '', [Validators.required]],
      host: [this.data.directory.attributes['authority'] || '', [Validators.required, Validators.pattern('[^\:]+:[0-9]{1,5}')]],
      baseDN: [this.data.directory.attributes['baseDomain'] || '', [Validators.required]],
      trustStore: [this.data.directory.attributes['certificatePath'] || ''],
    });
  }

  ngOnInit() {
    this.createFormGroup.get('directoryType').disable();
  }

  onSaveClick() {
    if (this.configureFormGroup.valid) {
      this.updateProvider.emit(this.getProviderDataFromForm());
    }
  }

  onTestClick() {
    if (this.configureFormGroup.valid) {
      this.testConnection.emit(this.getProviderDataFromForm());
    }
  }

  onCloseClick(): void {
    this.connection = null;
    this.dialogRef.close();
  }

  private getProviderDataFromForm(): Provider {

    const authority = this.configureFormGroup.get('host').value;
    const clientId = this.configureFormGroup.get('bindUser').value;
    const certificatePath = this.configureFormGroup.get('trustStore').value;
    const clientSecret = this.configureFormGroup.get('password').value;
    const baseDomain = this.configureFormGroup.get('baseDN').value;

    const m: Map<string, string> = new Map();

    if (authority) {
      m.set('authority', this.configureFormGroup.get('host').value);
    }
    if (clientId) {
      m.set('clientId', this.configureFormGroup.get('bindUser').value.trim());
    }
    if (certificatePath) {
      m.set('certificatePath', this.configureFormGroup.get('trustStore').value);
    }
    if (clientSecret) {
      m.set('clientSecret', btoa(this.configureFormGroup.get('password').value));
    }

    m.set('secure', String(this.useSSL));

    if (baseDomain) {
      m.set('baseDomain', this.configureFormGroup.get('baseDN').value.trim());
    }
    const convMap = {};
    m.forEach((val: string, key: string) => {
      convMap[key] = val;
    });
    this.directory.name = this.createFormGroup.get('directoryName').value;
    this.directory.type = this.createFormGroup.get('directoryType').value;
    this.directory.attributes = convMap;
    return this.directory;
  }

}
