import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { User } from 'src/app/models/user.model';
import { UserDetailData } from 'src/app/models/user-detail-data.model';

import { IdentityService } from './identity.service';
import { ActiveDirectoryService } from './active-directory.service';
import { Session } from 'src/app/models/session.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private http: HttpClient,
    public identityService: IdentityService,
    public activeDirectoryService: ActiveDirectoryService) { }

  initialize() {
    return this.getAllActiveUserData();
  }

  // resetst the current qtes user password
  resetPassword(newPassword: string) {
    let body = new HttpParams();
    body = body.set('newPassword', newPassword);
    return this.http.post<any>('/users/resetpassword', body).subscribe();
  }

  // resets the password of any qts user
  resetUserPassword(id: number, newPassword: string) {
    let body = new HttpParams();
    body = body
      .set('newPassword', newPassword)
      .set('id', id.toString());
    return this.http.post<any>('/users/resetUserpassword', body).subscribe();
  }

  isAdminNeeded(): Observable<boolean> {
    return this.http.get<boolean>('/users/admin');
  }

  addUser(newUser: User): Observable<any> {
    return this.http.post<any>('/users', newUser);
  }

  addAdminUser(input: FormData): Observable<any> {
    return this.http.post<any>('/users/admin', input);
  }

  editUser(newUser: User): Observable<any> {
    return this.http.put<any>('/users', newUser);
  }

  deleteUser(id: number): Observable<any> {
    const httpParams = new HttpParams().set('userId', id.toString());
    const options = { params: httpParams };
    return this.http.delete<any>('/users', options);
  }

  removeUserSession(userDetail: UserDetailData) {
    let body = new HttpParams();
    body =
      body.set('userName', userDetail.emailAddress)
        .set('systemId', userDetail.systemId);
    const options = { params: body };
    return this.http.delete<any>('/users/session', options);
  }

  getAllActiveUserData(): Observable<UserDetailData[]> {
    return this.http.get<User[]>('/users/')
      .pipe(map(data => {
        return this.getDetailsData(data);
      }));
  }

  // TODO: this is bad- we need to not mix objects, but that is a battle for tomorrow
  // need to clean up object usage
  private getDetailsData(userData: User[]): UserDetailData[] {
    const data: UserDetailData[] = [];
    for (const user of userData) {
      const userDetail = new UserDetailData();
      userDetail.userId = user.userId;
      userDetail.statusImagePath = this.getPath(user.activeSessions);
      userDetail.directoryId = user.directoryId;
      userDetail.name = user.name;
      userDetail.emailAddress = user.emailAddress;
      userDetail.roles = user.roles;
      userDetail.licenceGroups = user.licenceGroups;
      userDetail.lastActive = user.lastActive;
      userDetail.activeSessions = user.activeSessions;
      data.push(userDetail);
    }
    return data;
  }

  getUser(userDetail: UserDetailData): User {
    const user = new User();
      userDetail.statusImagePath = this.getPath(user.activeSessions);
      user.userId = userDetail.userId;
      user.directoryId = userDetail.directoryId;
      user.name = userDetail.name;
      user.emailAddress = userDetail.emailAddress;
      user.roles = userDetail.roles;
      user.licenceGroups = userDetail.licenceGroups;
      user.lastActive = userDetail.lastActive;
    return user;
  }

  private getPath(sessions: Session[]): string { // TODO: change this to be cleaner, bit of a hack to get working
    if (sessions && sessions.find(session => session.expiry !== null)) {
      return '/assets/images/GreenDot.png';
    } else {
      return '/assets/images/RedDot.png';
    }
  }
}


