import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatTableDataSource, MatDialog } from '@angular/material';

import { User } from 'src/app/models/user.model';
import { Provider } from 'src/app/models/provider.model';
import { ConfirmationDialogComponent } from 'src/app/modules/feature/common/confirmation-dialog/confirmation-dialog.component';
import { ProviderType } from 'src/app/models/provider-type.enum';

@Component({
  selector: 'app-identity-provider-management-card',
  template: `
  <br>
  <br>
  <div fxLayout="row wrap" fxLayoutAlign="space-around center" fxLayoutGap="10px grid">
  <div fxFlex *ngFor="let provider of directories" >
  <div flex>
    <mat-card class="mat-elevation-z2">
      <mat-card-header>
        <div mat-card></div>
        <mat-card-title><b>Name: </b>{{provider?.name}}</mat-card-title>
        <mat-card-title>
        <span  [ngClass]="{
          'dot': true,
          'connected' : provider?.isConnected,
          'not-connected' :  !provider?.isConnected}">
          </span>
          <span [ngClass]="{'connected-text' : provider?.isConnected,
          'not-connected-text' :  !provider?.isConnected}">
          {{provider.isConnected?"Connected":"Not Connected"}}
          </span>
        </mat-card-title>
        <mat-card-subtitle>Number of users in this directory: {{this.usersCountForDirectory(provider?.id )}}</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
      <div class="spacer"></div>
      <br>
      <br>
      <mat-divider></mat-divider>
      <div mat-dialog-actions align="end">
      <button  mat-button (click)="goToProviderDetails.emit(provider)">{{this.getDetailsButtonText(provider)}}</button>
      <div fxFlex> </div>
      <div >
        <button
          *ngIf="!isInternalProvider(provider)"
          mat-button
          matTooltip="Click here to delete this User Directory/Identity Provider"
          (click)="showRemoveDirectoryDialog(provider.id)">
          <mat-icon>delete</mat-icon>
        </button>
        <button
          *ngIf="!isInternalProvider(provider)"
          mat-button
          matTooltip="Click here to configure this User Directory/Identity Provider"
          (click)="this.configureDirectory.emit(provider)">
          <mat-icon>settings</mat-icon>
        </button>
       </div>
      </div>
    </mat-card-content>
   </mat-card>
  </div>
  </div>
</div>
  `,
  styleUrls: ['./identity-provider-management-card.component.scss']
})

export class IdentityProviderManagementComponent implements OnInit {

  @Input() directories: Provider[];
  @Input() userDataSource: MatTableDataSource<User>;

  @Output() configureDirectory = new EventEmitter();
  @Output() deleteDirectory = new EventEmitter();
  @Output() goToProviderDetails = new EventEmitter();
  constructor(public dialog: MatDialog) { }

  ngOnInit() { }

  isInternalProvider(provider: Provider): boolean {
    if (provider ) {
      return  provider.type === ProviderType.Internal;
    }
    return false;
  }

  getDetailsButtonText(directory: Provider): string {
    if (directory.type  === ProviderType.ActiveDirectory) {
      return 'SEE USERS & GROUPS';
    }
    return 'SEE USERS';
  }

  usersCountForDirectory(id: number) {
    return this.userDataSource.data.filter(u => u.directoryId === id).length;
  }

  showRemoveDirectoryDialog(directoryID: number) {
    const confirmDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      data: {
        title: 'Warning!',
        content: 'Removing this Directory will remove all references in the system. Do you wish to continue?',
        confirmText: 'Ok',
        rejectText: 'Cancel'
      }
    });

    confirmDialogRef.componentInstance.confirmation.subscribe(() => {
      this.deleteDirectory.emit(directoryID);
    });

  }
}
