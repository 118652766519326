import { Component, OnInit, ViewChild, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, MatSortable } from '@angular/material';
import { Provider } from 'src/app/models/provider.model';
import { UserGroup } from 'src/app/models/user-group.model';

@Component({
  selector: 'app-user-group-table',
  template: `
  <div class='configuration-card-content'>
      <table mat-table #table class="full-width-table" [dataSource]="userGroupDataSource" matSort>
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
          <td mat-cell class ="top-text" *matCellDef="let element">
            {{element?.name}}
          </td>
        </ng-container>
        <ng-container matColumnDef="role">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Role</th>
        <td mat-cell class ="top-text" *matCellDef="let element">
          {{element?.roles[0]?.description}}
        </td>
        </ng-container>
        <ng-container matColumnDef="licencegroup">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>License Pools</th>
        <td mat-cell class ="top-text" *matCellDef="let element">
        {{this.getLicencePools(element)}}
        </td>
        </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row
      (click)="this.showDetails(row)"
      *matRowDef="let row; columns: displayedColumns; let entry"></tr>
    </table>
    <mat-paginator
      [pageSizeOptions]="[10, 20, 40]"
      showFirstLastButtons>
    </mat-paginator>
  </div>
  `,
  styleUrls: ['./user-group-table.component.scss'],
})

export class GroupTableComponent implements OnInit, AfterViewInit {

  @Input() directories: Provider[];
  @Input() userGroupDataSource: MatTableDataSource<UserGroup>;
  @Input() displayedColumns: string[];

  @Output() selectedRow: UserGroup;
  @Output() showGroupDetails = new EventEmitter();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  selectedrow: any;

  ngOnInit() {
    this.userGroupDataSource.paginator = this.paginator;
    this.sort.sort(<MatSortable>({id: 'lastActive', start: 'desc'}));
    this.userGroupDataSource.sort = this.sort;
  }

  ngAfterViewInit(): void {
    this.userGroupDataSource.sortingDataAccessor = (data, sortHeaderId: string) => {
      switch (sortHeaderId) {
        case 'name': {
          return data.name;
        }
        case 'role': {
          return data.roles[0].description;
        }
        case 'licencegroup': {
          return this.getLicencePools(data);
        }
        default: {
          return data[sortHeaderId];
        }
      }
    };
  }


  getLicencePools(group: UserGroup) {
    return Object.keys(group.licenceGroups)
      .map(function (k) { return group.licenceGroups[k].name; }).join(', ');
  }

  showDetails(row: UserGroup) {
    this.selectedRow = row;
    this.showGroupDetails.emit(row);
  }
}


