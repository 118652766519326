import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatTableDataSource, MatDialog } from '@angular/material';
import { Provider } from 'src/app/models/provider.model';
import { UserDetailData } from 'src/app/models/user-detail-data.model';
import { UserGroup } from 'src/app/models/user-group.model';

@Component({
  selector: 'app-identity-provider-user-management-card',
  template: `
  <div *ngxPermissionsOnly="['groups:*']" class="mat-elevation-z2">
  <mat-card class="user-management-card">
    <mat-card-header class="user-management-header">
      <mat-card-title>
        Users
        <div fxFlex></div>
        <button  *ngIf="!this.isAD"
        mat-button  matTooltip="Click here to Add user"
        (click)="this.addUser.emit(currentProvider?.id)">ADD USER</button>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content class="user-group-card-content">
    <mat-divider></mat-divider>
    <app-user-table
    [isProvider] ='true'
    [groups] ='this.userGroups'
    [displayedColumns]='this.userDisplayedColumns'
    [userDataSource]='this.userDataSource'
    [directories]='this.directories'
    (showUserDetails)='this.showUserDetails.emit($event)'>
   </app-user-table>
  </mat-card-content>
  </mat-card>
  </div>
  `,
  styleUrls: ['./identity-provider-user-card.component.scss']
})
export class IdentityProviderUserCardComponent {
  @Input() currentProvider: Provider;
  @Input() userGroups: UserGroup[];
  @Input() directories: Provider[];
  @Input() userDisplayedColumns: string[];
  @Input()
  set users(users: UserDetailData[]) {
    if (users) {
      this.userDataSource.data = users;
    }
  }
  @Input() isAD: boolean;

  @Output() showUserDetails = new EventEmitter();
  @Output() addUser = new EventEmitter();
  @Output() searchAd = new EventEmitter();
  @Output() deleteDirectory = new EventEmitter();

  userDataSource = new MatTableDataSource<UserDetailData>();

  constructor(public dialog: MatDialog) { }

}
