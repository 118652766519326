import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from '../../../material.module'; // sTODO: export and not import all over?
import { AdminDashboardComponent } from './admin-dashboard.container'; // TODO: add path @app
import { NgxPermissionsModule } from 'ngx-permissions';
import { ConfigurationModule } from './components/configuration/configuration.module';
import { UserGroupModule } from './components/licence-management/licence-management.module';
import { MonitoringModule } from './components/metrics/monitoring.module';
import { UserManagementModule } from './components/user-management/user-management.module';


@NgModule({
  declarations: [
    AdminDashboardComponent
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MaterialModule,
    NgxPermissionsModule.forChild(),
    ConfigurationModule,
    UserGroupModule,
    UserManagementModule,
    MonitoringModule,
  ]
})
export class AdminDashboardModule { }

