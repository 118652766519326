import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MonitoringContainerComponent } from './monitoring.container';
import { MonitoringCardComponent } from './components/monitoring-card.component';
import { MaterialModule } from 'src/app/modules/material.module';
import { NgxPermissionsModule } from 'ngx-permissions';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ApplicationEventsDialogComponent } from './components/application-events-dialog.component';

@NgModule({
  declarations: [MonitoringContainerComponent, MonitoringCardComponent, ApplicationEventsDialogComponent],
  imports: [
    CommonModule,
    MaterialModule,
    NgxPermissionsModule.forChild(),
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [MonitoringContainerComponent, MonitoringCardComponent],
  entryComponents: [ApplicationEventsDialogComponent],
})
export class MonitoringModule { }
