import { Component, OnInit, ViewChild, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, MatSortable } from '@angular/material';
import { UserDetailData } from 'src/app/models/user-detail-data.model';
import { Provider } from 'src/app/models/provider.model';
import { UserGroup } from 'src/app/models/user-group.model';

@Component({
  selector: 'app-user-table',
  template: `
  <div class='configuration-card-content'>
      <table mat-table #table class="full-width-table" [dataSource]="userDataSource" matSort>
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
          <td mat-cell class ="top-text" [matTooltip]="getUserDesciption(element)" *matCellDef="let element">
            {{element?.name}}
          </td>
        </ng-container>
        <ng-container matColumnDef="role">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Role</th>
          <td mat-cell class ="top-text" *matCellDef="let element">
             {{this.getDisplayRole(element)}}
          </td>
        </ng-container>
        <ng-container matColumnDef="directory">
          <ng-container *ngIf='!this.isProvider; else group;'>
           <th mat-header-cell *matHeaderCellDef mat-sort-header>Identity Provider</th>
            <td mat-cell class ="top-text" *matCellDef="let element">
             {{this.getDirectoryName(element?.directoryId)}}
            </td>
          </ng-container>
          <ng-template #group>
          <ng-container matColumnDef="group">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Group</th>
          <td mat-cell class ="top-text" *matCellDef="let element">
           {{this.getUserGroup(element)}}
          </td>
          </ng-container>
          </ng-template>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row
      (click)="showDetails(row)"
      *matRowDef="let row; columns: displayedColumns;
      let entry"[ngClass]="{ 'highlight': entry.emailaddress ==  'test@test.com' }"></tr>
    </table>
    <mat-paginator
      [pageSizeOptions]="[10, 20, 40]"
      showFirstLastButtons>
    </mat-paginator>
  </div>
  `,
  styleUrls: ['./user-table.component.scss'],
})

export class UserTableComponent implements OnInit, AfterViewInit {

  @Input() isProvider: boolean;
  @Input() groups: UserGroup[];
  @Input() directories: Provider[];
  @Input() userDataSource: MatTableDataSource<UserDetailData>;
  @Input() displayedColumns: string[];

  @Output() selectedRow: UserDetailData;
  @Output() showUserDetails = new EventEmitter();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  selectedrow: any;

  ngOnInit() {
    this.userDataSource.paginator = this.paginator;
    this.sort.sort(<MatSortable>({ id: 'name', start: 'asc' }));
    this.userDataSource.sort = this.sort;
  }

  ngAfterViewInit() {
    this.userDataSource.sortingDataAccessor = (data, sortHeaderId: string) => {
      switch (sortHeaderId) {
        case 'role': {
          return this.getDisplayRole(data);
        }
        case 'directory': {
          return this.getDirectoryName(data.directoryId);
        }
        case 'group': {
          return this.getUserGroup(data);
        }
        default: {
          return data[sortHeaderId];
        }
      }
    };
  }

  getPropertyByPath(obj: Object, pathString: string) {
    return pathString.split('.').reduce((o, i) => o[i], obj);
  }

  showDetails(row: UserDetailData) {
    this.selectedRow = row;
    this.showUserDetails.emit(row);
  }
  getUserDesciption(user: UserDetailData) {
    return user.emailAddress;
  }

  getDirectoryName(id: number) {
    if (this.directories) {
      const dir = this.directories.find(d => d.id === id);
      return dir ? dir.name : '';
    }
    return '';
  }
  getDisplayRole(user: UserDetailData) {
    return Object.keys(user.roles)
      .map(function (k) { return user.roles[k].description; }).join(', ');
  }

  getUserGroup(user: UserDetailData) {
    const userGroupNames: String[] = [];

    this.groups.forEach(group => {
      if (group.users) {
        const hasUser = group.users.find(u => u.userId === user.userId);
        if (hasUser) {
          userGroupNames.push(group.name);
        }
      }
    });

    return Object.keys(userGroupNames)
      .map(function (k) { return userGroupNames[k]; }).join(', ');
  }

  getStatusMessage(user: UserDetailData): string {
    if (user.tokenExpiry) {
      return 'User is active';
    } else {
      return 'User does not have an active session';
    }
  }

  getTimeDifferenceFromLastActive(user: UserDetailData) {
    const lastActive = new Date(user.lastActive);
    const now = new Date();
    return Math.floor((now.getMinutes() - lastActive.getMinutes()));
  }

}


