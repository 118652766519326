import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, EMPTY } from 'rxjs';
import { map } from 'rxjs/operators';

import { Provider } from '../../../../models/provider.model';
import { ActiveDirectoryService } from './active-directory.service';

@Injectable({
  providedIn: 'root'
})

export class IdentityService {

  constructor(
    private http: HttpClient,
    private activeDirectoryService: ActiveDirectoryService
  ) {}


  initialize(): Observable<Provider[]> {
        return this.getAvailableProviders();
  }

  getAvailableProviders(): Observable<Provider[]> {
    return this.http.get<Provider[]>('/providers').pipe(map(providers => {
      if (providers) {
        providers.forEach(provider => {
          this.activeDirectoryService.connectToProvider(provider)
          .subscribe(connected => {
            provider.isConnected = connected;
          });
        });
        return providers;
      }

      return null;
    }));
  }

  getSupportedProviderTypes(): Observable<any> {
    return this.http.get<any>('/providers/types');
   }

  attemptToChangeProvider(provider: Provider): Observable<Provider> {
    if (provider) {
      return this.http.post<any>('/providers', provider, { observe: 'response' })
      .pipe(map(response => response.body));
    }
    return EMPTY;
  }

  deleteProvider(id: number): Observable<any> {
    if (id) {
      const httpParams = new HttpParams().set('id', id.toString());
      const options = { params: httpParams };
      return this.http.delete<any>('/providers', options);
    }
    return EMPTY;
  }

}

