import { Injectable } from '@angular/core';
import { LicenceService } from './licence.service';
import { LicenceGroup } from 'src/app/models/licence-group.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class LicenceGroupService {

  constructor(private licenceService: LicenceService) { }

    initialize(): Observable<LicenceGroup[]> {
        return this.licenceService.getLicenceGroups();
    }
}
