import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { FormErrorStateMatcherService } from 'src/app/core/services/helper/form-error-state-matcher.service';

@Component({
  selector: 'app-change-password-dialog',
  template: `
  <h1 mat-dialog-title>Reset Password</h1>
  <div mat-dialog-content fxLayout="column">
  <form [formGroup]="passwordForm">
  <div>
  <mat-form-field>
		<input matInput type="password" placeholder="New password" formControlName="newPassword" required>
		<mat-error *ngIf="passwordForm.hasError('required')">
			Please enter your new password
		</mat-error>
  </mat-form-field>
  </div>
<div>
	<mat-form-field fxFlex>
		<input matInput type="password" placeholder="Confirm password" formControlName="confirmPassword" [errorStateMatcher]="matcher">
  		<mat-error *ngIf="passwordForm.hasError('notSame')">
			  Passwords do not match
		  </mat-error>
  </mat-form-field>
  </div>
</form>
  </div>
  <div mat-dialog-actions>
    <button mat-button  (click)="onCancelClick()" cdkFocusInitial>Cancel</button>
    <button mat-button  [disabled]= "passwordForm.hasError('notSame')" (click)="onOkClick()" >Ok</button>
  </div>
  `,
  styleUrls: ['./change-password-dialog.component.scss']
})

export class ChangeUserPasswordDialogComponent {
  passwordForm: FormGroup;
  matcher = new FormErrorStateMatcherService();

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<ChangeUserPasswordDialogComponent>) {
    this.passwordForm = this.formBuilder.group({
      newPassword: ['', [Validators.required]],
      confirmPassword: ['']
    }, { validator: this.checkPasswords });
  }

  checkPasswords(group: FormGroup) {
    const pass = group.controls.newPassword.value;
    const confirmPass = group.controls.confirmPassword.value;
    return pass === confirmPass && confirmPass !== '' ? null : { notSame: true };
  }

  onOkClick() {
    this.dialogRef.close(this.passwordForm.value.newPassword);
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }

}
