import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { ActivatedRouteSnapshot } from '@angular/router';

import { AuthorizationService } from '../services/data/request/auth.service';

@Injectable({
  providedIn: 'root'
})

/**
 * Guard for activate a Guarded Route depending on authorized user being 'logged in'-having
 * a valid token
 */
export class NeedAuthGuard implements CanActivate {

  constructor(private authService: AuthorizationService, private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot) {

    const redirectUrl = route['_routerState']['url'];

    if (this.authService.isLoggedIn()) {
      return true;
    }

    this.router.navigateByUrl(
      this.router.createUrlTree(
        ['login'], {
          queryParams: {
            redirectUrl
          }
        }
      )
    );

    return false;
  }
}
