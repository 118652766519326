import { UserDetailData } from './user-detail-data.model';
import { UserGroup } from './user-group.model';

export class LicenceGroup {
    id: number;
    name: string;
    seats: number;
    activeSeats: number;
    users: UserDetailData[];
    userGroups: UserGroup[]; // line these up again with server...don;t abuse
    licenceDuration: number;
}
