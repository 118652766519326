import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { AuthorizationService } from '../services/data/request/auth.service';

import { UrlService } from '../services/helper/url.service';

@Injectable({
    providedIn: 'root'
})

/**
 * Intercepts Http Request and Response.
 * Ensures the user token is added to the header for proper server authorization.
 * Ensures the local user token is updated on response.
 */
export class TokenInterceptor implements HttpInterceptor {

    private baseUrl: string;

    constructor(public authService: AuthorizationService, urlService: UrlService) {
        this.baseUrl = urlService.getHostname();
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (request.url.includes('assets/svg/')) {
            return next.handle(request);
        }
        // add authorization token to header of every request except login
        if (!request.url.includes('login')) {
            request = this.addTokenToHeader(request);
        }
        const apiRequest = request.clone({ url: `${this.baseUrl}${request.url}` });
        // update token after each response
        return next.handle(apiRequest).pipe(tap((event: HttpEvent<any>, ) => {
            if (event instanceof HttpResponse) {
                this.updateStoredToken(event.headers);
            }
            return next.handle(apiRequest);

        }, (error: any) => {
            if (error instanceof HttpErrorResponse) {
                this.updateStoredToken(error.headers);
            }
            return next.handle(request);
        },
        ));
    }

    private addTokenToHeader(request: HttpRequest<any>): HttpRequest<any> {
        const jwt: string = this.authService.getToken();
        if (!!jwt) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${jwt}`
                }
            });
        }
        return request;
    }

    private updateStoredToken(headers: HttpHeaders) {
        const bearerToken: string | null = headers.get('Authorization');
        if (bearerToken) {
            const tokenArray = bearerToken.split(' ');
            const token = tokenArray[1];
            if (token) {
                this.authService.setToken(token);
            }
        }
    }
}





