import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatTableDataSource, MatDialog } from '@angular/material';
import { Provider } from 'src/app/models/provider.model';
import { UserDetailData } from 'src/app/models/user-detail-data.model';
import { ProviderType } from 'src/app/models/provider-type.enum';

@Component({
  selector: 'app-user-management-card',
  template: `
  <div *ngxPermissionsOnly="['groups:*']" class="mat-elevation-z2">
  <mat-card class="user-management-card">
    <mat-card-header class="user-management-header">
      <mat-card-title>
        User Management
        <div fxFlex></div>
        <button  mat-button  matTooltip="Click here to Add user"  (click)="this.addUser.emit(null)">ADD USER</button>
        <button  mat-button  matTooltip="Click here to Add a User Directory/Identity Provider"  (click)="showAddDirectoryDialog()">
        ADD IDENTITY PROVIDER
        </button>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content class="user-group-card-content">
    <mat-divider></mat-divider>
    <mat-tab-group>
    <mat-tab>
    <ng-template mat-tab-label >
    <div class= "stacked">
    <mat-icon  svgIcon="qts-users"></mat-icon>
    <span>Users</span>
    </div>
    </ng-template>
      <app-user-table
        [isProvider] ='false'
        [groups] ='null'
        [displayedColumns]='this.metaUserDisplayedColumns'
        [userDataSource]='this.userDataSource'
        [directories]='this.directories'
        (showUserDetails)='this.showUserDetails.emit($event)'>
       </app-user-table>
    </mat-tab>
    <mat-tab>
    <ng-template mat-tab-label>
    <div class= "stacked">
    <mat-icon svgIcon="qts-provider"></mat-icon>
    <span>Identity Providers</span>
    </div>
    </ng-template>
      <app-identity-provider-management-card
        [directories]='this.directories'
        [userDataSource]='this.userDataSource'
        (configureDirectory)='this.configureDirectory.emit($event)'
        (deleteDirectory)='this.deleteDirectory.emit($event)'
        (goToProviderDetails)='this.goToProviderDetails.emit($event)'>
      </app-identity-provider-management-card>
      <br>
    </mat-tab>
    </mat-tab-group>
  </mat-card-content>
  </mat-card>
  </div>
  `,
  styleUrls: ['./user-management-card.component.scss']
})
export class UserManagementCardComponent {
  @Input() directories: Provider[];
  @Input() metaUserDisplayedColumns: string[];
  @Input() userDisplayedColumns: string[];
  @Input() userDataSource: MatTableDataSource<UserDetailData>;

  @Output() showUserDetails = new EventEmitter();
  @Output() showGroupDetails = new EventEmitter();
  @Output() configureDirectory = new EventEmitter();
  @Output() addExternalGroup = new EventEmitter();
  @Output() addUser = new EventEmitter();
  @Output() searchAd = new EventEmitter();
  @Output() deleteDirectory = new EventEmitter();
  @Output() goToProviderDetails = new EventEmitter();

  constructor(public dialog: MatDialog) { }


  showAddDirectoryDialog() {
    const directory = new Provider();
    directory.attributes = {};
    directory.type = ProviderType.ActiveDirectory;
    this.configureDirectory.emit(directory);
  }
}
