import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { UserDetailData } from 'src/app/models/user-detail-data.model';
import { UserGroup } from 'src/app/models/user-group.model';
import { AuthorizationService } from './auth.service';


@Injectable({
  providedIn: 'root'
})

export class UserGroupService {

  constructor(private http: HttpClient, private authService: AuthorizationService) { }

  initialize(): Observable<UserGroup[]> {
    return this.getUserGroups();
  }


  getUserGroups(): Observable<UserGroup[]> {
    if (this.authService.isLoggedIn()) {
      return this.http.get<UserGroup[]>('/usergroups');
    } else {
      return EMPTY;
    }
  }

  getUserGroupUsers(groupId: number): Observable<UserDetailData[]> {
    if (this.authService.isLoggedIn()) {
      const httpParams = new HttpParams().set('id', groupId.toString());
      const options = { params: httpParams };
      return this.http.get<UserDetailData[]>('/usergroups/users', options);
    } else {
      return EMPTY;
    }
  }

  updateUserGroups(groups: UserGroup[]): Observable<UserGroup[]> {
    return this.http.put<UserGroup[]>('/usergroups', groups);
  }


  addUserGroup(group: UserGroup): Observable<number> {
    return this.http.post<number>('/usergroups', group);
  }

  deleteUserGroup(group: UserGroup): Observable<any> {
    if (group && group.id) {
      const httpParams = new HttpParams().set('id', group.id.toString());
      const options = { params: httpParams };
      return this.http.delete<any>('/usergroups', options);
    }

    return EMPTY;
  }
}
