import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginCardComponent } from './components/login-card/login-card.component';
import { MaterialModule } from '../../material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { LoginContainerComponent } from './login.container';
import { LoginEntryComponent } from './components/login-entry/login-entry.component';
import { AdminEntryComponent } from './components/admin-entry/admin-entry.component';
import { CommonComponentsModule } from '../common/common.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    FlexLayoutModule,
    CommonComponentsModule
  ],
  declarations: [LoginCardComponent, LoginContainerComponent, AdminEntryComponent, LoginEntryComponent],
  exports: [
    LoginCardComponent, // remove component???
    LoginContainerComponent
  ]
})
export class LoginModule { }

// TODO:
// make login dumb
// add password entry dialog
// add password change handling
