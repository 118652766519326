import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatTableDataSource, MatSortable, MatSort } from '@angular/material';

import { ActiveDirectoryUser } from 'src/app/models/active-directory-user.model';
import { ActiveDirectorySearchType } from 'src/app/models/active-directory-search-type.enum';

@Component({
  selector: 'app-active-directory-tools-dialog',
  template: `
  <h1 mat-dialog-title>Active Directory Tools</h1>
  <div mat-dialog-content>
    <table mat-table [dataSource]="dataSource" matSort aria-label="Elements">
      <ng-container matColumnDef="userPrincipal">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>User Principal</th>
          <td mat-cell *matCellDef="let row">{{row?.userPrincipal}}</td>
      </ng-container>

      <ng-container matColumnDef="memberOf">
          <th mat-header-cell  *matHeaderCellDef mat-sort-header>Member Of</th>
          <td mat-cell *matCellDef="let row">
            <span *ngFor='let group of row.memberOf'>{{group}}<br/></span>
          </td>
      </ng-container>

      <ng-container matColumnDef="commonName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Common Name</th>
          <td mat-cell *matCellDef="let row">{{row?.commonName}}</td>
      </ng-container>

      <ng-container matColumnDef="distinguishedName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Distinguished Name</th>
        <td mat-cell *matCellDef="let row">{{row?.distinguishedName}}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <div *ngIf='this.noResults'>
      <h3 class="no-results">No matching results found</h3>
    </div>
  </div>
  <div class="spacer"></div>
  <div mat-dialog-actions>
    <div class="table-footer-container">
      <div class="left-footer-content">
        <mat-form-field class="search-text">
          <input matInput type="text" placeholder="Search" [formControl]="searchControl">
        </mat-form-field>
        <button mat-button (click)='this.searchUsers()'>Search Users</button>
        <button mat-button (click)='this.searchGroups()'>Search Groups</button>
        <button mat-button (click)='this.searchAll()'>Find All</button>
      </div>
      <div class="right-footer-content">
        <button mat-button [mat-dialog-close]="true">Close</button>
      </div>
    </div>
  </div>
  `,
  styleUrls: ['./active-directory-tools-dialog.component.scss']
})
export class ActiveDirectoryToolsDialogComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort;

  @Output() searchAd = new EventEmitter();

  private searchResults = [];
  displayedColumns = [ 'userPrincipal', 'memberOf', 'commonName', 'distinguishedName' ];
  dataSource = new MatTableDataSource(this.searchResults);
  searchControl: FormControl;
  noResults = false;

  constructor() {
    this.searchControl = new FormControl('');
  }

  ngOnInit() {
    const defaultSort: MatSortable = {
      id: 'created',
      start: 'desc',
      disableClear: false
    };
    this.sort.sort(defaultSort);
    this.dataSource.sort = this.sort;
  }

  updateSearchResults(searchResults: ActiveDirectoryUser[]) {
    this.noResults = (searchResults === undefined || searchResults.length === 0);
    this.dataSource.data = searchResults;
  }

  searchGroups() {
    this.search(this.searchControl.value, ActiveDirectorySearchType.Group);
  }

  searchUsers() {
    this.search(this.searchControl.value, ActiveDirectorySearchType.User);
  }

  searchAll() {
    this.search('all', ActiveDirectorySearchType.All);
  }

  private search(searchValue: string, searchType: ActiveDirectorySearchType) {
    this.searchAd.emit({ searchValue, searchType });
  }
}
