import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { MatDialog, MatSelect } from '@angular/material';

import { ConfirmationDialogComponent } from 'src/app/modules/feature/common/confirmation-dialog/confirmation-dialog.component';

import { UserDetailData } from 'src/app/models/user-detail-data.model';
import { Provider } from 'src/app/models/provider.model';
import { UserGroup } from 'src/app/models/user-group.model';
import { Role } from 'src/app/models/role.model';
import { LicenceGroup } from 'src/app/models/licence-group.model';
import { ProviderType } from 'src/app/models/provider-type.enum';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { Session } from 'src/app/models/session.model';
import { UserActiveDirectoryIdentityDetailData } from 'src/app/models/user-identity-detail.model';

@Component({
  selector: 'app-user-detail',
  template: `
  <div class="panel" fxLayout="column" >
  <div class ="container" fxLayout="column" fxLayoutAlign="stretch" fxFlex="100%">
     <div class="spaced">
        <h1>
           <img class ="status" *ngIf="formUserData" height="14" width="auto" [src]="this.getPath(formUserData?.activeSessions)" />
           &nbsp;{{formUserData?.name}}
        </h1>
     </div>
     <div class="spaced">
        <b>Username:</b>&nbsp;{{formUserData?.emailAddress}}
     </div>
     <div *ngIf="!this.isAdUser()" class="spaced">
        <ng-container *ngIf="this.edittingDetails; else password">
           <b>Password:</b>&nbsp;
           <button  mat-button (click)="this.onChangePassword()">Set New Password</button>
        </ng-container>
        <ng-template #password>
           <b>Password:</b>&nbsp;**********
        </ng-template>
     </div>
     <div class="spaced">
        <b>Role:</b>
        <ng-container
        *ngIf="this.edittingDetails && formUserData?.emailAddress.toUpperCase() !== this.adminEmail.toUpperCase(); else roleLabel">
           <mat-form-field>
              <mat-select [(ngModel)]="selectedRole">
              <mat-option
              *ngFor="let role of this.roles"
              [value]="role">
              {{role.description}}
              </mat-option>
              </mat-select>
           </mat-form-field>
        </ng-container>
        <ng-template #roleLabel>
           {{this.assignedRoles}}
        </ng-template>
     </div>
     <div class="spaced">
        <b>Identity Provider:</b>&nbsp;{{this.provider?.name}}
     </div>
     <div class="spaced" fxLayout="column" fxLayoutAlign="stretch" fxFlex="1 1 20%">
        <b>License Pools:</b>
        &nbsp;
        <perfect-scrollbar flex style="max-height: 10vh;" [config]="this.config">
           <ng-container *ngIf="this.formLicenceGroups && this.formLicenceGroups?.length>0; else no_groups">
              <ng-container
                 *ngFor="let licenceGroup of this.formLicenceGroups; index as i;">
                 <div>
                    <button mat-flat-button [autofocus]="false" (click)="this.showLicenceGroupDetails.emit(licenceGroup)">
                    {{licenceGroup.name}}
                    </button>
                    <button class="icon"
                    mat-icon-button
                    *ngIf="this.edittingDetails"
                    (click)="this.removeGroup(licenceGroup)">
                    <mat-icon>remove</mat-icon>
                    </button>
                 </div>
              </ng-container>
           </ng-container>
           <ng-template #no_groups>
              <div class="spaced">
                 (No License Pools Associated with this User)
              </div>
           </ng-template>
        </perfect-scrollbar>
        <div *ngIf="this.edittingDetails && this.unAssignedGroups().length>0">
           <mat-form-field>
              <mat-select
              [(ngModel)]="selectedLicences"
              (openedChange)="licenceChooserOpenChange($event)"
              #licenceSelect
              multiple>
              <mat-option
              *ngFor="let licenceGroup of this.unAssignedGroups()"
              [value]="licenceGroup">
              {{licenceGroup.name}}
              </mat-option>
              </mat-select>
           </mat-form-field>
           <button class="icon"
              mat-icon-button
              (click)="openLicenceSelector()">
              <mat-icon>add</mat-icon>
           </button>
        </div>
     </div>
     <div class="spaced" fxLayout="column" fxLayoutAlign="stretch" fxFlex="1 1 auto" *ngIf="this.userGroups && this.userGroups?.length>0">
        <b>User Groups:</b>
        &nbsp;
        <perfect-scrollbar flex style="max-height: 10vh;" [config]="this.config">
           <ng-container
              *ngFor="let userGroup of this.userGroups; index as i;">
              <div>
                 <button mat-flat-button [autofocus]="false" (click)="this.showUserGroupDetails.emit(userGroup)">
                 {{userGroup.name}}&nbsp;[{{userGroup.roles[0].description}}]
                 </button>
              </div>
           </ng-container>
        </perfect-scrollbar>
     </div>
     <div    *ngIf='identityDetails'  fxLayout="column" fxLayoutAlign="stretch" fxFlex="2 1 auto">
        <br>
        <mat-card flex  >
           <h3>Active Directory Details</h3>
           <div class="spaced" >
              <b>Common Name:</b>&nbsp;{{identityDetails?.commonName}}
           </div>
           <div class="spaced">
              <b>Distinguished Name:</b>&nbsp;{{identityDetails?.distinguishedName}}
           </div>
           <div class="spaced">
              <b>Groups:</b>
              <perfect-scrollbar flex style="max-height: 200px;" [config]="this.config">
                 <ng-container *ngFor="let group of identityDetails?.memberOf; let i = index">
                    <mat-label>
                       {{group}}
                    </mat-label>
                 </ng-container>
              </perfect-scrollbar>
           </div>
        </mat-card>
     </div>
  </div>
  <mat-divider></mat-divider>
  <div align="end">
     <button  mat-button (click)="onSave()" *ngIf="edittingDetails">SAVE</button>
     <div fxFlex></div>
     <button  mat-button *ngIf= "formUserData?.emailAddress !== this.adminEmail" (click)="onDelete()">DELETE</button>
     <button  mat-button (click)="onEdit()">EDIT</button>
     <button  mat-button (click)="this.closeUserDetails.emit()">CLOSE</button>
  </div>
</div>
  `,
  styleUrls: ['./user-detail.component.scss']
})
export class UserDetailComponent {

  public config: PerfectScrollbarConfigInterface = {};
  @ViewChild('licenceSelect') licenceSelect: MatSelect;

  @Input() canModify: boolean;
  @Input() provider: Provider;
  @Input()
  set userDetails(user: UserDetailData) {
    if (user) {
      this.originalUserData = user;
      this.formUserData = Object.assign({}, user);
      this.initialRole = user.roles[0];
      this.selectedRole = user.roles[0];
      this.assignedRoles = this.getRoleLabel(user.roles);
      this.edittingDetails = false;
    }
  }
  @Input() identityDetails: UserActiveDirectoryIdentityDetailData;
  @Input() roles: Role[];
  @Input() userGroups: UserGroup[];
  @Input() adminEmail: string;
  @Input() allLicenceGroups: LicenceGroup[];
  @Input()
  set licenceGroups(licenceGroups: LicenceGroup[]) {
    this.formLicenceGroups = licenceGroups;
    this.originalLicenceGroups = licenceGroups;
  }


  @Output() closeUserDetails = new EventEmitter();
  @Output() showUserGroupDetails = new EventEmitter();
  @Output() showLicenceGroupDetails = new EventEmitter();
  @Output() showGroupDetails = new EventEmitter();
  @Output() deleteUserDetails = new EventEmitter();
  @Output() editUserDetails = new EventEmitter();
  @Output() addLicenceGroupToUser = new EventEmitter();
  @Output() changePassword = new EventEmitter();

  edittingDetails = false;
  canEditCode = false;
  initialRole: Role;
  selectedRole: Role;
  assignedRoles: string;
  originalUserData: UserDetailData;
  formUserData: UserDetailData;
  formLicenceGroups: LicenceGroup[];
  originalLicenceGroups: LicenceGroup[];
  selectedLicences: LicenceGroup[];


  constructor(private dialog: MatDialog) { }

  private toggleEnabled() {
    this.edittingDetails = !this.edittingDetails;
    if (!this.edittingDetails) {
      this.formLicenceGroups = this.originalLicenceGroups;
    }
  }

  openLicenceSelector() {
    this.licenceSelect.open();
  }

  licenceChooserOpenChange(opened: boolean) {
    if (!opened) {
      this.addLicenceGroup();
    }
  }

  isAdUser() {
    if (this.provider) {
      return this.provider.type === ProviderType.ActiveDirectory;
    }
    return false;
  }

  onChangePassword() {
    this.changePassword.emit(this.formUserData);
  }

  unAssignedGroups(): LicenceGroup[] {
    return this.allLicenceGroups.filter((group) =>
      this.formLicenceGroups.every((licenceGroup) => licenceGroup.id !== group.id));

  }

  getRoleLabel(roles: Role[]): string {
    return Object.keys(roles).map(function (k) { return roles[k].description; }).join(', ');
  }

  onEdit() {
    if (this.provider.type === ProviderType.ActiveDirectory) {
      this.dialog.open(ConfirmationDialogComponent, {
        width: '400',
        data: {
          title: 'Editing AD Users',
          content:
            'Users from AD cannot be individually modified. Open up the associated User Group to change their Role or Pool Assignments',
          confirmText: 'Ok'
        }
      });
    } else {
      this.toggleEnabled();
    }
  }

  removeGroup(group: LicenceGroup) {
    this.formLicenceGroups = this.formLicenceGroups.filter(g => g.id !== group.id);
  }

  addLicenceGroup() {
    if (this.selectedLicences != null && this.selectedLicences.length > 0) {
      this.formLicenceGroups = this.formLicenceGroups.concat(this.selectedLicences);
      this.selectedLicences = [];
    }
  }

  onSave() {
    let changes = false;
    if (this.formLicenceGroups.length !== this.originalLicenceGroups.length) {
      this.formUserData.licenceGroups = this.formLicenceGroups;
      changes = true;
    }
    if (this.selectedRole.id !== this.initialRole.id) {
      this.formUserData.roles = [this.selectedRole];
      changes = true;
    }
    if (changes) {
      this.editUserDetails.emit(this.formUserData);
    }
    this.edittingDetails = !this.edittingDetails;
  }


  onDelete() {
    let dialogTitle = 'Delete User';
    let message = 'Are you sure you want to delete ' + this.formUserData.name + ' from QTS?';
    if (this.provider.type === ProviderType.ActiveDirectory) {
      dialogTitle = 'Delete Active Directory User';
      message = 'Deleting an Active Directory user from QTS will remove them from the system,' +
        'but they will be automatically readded on system syncing until they ' +
        'are removed in Active Directory from the User Groups they are associated with.';
    }
    const confirmDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      data: {
        title: dialogTitle,
        content: message,
        confirmText: 'Delete',
        rejectText: 'Cancel'
      }
    });

    confirmDialogRef.componentInstance.confirmation.subscribe(_ => {
      this.deleteUserDetails.emit(this.formUserData);
      confirmDialogRef.close();
    });
  }

  getPath(sessions: Session[]): string { // TODO: change this to be cleaner, bit of a hack to get working
    if (sessions && sessions.find(session => session.expiry !== null)) {
      return '/assets/images/GreenDot.png';
    } else {
      return '/assets/images/RedDot.png';
    }
  }

}
