import { Injectable } from '@angular/core';
import { MatDrawer } from '@angular/material';
@Injectable({
  providedIn: 'root'
})
/**
 * Responsible for opening and closing the drawers found on the Admin Dashboard.
 */
export class DrawerService {

  // TODO: duplicate code in here we should make one....track who is currently open and handle accordingly

  public _userDetailDrawerVisible: boolean;
  public _userGroupDetailDrawerVisible: boolean;
  public _activeLicenceDetailDrawerVisible: boolean;
  public _licenceGroupDetailDrawerVisible: boolean;

  private _drawer: MatDrawer;


  constructor() { }
  set drawer(drawer: MatDrawer) {
    this._drawer = drawer;
  }

  public closeUserDrawer() {
    this._drawer.close();
    this._userDetailDrawerVisible = false;
  }

  public openUserDrawer() {
    this.closeOpenDrawers();
    this._drawer.open();

    this._userDetailDrawerVisible = true;
  }

  public isUserDrawerOpen(): boolean {
    return this._drawer.opened && this._userDetailDrawerVisible;
  }

  public closeUserGroupDrawer() {
    this._drawer.close();
    this._userGroupDetailDrawerVisible = false;
  }

  public openUserGroupDrawer() {
    this.closeOpenDrawers();
    this._drawer.open();
    this._userGroupDetailDrawerVisible = true;
  }


  public isUserGroupDrawerOpen(): boolean {
    return this._drawer.opened && this._userGroupDetailDrawerVisible;
  }


  public closeActiveLicenceDrawer() {
    this._drawer.close();
    this._activeLicenceDetailDrawerVisible = false;
  }

  public openActiveLicenceDrawer() {
    this.closeOpenDrawers();
    this._drawer.open();
    this._activeLicenceDetailDrawerVisible = true;
  }

  public isDrawerActiveLicenceDrawerOpen(): boolean {
    return this._drawer.opened && this._activeLicenceDetailDrawerVisible;
  }


  public closeLicenceGroupDrawer() {
    this._drawer.close();
    this._licenceGroupDetailDrawerVisible = false;
  }

  public openLicenceGroupDrawer() {
    this.closeOpenDrawers();
    this._drawer.open();
    this._licenceGroupDetailDrawerVisible = true;
  }

  public isLicenceGroupDrawerOpen(): boolean {
    return this._drawer.opened && this._licenceGroupDetailDrawerVisible;
  }

  closeOpenDrawers() {
    this.closeUserDrawer();
    this.closeUserGroupDrawer();
    this.closeActiveLicenceDrawer();
    this.closeLicenceGroupDrawer();
  }

}

