import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material';

import { UserDetailData } from 'src/app/models/user-detail-data.model';
import { ConfirmationDialogComponent } from 'src/app/modules/feature/common/confirmation-dialog/confirmation-dialog.component';
import { Role } from 'src/app/models/role.model';

@Component({
  selector: 'app-user-licence-detail',
  template: `
  <div class ="container" flex>
    <h1 *ngIf="userDetails && userDetails?.statusImagePath">
      <img class ="status" height="14" width="auto" [src]="userDetails?.statusImagePath" />
      {{' '+userDetails?.name}}
    </h1>
  <div class ='user-detail'>
  <div class="spaced">
    <b>Email:</b>&nbsp;{{userDetails?.emailAddress}}
  </div>
  <div class="spaced">
    <b>Roles:</b>&nbsp;{{this.getRoleLabel(userDetails?.roles)}}
  </div>
  <div class="spaced">
    <b>Last Active:</b>&nbsp;{{userDetails?.lastActive | date:'LLL dd, yyyy h:mm:ss aa (z)'}}
  </div>
  <div class="spaced">
    <b>Assigned License Expiry Date:</b>&nbsp;{{userDetails?.tokenExpiry | date:'LLL dd, yyyy h:mm:ss aa (z)' }}
  </div>
  <div class="spaced">
    <b>Assigned License Pool:</b>&nbsp;{{userDetails?.assignedPool}}
  </div>
 <br />
  <mat-divider></mat-divider>
  <div align="end">
      <button  mat-button (click)="remove()">REMOVE ACTIVE SESSION</button>
      <button  mat-button (click)="this.closeActiveSessionDetails.emit()">CLOSE</button>
  </div>
</div>
</div>
  `,
  styleUrls: ['./active-user-licence-detail.component.scss']
})
export class ActiveUserLicenceDetailComponent {

  @Input() userDetails: UserDetailData;

  @Output() closeActiveSessionDetails = new EventEmitter();
  @Output() showGroupDetails = new EventEmitter();
  @Output() removeActiveLicence = new EventEmitter();
  @Output() editUserDetails = new EventEmitter();

  canEditCode = false;

  constructor(private dialog: MatDialog) { }

  getRoleLabel(roles: Role[]): string {
    if (roles) {
      return Object.keys(roles).map(function (k) { return roles[k].description; }).join(', ');
    }
    return null;
  }

  remove() {
    const confirmDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: 'auto',
      data: {
        title: 'Remove License For Active Session',
        content: 'Are you sure you want to remove ' + this.userDetails.name + '\'s license assignment?',
        confirmText: 'Remove',
        rejectText: 'Cancel'
      }
    });

    confirmDialogRef.componentInstance.confirmation
      .subscribe(_ => {
        this.removeActiveLicence.emit(this.userDetails);
        confirmDialogRef.close();
      });
  }

  groupClick() {
    this.showGroupDetails.emit(this.userDetails.licenceGroups);
  }
}
