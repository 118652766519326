import { Injectable } from '@angular/core';
import { PreloadingStrategy, Route } from '@angular/router';
import { Observable, of, timer } from 'rxjs';
import { flatMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

/**
 * Preloading strategy for the application.  Takes in whether the given route should be preloaded for better
 * user experience or if further that preloading should be delayed.
 */
export class AppPreloadingStrategy implements PreloadingStrategy {
  preload(route: Route, load: Function): Observable<any> {
    const loadRoute = (delay) => delay ? timer(150).pipe(flatMap(_ => load())) : load();
    return route.data && route.data.preload ? loadRoute(route.data.delay) : of(null);
  }
}
