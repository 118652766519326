import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar } from '@angular/material'; // TODO: get from custom material module

@Injectable({
  providedIn: 'root'
})

export class CustomSnackbarService {
  constructor(private snackBar: MatSnackBar, private zone: NgZone) { }
  // TODO:
  // add error snackbars
  // add warning snackbars
  // add snackbar with custom icon

 openSnackBar(errorText: string, config: {}) {
    this.zone.run(() => {
      const snackBar = this.snackBar.open(errorText, 'Close', config);
      snackBar.onAction()
      .subscribe(_ => {
        snackBar.dismiss();
      });
    });
  }

}



