import { HttpClient, HttpParams, HttpHeaders} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FileInput } from 'ngx-material-file-input';
import { EMPTY, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LicenceGroup } from 'src/app/models/licence-group.model';
import { Licence } from 'src/app/models/licence.model';
import { AuthorizationService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class LicenceService {

  public seatCount = 0;

  constructor(private http: HttpClient, private authService: AuthorizationService) { }

  initialize(): Observable<Licence> {
    return this.getCurrentLicence();
  }

  getCurrentLicence(): Observable<Licence> {
    return this.http.get<Licence>('/licence/')
      .pipe(map(licence => {
        this.seatCount = licence.totalSeats;
        return licence;
      }));
  }

  getOfflineActivationRequestFile(userKey: String): Observable<Blob> {
    if (userKey) {
      const  headers = new HttpHeaders();
      headers.set('Content-Type', 'application/x-www-form-urlencoded');
      return this.http.post<Blob>('/licence/offline-activation-request-file',
      userKey, {headers: headers,  responseType: 'blob' as 'json' });
    }
    return EMPTY;
  }

  activateWithLicenceFile(activationFile: FileInput): Observable<any> {
    if (activationFile) {
      const input = new FormData();
      input.append('activationFile', activationFile.files[0], activationFile.files[0].name);
      return this.http.post<any>('/licence/activate', input);
    }
    return EMPTY;
  }

  isLicenceNeeded(): Observable<boolean> {
    return this.http.get<boolean>('/licence/isLicenceNeeded');
  }

  getLicenceGroups(): Observable<LicenceGroup[]> {
    if (this.authService.isLoggedIn()) {
      return this.http.get<LicenceGroup[]>('/licencegroups');
    } else {
      return EMPTY;
    }
  }

  updateLicenceGroups(groups: LicenceGroup[]): Observable<LicenceGroup[]> {
    return this.http.put<LicenceGroup[]>('/licencegroups', groups);
  }

  addLicenceGroup(group: LicenceGroup): Observable<any> {
    return this.http.post<any>('/licencegroups', group);
  }

  deleteLicenceGroup(licenceGroup: LicenceGroup): Observable<any> {
    if (licenceGroup.id) {
      const httpParams = new HttpParams().set('id', licenceGroup.id.toString());
      const options = { params: httpParams };
      return this.http.delete<any>('/licencegroups', options);
    }
    return EMPTY;
  }

}
