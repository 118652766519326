import { Component, Output, EventEmitter, Input, ViewChild, OnInit } from '@angular/core';
import { MatDialog, MatSelect } from '@angular/material';

import { LicenceGroup } from 'src/app/models/licence-group.model';
import { ConfirmationDialogComponent } from 'src/app/modules/feature/common/confirmation-dialog/confirmation-dialog.component';

import { UserDetailData } from 'src/app/models/user-detail-data.model';
import { UserGroup } from 'src/app/models/user-group.model';
import { PerfectScrollbarComponent, PerfectScrollbarConfigInterface, PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { FormGroup } from '@angular/forms';
import { Role } from 'src/app/models/role.model';
import { Session } from 'src/app/models/session.model';

@Component({
  selector: 'app-group-detail',
  template: `
  <div class="panel" fxLayout="column">
   <div class ="container"  fxLayout="column">
      <div class="spaced">
         <h1>
            {{this.userGroup?.name}}
         </h1>
      </div>
      <div class="spaced">
         <b>Role:</b>
         <ng-container *ngIf="this.edittingDetails; else roleLabel">
            <mat-form-field>
               <mat-select [(ngModel)]="selectedRole">
               <mat-option
               *ngFor="let role of this.getAvailableRoles()"
               [value]="role">
               {{role.description}}
               </mat-option>
               </mat-select>
            </mat-form-field>
         </ng-container>
         <ng-template #roleLabel>
            {{this.selectedRole?.description}}
         </ng-template>
      </div>
      <div class="spaced"  fxLayout="column" fxLayoutAlign="stretch" fxFlex="40% 50% 40%">
         <b>License Pools:</b>
         &nbsp;
         <perfect-scrollbar flex fxFlex="auto" [config]="this.config">
            <ng-container *ngIf="this.formLicenceGroups && this.formLicenceGroups?.length>0; else no_groups">
               <ng-container
                  *ngFor="let licenceGroup of this.formLicenceGroups; index as i;">
                  <div>
                     <button mat-flat-button [autofocus]="false" (click)="this.showLicenceGroupDetails.emit(licenceGroup)">
                     {{licenceGroup.name}}
                     </button>
                     <button class="icon"
                     mat-icon-button
                     *ngIf="this.edittingDetails"
                     (click)="this.removeGroup(licenceGroup)">
                     <mat-icon>remove</mat-icon>
                     </button>
                  </div>
               </ng-container>
            </ng-container>
            <ng-template #no_groups>
               <div class="spaced">
                  (No License Pools Associated with this User Group)
               </div>
            </ng-template>
         </perfect-scrollbar>
         <div *ngIf="this.edittingDetails && this.unAssignedGroups().length>0">
            <mat-form-field>
               <mat-select
               [(ngModel)]="selectedLicences"
               (openedChange)="licenceChooserOpenChange($event)"
               #licenceSelect
               multiple>
               <mat-option
               *ngFor="let licenceGroup of this.unAssignedGroups()"
               [value]="licenceGroup">
               {{licenceGroup.name}}
               </mat-option>
               </mat-select>
            </mat-form-field>
            <button class="icon"
               mat-icon-button
               (click)="openLicenceSelector()">
               <mat-icon>add</mat-icon>
            </button>
         </div>
      </div>
      <div class="spaced"  fxLayout="column" fxLayoutAlign="stretch" fxFlex="60% 50% 40%">
         <b>Members:</b>
         &nbsp;
         <perfect-scrollbar flex fxFlex="auto" [config]="this.config">
            <ng-container *ngIf="this.users?.length;else no_members">
               <ng-container *ngFor="let user of this.users; let i = index">
                  <div>
                     <button mat-flat-button [autofocus]="false" (click)="this.showUserDetails.emit(user)">
                     <img class ="status" height="10" width="auto" [src]='this.getPath(user.activeSessions)' />
                     {{user.name}}
                     </button>
                  </div>
               </ng-container>
            </ng-container>
            <ng-template #no_members>
               <div class="spaced">
                  (No users from this group are synced with QTS.)
               </div>
            </ng-template>
         </perfect-scrollbar>
      </div>
   </div>
   <br />
   <br>
   <mat-divider></mat-divider>
    <div align="end">
      <button  mat-button (click)="onSave()" *ngIf="edittingDetails">SAVE</button>
      <div fxFlex></div>
      <button  mat-button matTooltip="Sync users from AD.  Note: group users not manually synced will
         be automatically synced as they access the system." (click)="this.sync.emit(this.userGroup)">SYNC</button>
      <button  mat-button (click)="onDelete()">DELETE</button>
      <button  mat-button (click)="toggleEnabled()">EDIT</button>
      <button  mat-button (click)="this.closeGroupDetails.emit()">CLOSE</button>
   </div>
</div>
  `,
  styleUrls: ['./user-group-detail.component.scss']
})

export class GroupDetailComponent implements OnInit {

  public config: PerfectScrollbarConfigInterface = {};
  @ViewChild('licenceSelect') licenceSelect: MatSelect;
  @ViewChild(PerfectScrollbarComponent) componentRef?: PerfectScrollbarComponent;
  @ViewChild(PerfectScrollbarDirective) directiveRef?: PerfectScrollbarDirective;

  @Input()
  set groupDetails(userGroup: UserGroup) {
    if (userGroup) {
      this.userGroup = userGroup;
      this.initialRole = userGroup.roles[0];
      this.selectedRole = userGroup.roles[0];
    }
  }
  @Input() roles: Role[];
  @Input() allLicenceGroups: LicenceGroup[];
  @Input()
  set licenceGroups(liceneGroups: LicenceGroup[]) {
    this.formLicenceGroups = liceneGroups;
    this.originalLicenceGroups = liceneGroups;
  }

  @Input() users: UserDetailData[];

  @Output() closeGroupDetails = new EventEmitter();
  @Output() deleteGroupDetails = new EventEmitter();
  @Output() showUserDetails = new EventEmitter();
  @Output() showLicenceGroupDetails = new EventEmitter();
  @Output() sync = new EventEmitter();
  @Output() editUserGroup = new EventEmitter();

  edittingDetails = false;
  detailsFormGroup: FormGroup;
  userGroup: UserGroup;
  formLicenceGroups: LicenceGroup[];
  originalLicenceGroups: LicenceGroup[];
  formUsers: UserDetailData[];
  originalUsers: UserDetailData[];
  selectedRole: Role;
  initialRole: Role;
  selectedLicences: LicenceGroup[];

  constructor(
    private dialog: MatDialog) { }

  ngOnInit(): void {
  }

  openLicenceSelector() {
    this.licenceSelect.open();
  }

  licenceChooserOpenChange(opened: boolean) {
    if (!opened) {
      this.addLicenceGroup();
    }
  }

  getAvailableRoles(): Role[] {
    if (this.roles) {
      return this.roles.filter(r => r.name !== 'tenantadmin');
    }
    return this.roles;
  }

  unAssignedGroups(): LicenceGroup[] {
    return this.allLicenceGroups.filter((group) =>
      this.formLicenceGroups.every((licenceGroup) => licenceGroup != null && licenceGroup.id !== group.id));
  }

  addLicenceGroup() {
    if (this.selectedLicences != null && this.selectedLicences.length > 0) {
      this.formLicenceGroups = this.formLicenceGroups.concat(this.selectedLicences);
      this.selectedLicences = [];
    }
  }

  removeGroup(group: LicenceGroup) {
    this.formLicenceGroups = this.formLicenceGroups.filter(g => g.id !== group.id);
  }

  toggleEnabled() {
    this.edittingDetails = !this.edittingDetails;
    if (!this.edittingDetails) {
      this.selectedRole = this.initialRole;
      this.formLicenceGroups = this.originalLicenceGroups;
      this.selectedRole = this.initialRole;
    }
  }

  onSave() {
    const group: UserGroup = Object.assign({}, this.userGroup);
    if (this.formLicenceGroups.length !== this.originalLicenceGroups.length) {
      group.licenceGroups = this.formLicenceGroups;
    }
    if (this.selectedRole.id !== this.initialRole.id) {
      group.roles = [this.selectedRole];
    }
    this.editUserGroup.emit(group);
    this.edittingDetails = !this.edittingDetails;
  }

  onDelete() {
    const confirmDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: 'auto',
      data: {
        title: 'Delete Group ',
        content: 'Are you sure you want to delete ' + this.userGroup.name + ' from QTS?',
        confirmText: 'Delete',
        rejectText: 'Cancel'
      }
    });

    confirmDialogRef.componentInstance.confirmation.subscribe(_ => {
      this.deleteGroupDetails.emit(this.userGroup);
      confirmDialogRef.close();
    });
  }


  userClick(userDetails: UserDetailData) {
    this.showUserDetails.emit(userDetails);
  }

  getPath(sessions: Session[]): string { // TODO: change this to be cleaner, bit of a hack to get working
    if (sessions && sessions.find(session => session.expiry !== null)) {
      return '/assets/images/GreenDot.png';
    } else {
      return '/assets/images/RedDot.png';
    }
  }
}
