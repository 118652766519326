import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatTableDataSource, MatDialog } from '@angular/material';
import { Provider } from 'src/app/models/provider.model';
import { UserGroup } from 'src/app/models/user-group.model';

@Component({
  selector: 'app-identity-provider-group-management-card',
  template: `
  <div *ngxPermissionsOnly="['groups:*']" class="mat-elevation-z2">
  <mat-card class="user-management-card">
    <mat-card-header class="user-management-header">
      <mat-card-title>
        Groups
        <div fxFlex></div>
        <button  mat-button  matTooltip="Click here to Add user"  (click)="this.addUser.emit(currentProvider?.id)">ADD USER GROUP</button>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content class="user-group-card-content">
    <mat-divider></mat-divider>
    <app-user-group-table
          [displayedColumns]='this.groupDisplayedColumns'
          [userGroupDataSource]='this.groupDataSource'
          (showGroupDetails)='this.showGroupDetails.emit($event)'>
    </app-user-group-table>
  </mat-card-content>
  </mat-card>
  </div>
  `,
  styleUrls: ['./identity-provider-group-card.component.scss']
})
export class IdentityProviderGroupCardComponent {
  @Input() currentProvider: Provider;
  @Input() directories: Provider[];
  @Input() groupDisplayedColumns: string[];
  @Input()
  set userGroups(groups: UserGroup[]) {
    this.groupDataSource.data = groups;
  }

  @Output() showGroupDetails = new EventEmitter();
  @Output() addUser = new EventEmitter();
  @Output() searchAd = new EventEmitter();
  @Output() deleteDirectory = new EventEmitter();
  @Output() goToProviderDetails = new EventEmitter();

  groupDataSource = new MatTableDataSource<UserGroup>();

  constructor(public dialog: MatDialog) { }


}
