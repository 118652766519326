import { Component, Inject, Output, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
// TODO: make me a module common module
export interface ConfirmationDialogData {
  title: string;
  content: string;
  confirmText: string;
  rejectText: string;
}

@Component({
  selector: 'app-confirmation-dialog',
  styleUrls: ['./confirmation-dialog.component.scss'],
  template: `
  <h1 mat-dialog-title>{{data.title}}</h1>
  <div mat-dialog-content>
    {{data.content}}
  </div>
  <div mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>{{data.rejectText}}</button>
    <button mat-button (click)="onOkClick()" cdkFocusInitial>{{data.confirmText}}</button>
  </div>
  `
})

export class ConfirmationDialogComponent {

  @Output() confirmation = new EventEmitter();

  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogData) { }

  onOkClick(): void {
   this.confirmation.emit();
   this.dialogRef.close();
  }

}
