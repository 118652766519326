import { Component, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FileInput } from 'ngx-material-file-input';

const DAT_EXTENSION = '.dat';


@Component({
  selector: 'app-licence-entry',
  template: `
   <div style="text-align: center; padding-bottom: 25px;">
   <mat-button-toggle-group #group="matButtonToggleGroup" [value]='InitSelectedButton'>
   <mat-button-toggle value='REQUEST LICENSE'>{{REQUEST_LICENCE}}</mat-button-toggle>
   <mat-button-toggle value='ACTIVATE LICENSE'>{{ACTIVATE_LICENCE}}</mat-button-toggle>
   </mat-button-toggle-group>
   </div>
    <ng-container *ngIf="group.value === REQUEST_LICENCE">
      <form class='form' [formGroup]="generateForm">
        <div class="right" fxLayout="row wrap" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
          <mat-form-field fxFlex>
            <input matInput type="text"
            placeholder="Please input the license key that your QRA representative provided."
            formControlName="licenceKey" required>
            <mat-error *ngIf="licenceForm.get('licenceKey')?.errors?.required">
                There is a problem with the key provided. Please check you input.
            </mat-error>
         </mat-form-field>
         <button mat-button [disabled]="generateForm.invalid" (click)="generateOfflineRequest()">GENERATE REQUEST</button>
       </div>
      </form>
    </ng-container>
    <ng-container *ngIf="group.value === ACTIVATE_LICENCE">
      <form class='form' [formGroup]="licenceForm">
        <div class="right" fxLayout="row wrap" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
          <mat-form-field fxFlex>
            <ngx-mat-file-input
              formControlName="licenceFile"
              fileLimit="1"
              accept= PROPERTIES_EXTENSION
              placeholder="Please upload the provided License File (.dat)."
              required>
            </ngx-mat-file-input>
            <mat-icon style="float: right;">attach_file</mat-icon>
            <mat-error *ngIf="licenceForm.get('licenceFile')?.errors?.invalidFile">
            Invalid license file
            </mat-error>
            <mat-error *ngIf="licenceForm.get('licenceFile')?.errors?.required">
            License file is required
            </mat-error>
          </mat-form-field>
          <button mat-button [disabled]="licenceForm.invalid" (click)="activateLicence()">ACTIVATE</button>
        </div>
       </form>
    </ng-container>

  `,
  styleUrls: ['./licence-entry.component.scss']
})
export class LicenceEntryComponent {
  readonly  REQUEST_LICENCE = 'REQUEST LICENSE';
  readonly  ACTIVATE_LICENCE = 'ACTIVATE LICENSE';

  @Input() InitSelectedButton: String;

  @Output() loginAttempt = new EventEmitter();
  @Output() activate = new EventEmitter();
  @Output() generateRequest = new EventEmitter();


  shown: String;
  licenceForm: FormGroup;
  generateForm: FormGroup;

  constructor(private formBuilder: FormBuilder) {
    this.licenceForm = this.formBuilder.group({
      licenceFile: ['', [Validators.required]]
    }, {
        validator: [
          this.isValidLicenceInput('licenceFile')
        ]
      });

      this.generateForm = this.formBuilder.group({
        licenceKey: ['', [Validators.required]],
      });
  }

  generateOfflineRequest() {
    const licenceKey = this.generateForm.get('licenceKey').value;
    this.generateRequest.emit(licenceKey);
  }

  activateLicence() {
    const licenceFile = this.licenceForm.get('licenceFile').value;
    this.activate.emit(licenceFile);
  }


  private isValidLicenceInput(licenceFile: string) {
    return (group: FormGroup) => {
      if (this.licenceForm) {
        const licenceInput = this.licenceForm.get(licenceFile);
        const file: FileInput = licenceInput.value;
        if (file) {
          const validFileExtension = file.fileNames.endsWith(DAT_EXTENSION);
          if (!validFileExtension) {
            return licenceInput.setErrors({ invalidFile: true });
          } else {
            return licenceInput.setErrors(null);
          }
        }
      }
    };
  }

}
