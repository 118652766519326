import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthorizationService } from '../data/request/auth.service';
import { Router } from '@angular/router';
import { CustomSnackbarService } from './custom-snackbar.service';

@Injectable({
  providedIn: 'root'
})

/**
 * Handles HTTP Error Responses
 */
export class HttpErrorHandlerService {

  constructor(private snackbarService: CustomSnackbarService, private authService: AuthorizationService, private router: Router) { }

  /**
   * Handles Error resonses
   */
  public handleError(httpError: HttpErrorResponse) {
    switch (httpError.status) {
      case 0: {
        this.invalidateUser(null, 'Unable to contact server', 6000);
        break;
      }
      case 400: {
        this.showCustomError(httpError, 'There was an issue with the inputs provided', 6000);
        break;
      }
      case 403: {
        this.invalidateUser(null, 'Invalid access token. Login to receive a new token.', 6000);
        break;
      }
      case 401: {
        this.invalidateUser(httpError, 'Failed to Authenticate User', 6000);
        break;
      }
      case 404: {
        // don't want to spam client telling them can't find (prob ad connection down)
        console.error(httpError);
        break;
      }
      case 500: {
        this.showCustomError(null,
          'There was an issue with your request. Please check your inputs and the server logs for details.', 6000);
        break;
      }
      default: {
        this.showCustomError(httpError, 'There was an issue.  Please see console logs for details', 6000);
        break;
      }
    }
  }

  /** For these errors the user should be logged out */
  invalidateUser(httpError: HttpErrorResponse, defaultMessage: string, duration: number): any {
    this.showCustomError(httpError, defaultMessage, duration);
    this.authService.invalidateSession();
    this.router.navigate(['/login']);
  }

  /** Error displayed is provided */
  showFixedError(httpError: HttpErrorResponse, messageToShow: string, duration: number) {
    this.snackbarService.openSnackBar(messageToShow, { verticalPosition: 'bottom', horizontalPosition: 'center' });
  }

  /** Error displayed is determined by response or falls back to one provided */
  showCustomError(httpError: HttpErrorResponse, defaultMessage: string, duration: number) {
    let displayMessage = defaultMessage ? defaultMessage : 'An error has occured';
    // To make life easy, for blob we just let the default messaging occur.
    if (httpError && httpError.error && !(httpError.error instanceof Blob)) {
        if (httpError.error.message) {
          displayMessage = httpError.error.message;
        } else if (httpError.error.error) {
          displayMessage = httpError.error.error;
        } else if (httpError.error.errors) {
          displayMessage = httpError.error.errors;
        } else if (httpError.error) {
          displayMessage = httpError.error;
        }
    }
    if (duration > 0) {
      this.snackbarService.openSnackBar(
        displayMessage,
        { verticalPosition: 'bottom', horizontalPosition: 'center', duration: 6000 });
    } else {
      this.snackbarService.openSnackBar(displayMessage, { verticalPosition: 'bottom', horizontalPosition: 'center' });
    }
  }
}
