import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthorizationService } from '../../../../../core/services/data/request/auth.service'; // TODO: check into alias'
import { User } from 'src/app/models/user.model';
import { Router, RouterOutlet } from '@angular/router';
import { UserService } from 'src/app/core/services/data/request/user.service';
import { Role } from 'src/app/models/role.model';
import { routerAnimation } from '../../../common/animations';
import { PerfectScrollbarConfigInterface, PerfectScrollbarComponent, PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { MatDrawer } from '@angular/material';
import { DrawerService } from 'src/app/core/services/helper/drawer.service';

@Component({
  selector: 'app-dashboard-view',
  animations: [routerAnimation()],
  template: `
  <div class="app-background">
    <mat-drawer-container #drawercontainer class="drawer-container" flex hasBackdrop="true">
      <mat-drawer #drawer mode="over" position="end">
          <app-admin-drawer #drawerContents></app-admin-drawer>
      </mat-drawer>
      <mat-drawer-content>
          <app-toolbar [userName]='this.userName' [userRole]='this.getDisplayRole()'
            [toolbarTitle]='this.getTitleFromRole()' (logOut)='onLogOut()' (passwordChange)='onPasswordChange($event)'>
          </app-toolbar>
          {{this.userRole}}
          <div class="content" [@routerAnimation]="getRouteAnimation(router)">
              <router-outlet #router="outlet"></router-outlet>
          </div>
      </mat-drawer-content>
  </mat-drawer-container>
</div>
  `,
  styleUrls: ['./dashboard-view.container.scss']
})

export class DashboardViewComponent implements OnInit {

  public config: PerfectScrollbarConfigInterface = {};
  @ViewChild(PerfectScrollbarComponent) componentRef?: PerfectScrollbarComponent;
  @ViewChild(PerfectScrollbarDirective) directiveRef?: PerfectScrollbarDirective;
  @ViewChild('drawer') drawer: MatDrawer;

  toolbarTitle: string;
  userName: string;
  userRole: Role;

  constructor(private userService: UserService, private authService: AuthorizationService, private router: Router,
     private drawerService: DrawerService) { }

  ngOnInit() {
    this.drawerService.drawer = this.drawer;
    const user: User = this.authService.getAuthUser();
    if (user && user.roles) {
      this.userName = user.emailAddress;
      if (user.roles && user.roles[0]) {
        this.userRole = user.roles[0];
        this.toolbarTitle = user.roles[0].description;
      }
    }
  }

  public getRouteAnimation(outlet: RouterOutlet) {
    const res =
      outlet.activatedRouteData.num === undefined
        ? -1
        : outlet.activatedRouteData.num;

    return res;
  }

  getTitleFromRole(): string {
    if (this.userRole) {
      switch (this.userRole.name) {
        case 'admin':
        case 'tenantadmin':
          {
            return 'Admin Settings';
          }
        default:
          {
            return 'User Settings';
          }
      }
    } else {
      return null;
    }
  }

  getDisplayRole(): string {
    if (this.userRole) {
      switch (this.userRole.name) {
        case 'admin':
        case 'tenantadmin':
          {
            return 'QTS Admin';
          }
        default:
          {
            return 'User';
          }
      }
    } else {
      return null;
    }
  }

  onLogOut() {
    this.authService.logOut();
    this.router.navigateByUrl('/login');
  }

  onPasswordChange(newPassword: string) {
    if (this.authService.isLoggedIn) {
      this.userService.resetPassword(newPassword);
    }
  }

}
