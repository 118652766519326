import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, EMPTY } from 'rxjs';

import { ApplicationEvent } from 'src/app/models/application-event.model';

import { AuthorizationService } from './auth.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MonitoringService {

  constructor(private http: HttpClient, private authService: AuthorizationService) { }

  archiveAppEvents(): Observable<any> {
    return this.http.delete('/monitoring/events');//this is obsolete now
  }

  download(type: 'events' | 'logs'| 'all'): Observable<any> {
    return this.http.get(`/monitoring/download?type=${type}`, { responseType: 'arraybuffer' });
  }

  getApplicationEventsLoggingPath(): Observable<any> {
    return this.http.get<{ path: string }>('/monitoring/loggingpath/events').pipe(
      map(response => response.path));
  }

  getSystemLoggingPath(): Observable<any> {
    return this.http.get<{ path: string }>('/monitoring/loggingpath/system').pipe(
      map(response => response.path));
  }

  getApplicationEvents(): Observable<ApplicationEvent[]> {
    if (this.authService.isLoggedIn()) {
      return this.http.get('/monitoring/events', { responseType: 'text' }).pipe(
        map((responseText) => {
          const events = JSON.parse(responseText);
          return events.map((event) => ({
            ...event,
            created: new Date(event.created), //date is messing things up for serialization...get it working then change its format...then the cache ... then other methods
          }));
        })
      );
    } else {
      return EMPTY;
    }
  }
}
