import { Injectable } from '@angular/core';
import { HttpClient /*, HttpParams*/ } from '@angular/common/http';
import { Observable, EMPTY } from 'rxjs';

import { ConfigurationSummary } from 'src/app/models/configuration-summary.model';
import { AuthorizationService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  constructor(private http: HttpClient, private authService: AuthorizationService) { }

  getAllConfigurationSummaries(): Observable<ConfigurationSummary[]> {
    if (this.authService.isLoggedIn()) {
      return this.http.get<ConfigurationSummary[]>('/analysis/configuration/summary/');
    } else {
      return EMPTY;
    }
  }

  uploadConfiguration(fileJson: string): any {
    return this.http.post<any>('/v1/qvsconfigurationservice/qvsconfigurations/', fileJson);  //TODO: take me out
  }
}
