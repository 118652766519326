import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from '../../../../material.module';
import { DashboardViewComponent } from './dashboard-view.container';
import { ToolbarComponent } from '../../toolbar/toolbar.component';
import { AdminDashboardModule } from '../../admin-dashboard/admin-dashboard.module';
import { LicenceGroupDetailComponent } from './components/admin-drawer/components/licence-group-detail/licence-group-detail.component';
import { UserDetailComponent } from './components/admin-drawer/components/user-detail/user-detail.component';
import { GroupDetailComponent } from './components/admin-drawer/components/user-group-detail/user-group-detail.component';
import { AdminDrawerContainerComponent } from './components/admin-drawer/admin-drawer.container';
import { PerfectScrollbarModule, PerfectScrollbarConfigInterface, PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PoolActiveUserTableComponent } from './components/admin-drawer/components/pool-active-user-table/pool-active-user-table.component';
import {
  ActiveUserLicenceDetailComponent
 } from './components/admin-drawer/components/active-user-licence-detail/active-user-licence-detail.component';
 import {
   ChangePasswordDialogComponent
 } from '../../toolbar/components/change-password-dialog/change-password-dialog.component'; // Need alias
 import {
   ChangeUserPasswordDialogComponent
  } from './components/admin-drawer/components/change-password-dialog/change-password-dialog.component';


const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  declarations: [
    DashboardViewComponent,
    ToolbarComponent,
    ChangePasswordDialogComponent,
    ChangeUserPasswordDialogComponent,
    LicenceGroupDetailComponent,
    ActiveUserLicenceDetailComponent,
    PoolActiveUserTableComponent,
    UserDetailComponent,
    AdminDrawerContainerComponent,
    GroupDetailComponent],
  imports: [
    CommonModule,
    RouterModule,
    BrowserModule,
    BrowserAnimationsModule,
    MaterialModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    AdminDashboardModule,
    PerfectScrollbarModule
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ],
  exports: [RouterModule],
  entryComponents: [ChangePasswordDialogComponent, ChangeUserPasswordDialogComponent]
})

export class DashboardViewModule { }
