import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NeedAuthGuard } from './core/guards/auth.guard';
import { AppPreloadingStrategy } from './core/strategies/app-preload.strategy';
import { DashboardViewComponent } from './modules/feature/dashboard/views/dashboard-view/dashboard-view.container';
import { LoginContainerComponent } from './modules/feature/login/login.container';
import { AdminDashboardComponent } from './modules/feature/dashboard/admin-dashboard/admin-dashboard.container';
import { IdentityProviderDetailsContainerComponent } from './modules/feature/dashboard/admin-dashboard/components/user-management/identity-provider-details.container';

export const ROUTES: Routes =
  [
    { path: '', redirectTo: 'login', pathMatch: 'full' },
    { path: 'login', component: LoginContainerComponent }, // eager load
    {
      path: 'dashboard', component: DashboardViewComponent,
      children: [
        { path: '', component: AdminDashboardComponent},
        { path: 'provider/:id', component: IdentityProviderDetailsContainerComponent}
      ],
      canActivate: [NeedAuthGuard]
    },
    { path: '**', redirectTo: 'login' }

    /* NOTE: if we do not want admin module everytime then switch to canLoad and remove strategy...
    will decrease payload and add a 'bit' of security*/
  ];

@NgModule({
  imports: [
    RouterModule.forRoot(ROUTES, { useHash: true })],
  exports: [RouterModule],
  providers: [AppPreloadingStrategy]
})

export class AppRoutingModule { }
