import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable,  of, EMPTY } from 'rxjs';
import { map } from 'rxjs/operators';

import { ActiveDirectorySearchType } from 'src/app/models/active-directory-search-type.enum';
import { ActiveDirectoryUser } from 'src/app/models/active-directory-user.model';
import { UserGroup } from 'src/app/models/user-group.model';
import { ProviderType } from 'src/app/models/provider-type.enum';
import { UserActiveDirectoryIdentityDetailData } from 'src/app/models/user-identity-detail.model';
import { Provider } from 'src/app/models/provider.model';

@Injectable({
  providedIn: 'root'
})

/**
 * Identity Service provides access to AD information.
 */
export class ActiveDirectoryService {

  constructor(private http: HttpClient) {}

  testProviderConnection(provider: Provider): Observable<any> {
    if (provider) {
      provider.type = ProviderType.ActiveDirectory;
      return this.http.post<any>('/activedirectory/connect', provider, { observe: 'response' });
    }
    return EMPTY;
  }

  connectToProvider(provider: Provider): Observable<boolean> {
    if (provider) {
      let connected = false;
      if (provider.type === ProviderType.Internal) {
        return of(true);
      } else {
        return this.http.post<any>('/activedirectory/connect', provider, { observe: 'response' })
        .pipe(map(response => {
          if (response instanceof HttpResponse) {
            connected = response.status === 200;
          }
          return connected;
        }));
      }
    }
    return EMPTY;
  }

  searchForGroups(directoryId: number, partialGroupName: string): Observable<string[]> {
    if (partialGroupName) {
      const params = new HttpParams()
        .set('partialGroupName', partialGroupName)
        .set('directoryId', directoryId.toString());
      return this.http.post<string[]>('/activedirectory/search/groups', params);
    }
    return EMPTY;
  }

  searchActiveDirectory(directoryId: number, adSearch: { searchValue: string, searchType: ActiveDirectorySearchType })
    : Observable<ActiveDirectoryUser[]> {
    if (adSearch) {
      const params = new HttpParams()
        .set(adSearch.searchType, adSearch.searchValue)
        .set('directoryId', directoryId.toString());
      return this.http.post<ActiveDirectoryUser[]>('/activedirectory/search', params);
    }
    return EMPTY;
  }


  getActiveDirectoyUserIdentityData(directoryID: number, userEmail: string): Observable<UserActiveDirectoryIdentityDetailData> {
    const params = new HttpParams()
      .set('adUser', userEmail)
      .set('directoryId', directoryID.toString());
    return this.http.get<UserActiveDirectoryIdentityDetailData>('/activedirectory/user', { params: params });
  }

  syncUserGroup(group: UserGroup): Observable<any> {
    return this.http.post<String>('/activedirectory/sync', group);
  }

}

