import { Component, Output, EventEmitter, Input } from '@angular/core';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { TokenConfigDialogComponent } from './token-config-dialog.component';

@Component({
  selector: 'app-token-config',
  template: `
  <mat-icon matTooltip="Click here to change session duration" (click)="showDialog()">
    <img src='/assets/images/SettingIcon.png' />
  </mat-icon>
  `,
  styleUrls: ['./token-config.component.scss']
})
export class TokenConfigComponent {
  expiry: number;
  dialogConfig = new MatDialogConfig();
  @Input()
  set currentExpiry(expiry: number) {
    this.expiry = expiry;
    this.dialogConfig.data = { currentExpiry: this.expiry };
    this.dialogConfig.minWidth = '300px';
  }
  @Output() updateExpiryTime = new EventEmitter();

  constructor(public dialog: MatDialog) { }

  showDialog() {
    const dialogRef = this.dialog.open(TokenConfigDialogComponent, this.dialogConfig);
    dialogRef.afterClosed().subscribe(tokenExpiryTime => {
      this.updateExpiryTime.emit(tokenExpiryTime);
    });
  }
}
