import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';


import { Role } from 'src/app/models/role.model';

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  constructor(private http: HttpClient) { }

  initialize(): Observable<Role[]> {
    return this.getRoles();
  }
  getRoles(): Observable<Role[]> {
    return this.http.get<Role[]>('/providers/roles');
  }
}
