import { Component, Output, EventEmitter, Input } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material';

import { ApplicationEvent } from 'src/app/models/application-event.model';

import { ConfirmationDialogComponent } from 'src/app/modules/feature/common/confirmation-dialog/confirmation-dialog.component';
import { ApplicationEventsDialogComponent } from './application-events-dialog.component';

@Component({
  selector: 'app-monitoring-card',
  styleUrls: ['./monitoring-card.component.scss'],
  template: `
  <div *ngxPermissionsOnly="['metrics:*', 'metrics:read']" class="mat-elevation-z2">
  <mat-card class="dashboard-card">
      <mat-card-header>
          <mat-card-title class="card-container-left">
              {{title}}
          </mat-card-title>
          <mat-card-title class="card-container-right">
              <button mat-icon-button class="more-button" [matMenuTriggerFor]="menu" aria-label="Toggle menu">
                  <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu" xPosition="before">
                  <button mat-menu-item (click)="downloadSystemLogsClicked()">Download System Logs</button>
                  <button mat-menu-item (click)="downloadAppEventsClicked()">Download Application Events</button>
                  <button *ngxPermissionsOnly="['metrics:*']" mat-menu-item (click)="openArchiveConfirmation()">Archive Application Events</button>
                  <button mat-menu-item (click)="downloadAllClicked()">Download All {{title}} Data</button>
              </mat-menu>
              <mat-divider></mat-divider>
          </mat-card-title>
          <br />
      </mat-card-header>
      <mat-card-content class="metrics-card-content">
          <!-- <mat-divider></mat-divider> -->
          <div class="metric-boxes" fxLayout="row" fxLayoutGap="10px">
            <div class="metric-box" id="system-metric-div">
                <h3>System Events</h3>
                <p><b>System Logging Path:</b>{{systemLoggingPath}}</p>
            </div>            
            <div (click)="openEventsList()" class="metric-box" id="events-metric-div">
              <h3>Application Events</h3>
              <div *ngIf="applicationEvents" class='event-link'>
                  <span>{{ applicationEvents[applicationEvents?.length - 1]?.name }}</span><br />
                  <span>{{ applicationEvents[applicationEvents?.length - 1]?.created | date:'LLL dd, yyyy h:mm aa (z)' }}</span>
                  <span>&nbsp;<img  src="/assets/images/LinkIcon.png"/></span>
                  <p><b>Application Events Logging Path: </b>{{appEventsLoggingPath}}</p>
              </div>
            </div>
          </div>
      </mat-card-content>
  </mat-card>
</div>
`
})
export class MonitoringCardComponent {
  title = 'Monitoring';
  private eventListDialog: MatDialogRef<ApplicationEventsDialogComponent>;

  constructor(public dialog: MatDialog) {

  }

  @Output() downloadSystemLogs = new EventEmitter();
  @Output() downloadAppEvents = new EventEmitter();
  @Output() archiveAppEvents = new EventEmitter();
  @Output() downloadAll = new EventEmitter();  

  @Input() systemLoggingPath: string;
  @Input() appEventsLoggingPath: string;
  private _applicationEvents: ApplicationEvent[] = [];

  @Input()
  set applicationEvents(applicationEvents: ApplicationEvent[]) {
    this._applicationEvents = applicationEvents;
    if (this.eventListDialog && this.eventListDialog.componentInstance) {
        this.eventListDialog.componentInstance.dataSource.data = applicationEvents;
    }
  }

  get applicationEvents(): ApplicationEvent[] {
    return this._applicationEvents;
  }

  openArchiveConfirmation(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.minWidth = '300px';
    dialogConfig.data = {
      title: 'Archive Application Events',
      content: 'Are you sure you want to archive your Application Event Data? This will reset your active Event log. This action can not be undone.',
      confirmText: 'Yes',
      rejectText: 'No'
    };
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);

    dialogRef.componentInstance.confirmation
    .subscribe(() => {
        this.archiveAppEvents.emit(true);
    });
  }

  openEventsList(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '80%';
    dialogConfig.minWidth = '300px';
    dialogConfig.height = '85vh';
    dialogConfig.data = this._applicationEvents;
    this.eventListDialog = this.dialog.open(ApplicationEventsDialogComponent, dialogConfig);
  }

  downloadAppEventsClicked() {
    this.downloadAppEvents.emit(true);
  }

  downloadSystemLogsClicked() {
    this.downloadSystemLogs.emit(true);
  }

  downloadAllClicked() {
    this.downloadAll.emit(true);
  }
}
