import { Role } from 'src/app/models/role.model';
import { UserDetailData } from 'src/app/models/user-detail-data.model';

export class RoleDisplay {

    /*
    * A user can have many roles.
    * Currently we display the role with the greater number of permissions.
    * This may change in teh future, but for now there is this helper method.
    */
    public getDisplayRole(user: UserDetailData): string {
        if (user.roles.length === 1) {
            return user.roles[0].description;
        }
        let setRole: Role = new Role();
        setRole.description = 'No Role assigned';
        setRole.permissions = [];
        user.roles.forEach(role => {
            if (role.permissions.length > setRole.permissions.length) {
                setRole = role;
            }
        });
        return setRole.description;

    }
}
