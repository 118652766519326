import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { FileInput } from 'ngx-material-file-input';

const JSON_EXTENSION = '.json';

@Component({
  selector: 'app-upload-coniguration-dialog',
  template: `
<h1 mat-dialog-title>Upload New Configuration</h1>
<div mat-dialog-content fxLayout="column">
  <form [formGroup]="updateForm">
    <div>
    <mat-form-field fxFlex class='search'>
      <ngx-mat-file-input
        formControlName="configFile"
        fileLimit="1"
        accept= JSON_EXTENSION
        placeholder="Configuration File"
        required>
      </ngx-mat-file-input>
      <mat-icon style="float: right;">attach_file</mat-icon>
      <mat-error *ngIf="updateForm.get('configFile')?.errors?.invalidFile">
         Invalid file type
      </mat-error>
      </mat-form-field>
  </div>
  </form>
</div>
<footer>
  <div mat-dialog-actions align="end">
    <button mat-button [disabled]="updateForm.invalid"  (click)="onSaveClick()">Save</button>
    <button mat-button (click)="onCloseClick()" cdkFocusInitial>Close</button>
  </div>
</footer>
`,
  styleUrls: ['./upload-configuration-dialog.component.scss']
})

export class UploadConfigurationComponent {
  updateForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<UploadConfigurationComponent>) {
    this.updateForm = this.formBuilder.group({
      configFile: ['', [Validators.required]]
    },
      {
        validator: [this.isValidFileType()]
      });
  }

  onSaveClick() {
    this.dialogRef.close(this.updateForm.value.configFile);
  }

  private isValidFileType() {
    return (group: FormGroup) => {
      if (this.updateForm) {
        const configInput = this.updateForm.get('configFile');
        const file: FileInput = configInput.value;
        if (file) {
          const validFileExtension = file.fileNames.endsWith(JSON_EXTENSION);
          if (!validFileExtension) {
            return configInput.setErrors({ invalidFile: true });
          } else {
            return configInput.setErrors(null);
          }
        }
      }
    };
  }

  onCloseClick(): void {
    this.dialogRef.close();
  }

}
