import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatTableDataSource, MatDialog, MatDialogConfig, MatTabGroup } from '@angular/material';

import { LicenceGroup } from 'src/app/models/licence-group.model';
import { User } from 'src/app/models/user.model';
import { UpdateLicenceDialogComponent } from './update-licence-dialog.component';
import { take } from 'rxjs/operators';
import { FileInput } from 'ngx-material-file-input';

@Component({
  selector: 'app-licence-management-card',
  template: `
  <div *ngxPermissionsOnly="['groups:*']" class="mat-elevation-z2">
  <mat-card class="licence-management-card">
  <mat-card-header class="user-header">
    <mat-card-title >
    License Management
      <div fxFlex></div>
      <button mat-button  (click)=" this.addExternalGroup.emit();">ADD LICENSE POOL</button>
      <button  mat-button  (click)="update()">UPDATE</button>
    </mat-card-title>
  </mat-card-header>
  <mat-divider></mat-divider>
  <br />
  <div class="card-container-left">
  <b>Total Licenses:</b>&nbsp;
  {{licenceSeats}}&nbsp;&nbsp;&nbsp;
  <b>License Expiry:</b>&nbsp;
  {{licenceExpiry}}
  <div fxFlex></div>
  <mat-form-field *ngIf='isFiltering' floatLabel='never'>
    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
    <img class ='search' matSuffix  src='/assets/images/MagnifyIcon.png' (click)="disableFiltering()">
  </mat-form-field>
 <img class ='search' *ngIf='!isFiltering'
  (click)="enableFiltering()"
  src='/assets/images/MagnifyIcon.png'>
  </div>
  <br />
  <mat-divider></mat-divider>
      <mat-tab-group #tabGroup>
   <mat-tab>
   <ng-template mat-tab-label >
   <div class= "stacked">
   <mat-icon svgIcon="qts-session"></mat-icon>
   <span>Active License Sessions</span>
   </div>
   </ng-template>
     <app-active-user-licence-table
       [displayedColumns]='this.userDisplayedColumns'
       [userDataSource]='this.userDataSource'
       [hasLicenceGroups]='this.hasLicenceGroups'
       (showUserDetails)='this.showUserDetails.emit($event)'
       (refresh)='this.refresh.emit()'>
     </app-active-user-licence-table>
   </mat-tab>
   <mat-tab>
   <ng-template mat-tab-label >
   <div class= "stacked">
   <mat-icon svgIcon="qts-pool"></mat-icon>
   <span>License Pools</span>
   </div>
   </ng-template>
     <app-group-table
       [groupDisplayedColumns]='this.groupDisplayedColumns'
       [groupDataSource]='this.groupDataSource'
       [licenceSeats]='this.licenceSeats'
       (updateSeats)=' this.updateSeats.emit($event)'
       (showGroupDetails)='this.showGroupDetails.emit($event)'
       (refresh)='this.refresh.emit()'>
      </app-group-table>
   </mat-tab>
  </mat-tab-group>
  </mat-card>
  </div>
  `,
  styleUrls: ['./licence-management-card.component.scss']
})
export class LicenceManagementCardComponent {
  @ViewChild(MatTabGroup) tabGroup: MatTabGroup;
  @Input() userDisplayedColumns: string[];
  @Input() userDataSource: MatTableDataSource<User>;
  @Input() groupDataSource: MatTableDataSource<LicenceGroup>;
  @Input() groupDisplayedColumns: string[];
  @Input() licenceSeats: number;
  @Input() licenceExpiry: number;
  @Input() hasLicenceGroups: boolean;

  @Output() updateSeats = new EventEmitter();
  @Output() showUserDetails = new EventEmitter();
  @Output() showGroupDetails = new EventEmitter();
  @Output() addExternalGroup = new EventEmitter();
  @Output() refresh = new EventEmitter();

  @Output() generateRequest = new EventEmitter();
  @Output() activate = new EventEmitter();

  private _isFiltering = false;
  get isFiltering(): boolean {
    return this._isFiltering;
  }

  seatCount: number;
  expiry: string;

  constructor(public dialog: MatDialog) { }

  enableFiltering() {
    this._isFiltering = true;
  }

  disableFiltering() {
    this._isFiltering = false;
    this.userDataSource.filter = null;
    this.groupDataSource.filter = null;
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim().toLowerCase();
    if ( this.tabGroup.selectedIndex === 0 ) {
      this.userDataSource.filter = filterValue;
    } else {
      this.groupDataSource.filter = filterValue;
    }
  }

  update() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.minWidth = '800px';
    const dialogRef = this.dialog.open(UpdateLicenceDialogComponent, dialogConfig);

   const generateSub = dialogRef.componentInstance.generateRequest.subscribe( (key: String) => {
      this.generateRequest.emit(key);
    });
    const activateSub = dialogRef.componentInstance.activate.subscribe( (file: FileInput) => {
      this.activate.emit(file);
      dialogRef.close();
    });

    dialogRef.afterClosed().pipe(
      take(1)).subscribe(() => {
      generateSub.unsubscribe();
      activateSub.unsubscribe();
    });
  }
}
