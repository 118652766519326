import { NgModule } from '@angular/core';
import { MaterialModule } from 'src/app/modules/material.module';
import { NgxPermissionsModule } from 'ngx-permissions';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { LicenceEntryComponent } from './licence-entry/licence-entry.component';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [ConfirmationDialogComponent, LicenceEntryComponent],
  imports: [
    CommonModule,
    MaterialModule,
    NgxPermissionsModule.forChild(),
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [LicenceEntryComponent],
  entryComponents: [ConfirmationDialogComponent],
})
export class CommonComponentsModule { }
