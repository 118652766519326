import { Component, OnInit, OnDestroy } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { MonitoringService } from 'src/app/core/services/data/request/metric.service';
import { CustomSnackbarService } from 'src/app/core/services/helper/custom-snackbar.service';
import { ApplicationEvent } from 'src/app/models/application-event.model';
import { interval, Subscription } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-monitoring',
  template: `
  <app-monitoring-card
    (downloadAppEvents)='this.onDownloadAppEvents()'
    (downloadSystemLogs)='this.onDownloadSystemLogs()'
    (downloadAll)='this.onDownloadAll()'
    (archiveAppEvents)='this.onArchiveAppEvents()'
    [systemLoggingPath]='this.systemLoggingPath'
    [appEventsLoggingPath]='this.appEventsLoggingPath'
    [applicationEvents]='this.applicationEvents'>
  </app-monitoring-card>
  `
})

export class MonitoringContainerComponent implements OnInit, OnDestroy {

  title = 'Monitoring';
  appEventPollingTimer = interval(60000);
  appEventPollSubscription: Subscription;

  systemLoggingPath: string;
  appEventsLoggingPath: string;
  applicationEvents: ApplicationEvent[];

  constructor(private logger: NGXLogger,
              private monitoringService: MonitoringService,
              public snackbarService: CustomSnackbarService) {

  }

  ngOnInit() {
    this.monitoringService.getApplicationEventsLoggingPath().subscribe(
      data => {
        this.appEventsLoggingPath = data;
      },
      error => {
        this.appEventsLoggingPath = 'Error obtaining application event logging path.';
        this.logger.error(error);
      }
    );

    this.monitoringService.getSystemLoggingPath().subscribe(
      data => {
        this.systemLoggingPath = data;
      },
      error => {
        this.systemLoggingPath = 'Error obtaining system logging path.';
        this.logger.error(error);
      }
    );

    this.startSubscriptions();
  }
  startSubscriptions() {
    this.appEventPollSubscription = this.appEventPollingTimer
      .pipe(
        startWith(0),
        switchMap(() => this.monitoringService.getApplicationEvents())
      )
      .subscribe(
        appEvents => {
          if (appEvents) {
            this.applicationEvents = appEvents;
          } else {
            this.applicationEvents = [];
          }
        },
        error => { console.error(error); }
      );
  }

  ngOnDestroy() {
    this.appEventPollSubscription.unsubscribe();
  }

  onDownloadSystemLogs() {
    this.monitoringService.download('logs')
      .subscribe(
        data => {
          const date = new Date();
          const formattedDate = date.toISOString().split('T')[0];
          const filename = `qts-system-logs_${formattedDate}.zip`;
          const systemBlob = new Blob([data], { type: 'application/zip' });
          this.saveFile(systemBlob, filename);
        }
      );
  }
  onDownloadAll() {
    this.monitoringService.download('all')
      .subscribe(
        data => {
          const date = new Date();
          const formattedDate = date.toISOString().split('T')[0];
          const filename = `qts-files_${formattedDate}.zip`;
          const systemBlob = new Blob([data], { type: 'application/zip' });
          this.saveFile(systemBlob, filename);
        }
      );
  }


   onDownloadAppEvents() {
    this.monitoringService.download('events')
      .subscribe(
        data => {
          const eventBlob = new Blob([data], { type: 'application/csv' });
          this.saveAppEventstoCSV(eventBlob);
        }
      );
  }

  onArchiveAppEvents() {
    this.monitoringService.download('events')
    .subscribe(
      data => {
        const eventBlob = new Blob([data], { type: 'application/csv' });
        this.saveAppEventstoCSV(eventBlob);
        this.monitoringService.archiveAppEvents().subscribe(
          deleteData => {
            this.snackbarService.openSnackBar(
              deleteData.response,
              { verticalPosition: 'bottom', horizontalPosition: 'center', duration: 6000 });
          });
      }
    );
  }

  private saveFile(blob: Blob, filename: string) {
    if (window.navigator.msSaveOrOpenBlob) {
      // IE / Edge
      window.navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      // Chrome / Firefox / Safari / Opera
      const downloadAnchor = document.createElement('a');
      downloadAnchor.href = window.URL.createObjectURL(blob);
      downloadAnchor.download = filename;
      downloadAnchor.style.display = 'none';  // Make sure the element is not visible
      document.body.appendChild(downloadAnchor);
      downloadAnchor.click();
      window.URL.revokeObjectURL(downloadAnchor.href);  // Clean up the URL object
      document.body.removeChild(downloadAnchor);
    }
  }


  private saveAppEventstoCSV(eventBlob: Blob) {
  // Format the date as 'YYYY-MM-DD'
  const date = new Date();
  const formattedDate = date.toISOString().split('T')[0];
  // Example: 'qvs-app-events_2023-04-15.csv'
  const defaultName = `qts-app-events_${formattedDate}.csv`;
    // IE / Edge
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(eventBlob, defaultName);
    } else { // Chrome / Firefox
      const downloadAnchor = document.createElement('a');
      downloadAnchor.href = window.URL.createObjectURL(eventBlob);
      downloadAnchor.download = defaultName;
      document.body.appendChild(downloadAnchor);
      downloadAnchor.click();
      document.body.removeChild(downloadAnchor);
    }
  }
}
