import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { first, map, switchMap, finalize } from 'rxjs/operators';
import { ErrorInfo } from 'src/app/models/error-info.model';

import { AuthorizationService } from '../../../core/services/data/request/auth.service';
import { LicenceService } from 'src/app/core/services/data/request/licence.service';
import { AdminDataService } from 'src/app/core/services/data/data.service';
import { UserService } from 'src/app/core/services/data/request/user.service';
import { FileInput } from 'ngx-material-file-input';
import { CustomSnackbarService } from 'src/app/core/services/helper/custom-snackbar.service';

@Component({
  selector: 'app-login-container',
  styleUrls: ['./login.container.scss'],
  template: `
    <app-login-card
    [licenceNeeded]='isLicenceNeeded'
    [adminNeeded]='isAdminNeeded'
    (loginAttempt)='onLogin($event)'
    (creatAdminAccount)='onCreatAdminAccount($event)'
    (generateRequest)='onGenerateRequest($event)'
    (activate)='onActivate($event)'>
    </app-login-card>
  `
})

export class LoginContainerComponent implements OnInit {

  isLicenceNeeded: boolean;
  isAdminNeeded: boolean;

  constructor(
    private snackBarService: CustomSnackbarService,
    private dataService: AdminDataService,
    private userService: UserService,
    private licenceService: LicenceService,
    private authService: AuthorizationService,
    private router: Router) { }

  ngOnInit() {
    this.getLicenceLoginState();
  }

  private getLicenceLoginState() {
    this.licenceService.isLicenceNeeded()
      .pipe(switchMap(licenceNeeded => {
        return this.userService.isAdminNeeded()
          .pipe(map(hasAdmin => ({ licenceNeeded, hasAdmin })));
      }))
      .subscribe(({ licenceNeeded, hasAdmin }) => {
        this.isLicenceNeeded = licenceNeeded;
        this.isAdminNeeded = !hasAdmin;
      });
  }


  onLogin($event) {
    if (!this.isLicenceNeeded && !this.isAdminNeeded) {
      this.authService.login($event.name, $event.password).pipe(first())
        .subscribe(
          user => {
            this.router.navigateByUrl('dashboard');
          },
          response => {
            const errorInfo: ErrorInfo = response.error;
            if (errorInfo && errorInfo.exception && errorInfo.exception.endsWith('LicenceExpiredException')) {
              this.getLicenceLoginState();
            }
          });
    }
  }

  onActivate(activationFile: FileInput) {
    this.dataService.activate(activationFile).pipe(finalize(() => {
      this.getLicenceLoginState();
    })).subscribe();
  }

  onGenerateRequest(userKey: String) {
    this.licenceService.getOfflineActivationRequestFile(userKey).subscribe((data: any) => {
      const blob = new Blob([data], { type: 'application/octet-stream' });
      const a = document.createElement('a');
      a.href = URL.createObjectURL(blob);
      a.download = 'qts_licence_request.dat';
      a.click();
      this.snackBarService.openSnackBar('Please give this request file to your QRA contact.',
        {
          verticalPosition: 'bottom',
          horizontalPosition: 'left',
          duration: 10000,
          panelClass: ['download']
        });
      this.getLicenceLoginState();
    });
  }

  onCreatAdminAccount($event) {
    if (!this.isLicenceNeeded && this.isAdminNeeded) {
      this.dataService.addAdminUser($event.userName, $event.password)
        .subscribe(
          (result) => {
            this.getLicenceLoginState();
          });
    }
  }
}
