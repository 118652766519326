import { Component, Output, EventEmitter, Inject, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Role } from 'src/app/models/role.model';
import { LicenceGroup } from 'src/app/models/licence-group.model';
import { User } from 'src/app/models/user.model';
import { Provider } from 'src/app/models/provider.model';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { UserGroup } from 'src/app/models/user-group.model';
import { ProviderType } from 'src/app/models/provider-type.enum';

@Component({
  selector: 'app-user-add-dialog',
  template: `
<br>
<mat-vertical-stepper [linear]='false' #stepper>
<div *ngIf ='this.hasNoDirectory'>
<mat-step [stepControl]="chooseDirectory">
<form [formGroup]="chooseDirectory">
<br>
  <ng-template matStepLabel>Choose Identity Provider</ng-template>
  <div mat-dialog-content fxLayout="column">
     <mat-form-field fxFlex>
        <mat-select
        formControlName="userDirectory"
        placeholder="Identity Providers Name"
        (selectionChange)="this.directoryChange()"
        required>
          <mat-option *ngFor="let directory of directories" [value]="directory">
            {{directory?.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
</div>
<div class ="spacer"></div>
</form>
</mat-step>
</div>
<div *ngIf="(chooseDirectory.get('userDirectory').value || this.directoryId)">
<div *ngIf="this.isIntenalDirectory(chooseDirectory.get('userDirectory').value.id)|| this.isIntenalDirectory(this.directoryId)">
<mat-step>
 <form [formGroup]="addUserForm">
 <br>
  <ng-template matStepLabel>Add User Details</ng-template>
  <div  mat-dialog-content fxLayout="column">
   <div>
      <mat-form-field fxFlex>
        <input matInput type="text" placeholder="User Name/Email" formControlName="userName" required>
      </mat-form-field>
   </div>
  <div>
    <mat-form-field fxFlex>
      <input matInput type="password" placeholder="Password" formControlName="userPassword" required>
    </mat-form-field>
  </div>
</div>
<div class ="spacer"></div>
</form>
</mat-step>
</div>
<div *ngIf=" this.isActiveDirectory(chooseDirectory.get('userDirectory').value.id) || this.isActiveDirectory(this.directoryId)">
<mat-step>
 <form [formGroup]="addGroupForm">
 <br>
  <ng-template matStepLabel>Add Group Details</ng-template>
  <div >
  <div class="groupNameContainer">
  <div class="groupInput">
    <mat-form-field fxFlex>
      <input
        #groupNameInput
        matInput
        type="text"
        placeholder="Name"
        [formControl]="groupName"
        [matAutocomplete]="groupAutocomplete"
        required>
      <mat-autocomplete #groupAutocomplete="matAutocomplete">
        <mat-option *ngFor="let option of filteredGroups | async" [value]="option">
          {{option}}
        </mat-option>
      </mat-autocomplete>
      <mat-error *ngIf="groupName.hasError('invalidAdGroup')">{{this.groupName.getError('invalidAdGroup')}}</mat-error>
    </mat-form-field>
    <mat-icon
    matTooltip="Provide suggestions based on entered group name."
    (click)='this.searchForGroups()'>
      <img src='/assets/images/MagnifyIcon.png'/>
  </mat-icon>
  </div>
  <div class="sync">
  <mat-checkbox
  id="syncUsers"
  type="checkbox"
  formControlName="syncUsers">Sync users on adding group</mat-checkbox>
  </div>
</div>
  </div>
<div class ="spacer"></div>
</form>
</mat-step>
</div>
<mat-step [stepControl]="chooseRoles">
<form [formGroup]="chooseRoles">
<br>
  <ng-template matStepLabel>Associate Role</ng-template>
  <div mat-dialog-content fxLayout="row">
     <mat-form-field fxFlex>
        <mat-select formControlName="userRole" placeholder="Role">
          <mat-option *ngFor="let role of this.getAvailableRoles()" [value]="role">
            {{role?.description}}
          </mat-option>
        </mat-select>
      </mat-form-field>
</div>
<div class ="spacer"></div>
</form>
</mat-step>
<mat-step [stepControl]="chooseLicencGroups">
<form [formGroup]="chooseLicencGroups">
  <br>
  <ng-template matStepLabel >Join License Pool</ng-template>
  <div mat-dialog-content fxLayout="row">
     <mat-form-field fxFlex *ngIf='licencePools.length>0; else addPool'>
        <mat-select formControlName="userLicencePools" placeholder="License Pools" multiple>
          <mat-option matTooltip="Licenses in pool: {{pool?.seats}}" *ngFor="let pool of licencePools" [value]="pool">
            {{pool?.name}}
          </mat-option>
          <button  mat-button  matTooltip="Add new License Pool " style="float: right"  (click)="this.addLicencePool.emit()">
            <mat-icon>add</mat-icon>
          </button>
        </mat-select>
      </mat-form-field>
      <ng-template #addPool>
        <button  mat-button  matTooltip="Add new License Pool " style="float: left"  (click)="this.addLicencePool.emit()">
         Add License Pool
        </button>
      </ng-template>
    </div>
  <div class ="spacer"></div>
</form>
</mat-step>
</div>
</mat-vertical-stepper>
<div class ="spacer"></div>
<footer *ngIf='!this.chooseDirectory.invalid || this.directoryId'>
<div fxFlex></div>
  <button mat-button
  [disabled]="this.canSave()"
  (click)="onSaveClick()">Save</button>
</footer>
  `,
  styleUrls: ['./user-add-dialog.component.scss']
})

export class UserAddDialogComponent implements OnInit {
  @ViewChild('groupNameInput') groupNameInput: ElementRef;
  @Output() addUser = new EventEmitter();
  @Output() addGroup = new EventEmitter();
  @Output() addLicencePool = new EventEmitter();
  @Output() groupAdded = new EventEmitter();
  @Output() searchGroups = new EventEmitter();

  chooseDirectory: FormGroup;
  addUserForm: FormGroup;
  addGroupForm: FormGroup;
  chooseRoles: FormGroup;
  chooseLicencGroups: FormGroup;
  addForm: FormGroup;
  directories: Provider[];
  defaultDirectory: Provider;
  roles: Role[];
  groupOptions: string[] = [];
  licencePools: LicenceGroup[];
  directoryId: number;
  hasNoDirectory: boolean;
  groupName: FormControl;
  syncUsers: FormControl;
  filteredGroups: Observable<string[]>;

  constructor(
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<UserAddDialogComponent>) {
    this.groupOptions = this.data.groupOptions;
    this.roles = data.roles;
    this.licencePools = data.licencePools;
    this.directoryId = data.directoryId;
    this.directories = data.directories;
    this.hasNoDirectory = data.directoryId === null;
    this.groupName = new FormControl('', [Validators.required, this.validAdGroupName()]);
    this.syncUsers = new FormControl(false, []);
    this.addGroupForm = this.formBuilder.group({ syncUsers: this.syncUsers, groupName: this.groupName });
    if (data.directoryId != null) {
      this.defaultDirectory = data.directories[this.directories.findIndex(p => p.id === this.directoryId)];
    } else {
      this.defaultDirectory = data.directories[0];
    }

    this.filteredGroups = this.groupName.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );
  }

  ngOnInit(): void {
    this.chooseDirectory = this.formBuilder.group({
      userDirectory: [this.defaultDirectory, Validators.required]
    });
    this.addUserForm = this.formBuilder.group({
      userPassword: ['', [Validators.required]],
      userName: ['', [Validators.required]],
    });
    this.chooseRoles = this.formBuilder.group({
      userRole: ['', Validators.required]
    });
    this.chooseLicencGroups = this.formBuilder.group({
      userLicencePools: []
    });
  }

  directoryChange() {
    this.groupName.setValue('');
    this.updateGroupOptions([]);
  }
  canSave(): boolean {
    if (this.isActiveDirectory(this.chooseDirectory.get('userDirectory').value.id) ||
      this.isActiveDirectory(this.directoryId)) {
      return this.chooseRoles.invalid || this.groupName.invalid;
    }
    return this.chooseRoles.invalid || this.addUserForm.invalid;
  }

  getAvailableRoles(): Role[] {
    if (this.isActiveDirectory(this.chooseDirectory.get('userDirectory').value.id)
      || this.isActiveDirectory(this.directoryId)) {
      return this.roles.filter(r => r.name !== 'tenantadmin');
    }
    return this.roles;
  }

  searchForGroups() {
    const groupName = this.groupName.value;
    if (groupName) {
      const id = this.chooseDirectory.get('userDirectory').value ?
        this.chooseDirectory.get('userDirectory').value.id : this.directoryId;
      this.searchGroups.emit({ id: id, groupName: groupName });
    }
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.groupOptions.filter(group => group.toLowerCase().indexOf(filterValue) >= 0);
  }

  updateGroupOptions(options: string[]) {
    this.groupOptions = options;
    // Trigger valueChanges so autocomplete options display without requiring an input change.
    this.groupName.updateValueAndValidity({ onlySelf: false, emitEvent: true });
    if ( this.groupNameInput) {
    this.groupNameInput.nativeElement.focus();
    }
  }

  validAdGroupName(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (this.groupOptions.indexOf(control.value) < 0) {
        return { 'invalidAdGroup': 'Name must be valid AD group' };
      }
      return null;
    };
  }

  onSaveClick() {
    if (this.isActiveDirectory((this.chooseDirectory.get('userDirectory').value.id)) || this.isActiveDirectory(this.directoryId)) {
      this.saveGroup();
    } else {
      this.saveUser();
    }
  }

  saveUser() {
    const user = new User();
    user.directoryId = this.hasNoDirectory ? this.chooseDirectory.get('userDirectory').value.id : this.directoryId;
    user.emailAddress = this.addUserForm.get('userName').value;
    user.password = this.addUserForm.get('userPassword').value;
    user.roles = [this.chooseRoles.get('userRole').value];
    user.licenceGroups = this.chooseLicencGroups.get('userLicencePools').value;
    this.addUser.emit(user);
  }

  saveGroup() {
    const userGroup = new UserGroup();
    userGroup.directoryId = this.hasNoDirectory ? this.chooseDirectory.get('userDirectory').value.id : this.directoryId;
    userGroup.name = this.addGroupForm.get('groupName').value;
    userGroup.licenceGroups = this.chooseLicencGroups.get('userLicencePools').value;
    userGroup.roles = [this.chooseRoles.get('userRole').value];

    const syncUsers = this.addGroupForm.get('syncUsers').value;

    if (this.isActiveDirectory(userGroup.directoryId)) {
      this.addGroup.emit({ sync: syncUsers, group: userGroup });
    }
  }

  onCloseClick(): void {
    this.dialogRef.close();
  }

  isActiveDirectory(id: number): boolean {
    if (id) {
      return this.directories.filter(d => d.id === id && d.type === ProviderType.ActiveDirectory).length > 0;
    }
    return false;
  }

  isIntenalDirectory(id: number): boolean {
    if (id) {
      return this.directories.filter(d => d.id === id && d.type === ProviderType.Internal).length > 0;
    }
    return false;
  }
}
