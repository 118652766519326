import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'src/app/modules/material.module';
import { NgxPermissionsModule } from 'ngx-permissions';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTableModule, MatPaginatorModule, MatSortModule } from '@angular/material';

import { LicenceGroupTableComponent } from './components/licence-group-table.component';
import { TokenConfigComponent } from '../token-config/components/token-config.component';
import { TokenConfigContainerComponent } from '../token-config/token-config.container';
import { TokenConfigDialogComponent } from '../token-config/components/token-config-dialog.component';
import { LicenceManagementCardComponent } from './components/licence-management-card.component';
import { ActiveUserLicenceTableComponent } from './components/active-user-licence-table.component';
import { AddLicenceGroupDialogComponent } from './components/add-licence-group-dialog.component';
import { LicenceManagementContainerComponent } from './licence-management.container';
import { UpdateLicenceDialogComponent } from './components/update-licence-dialog.component';
import { CommonComponentsModule } from 'src/app/modules/feature/common/common.module';

@NgModule({
  declarations: [
    LicenceManagementContainerComponent,
    LicenceManagementCardComponent,
    ActiveUserLicenceTableComponent,
    LicenceGroupTableComponent,
    TokenConfigComponent,
    TokenConfigContainerComponent,
    TokenConfigDialogComponent,
    AddLicenceGroupDialogComponent,
    UpdateLicenceDialogComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    NgxPermissionsModule.forChild(),
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    CommonComponentsModule
  ],

  exports: [LicenceManagementContainerComponent, LicenceManagementCardComponent],
  entryComponents: [TokenConfigDialogComponent, UpdateLicenceDialogComponent, AddLicenceGroupDialogComponent]
})
export class UserGroupModule { }
