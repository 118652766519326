import { Component, OnInit, ViewChild, Output, EventEmitter, OnDestroy } from '@angular/core';
import { MatTableDataSource, MatDialogConfig, MatDialog, MatDialogRef } from '@angular/material';

import { UserManagementCardComponent } from './components/user-management-card.component';
import { UserAddDialogComponent } from './components/user-add-dialog.component';

import { DrawerService } from 'src/app/core/services/helper/drawer.service';
import { LicenceService } from 'src/app/core/services/data/request/licence.service';

import { UserDetailData } from 'src/app/models/user-detail-data.model';
import { LicenceGroup } from 'src/app/models/licence-group.model';
import { Licence } from 'src/app/models/licence.model';
import { Role } from 'src/app/models/role.model';
import { Provider } from 'src/app/models/provider.model';
import { ActiveDirectoryToolsDialogComponent } from './components/active-directory-tools-dialog.component';
import { ActiveDirectoryUser } from 'src/app/models/active-directory-user.model';
import { AuthorizationService } from 'src/app/core/services/data/request/auth.service';
import { HttpResponse } from '@angular/common/http';
import { CustomSnackbarService } from 'src/app/core/services/helper/custom-snackbar.service';
import { DirectoryEditDialogComponent } from './components/directory-edit-dialog.component';
import { UserGroup } from 'src/app/models/user-group.model';
import { AddLicenceGroupDialogComponent } from '../licence-management/components/add-licence-group-dialog.component';
import { ActiveDirectoryService } from 'src/app/core/services/data/request/active-directory.service';
import { Router } from '@angular/router';
import { AdminDataService } from 'src/app/core/services/data/data.service';
import { take, map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-user-management',
  template: `
  <app-user-management-card
  [directories]='this.knownProviders'
  [metaUserDisplayedColumns]='this.metaUserDisplayedColumns'
  [userDisplayedColumns]='this.userDisplayedColumns'
  [userDataSource]='this.userDataSource'
  (showUserDetails)='onShowUserDetails($event)'
  (showGroupDetails)='onShowGroupDetails($event)'
  (configureDirectory)='onConfigureDirectory($event)'
  (addExternalGroup)='onAddUser($event)'
  (addUser)='onAddUser($event)'
  (searchAd)='onSearchAd($event)'
  (deleteDirectory)='onDeleteDirectory($event)'
  (goToProviderDetails)='gotToProviderDetails($event)'
  >
  </app-user-management-card>
  `
})

export class UserManagementContainerComponent implements OnInit, OnDestroy {

  constructor(
    private router: Router,
    private authService: AuthorizationService,
    public dataService: AdminDataService,
    public activeDirectoryService: ActiveDirectoryService,
    public licenceService: LicenceService,
    public drawerService: DrawerService,

    public snackbarService: CustomSnackbarService,
    private dialog: MatDialog
  ) { }

  @ViewChild(UserManagementCardComponent) childView: UserManagementCardComponent;

  @Output() showDetails = new EventEmitter();
  @Output() shownUserUpdate = new EventEmitter();

  private editProviderDialogRef: MatDialogRef<DirectoryEditDialogComponent>;
  private adToolsDialogRef: MatDialogRef<ActiveDirectoryToolsDialogComponent>;
  private _connectionTestResult = null;

  knownProviders: Provider[];
  metaUserDisplayedColumns = ['name', 'role', 'directory'];
  userDisplayedColumns = ['name', 'role'];
  groupDisplayedColumns = ['name', 'role', 'licencegroup'];
  userDataSource = new MatTableDataSource<UserDetailData>();
  groupDataSource = new MatTableDataSource<UserGroup>();
  currentlySelectedUser: UserDetailData;
  currentlySelectedGroup: LicenceGroup;
  licenceSeats: number;
  roles: Role[];
  licencePools: LicenceGroup[];
  internalDirectoryID: number;
  killSubscriptions = new Subject<void>();


  ngOnInit() {
    this.dataService.userDetails
      .pipe(takeUntil(this.killSubscriptions))
      .subscribe(userDetails => {
        this.userDataSource.data = userDetails.sort(function (a, b) { return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0); });
      });

    this.dataService.userGroupDetails
      .pipe(takeUntil(this.killSubscriptions))
      .subscribe(groupDetails => {
        this.groupDataSource.data = groupDetails;
      });

    this.dataService.currentSelectedUserDetails
      .pipe(takeUntil(this.killSubscriptions))
      .subscribe(selectedUser => {
        this.currentlySelectedUser = selectedUser;
      });


    this.dataService.currentSelectedLicenceGroupDetails
      .pipe(takeUntil(this.killSubscriptions))
      .subscribe(selectedGroup => {
        this.currentlySelectedGroup = selectedGroup;
      });

    this.licenceService.getCurrentLicence() //swap this out when the time comes??? should this come from the sataservice? is it even needed here TBD
      .pipe(takeUntil(this.killSubscriptions))
      .subscribe(
        (licence: Licence) => {
          this.licenceSeats = licence.totalSeats;
        },
        () => {
          this.licenceSeats = 0;
        }
      );

    this.dataService.providers
      .pipe(takeUntil(this.killSubscriptions))
      .subscribe(providers => {
        if (!providers) {
          return;
        }
        this.knownProviders = providers;
      });

    this.dataService.roles
      .pipe(takeUntil(this.killSubscriptions))
      .subscribe(roles => {
        this.roles = roles;
      });

  }

  ngOnDestroy(): void {
    this.killSubscriptions.next();
    this.killSubscriptions.complete();
  }

  gotToProviderDetails(provider: Provider) {
    this.router.navigate(['dashboard/provider/', provider.id]);
  }

  onShowUserDetails(user: UserDetailData) {
    this.drawerService.openUserDrawer();
    this.dataService.setCurrentSelectedUserDetails(user);
  }

  onShowGroupDetails(group: UserGroup) {
    this.drawerService.openUserGroupDrawer();
    this.dataService.setCurrentSelectedUserGroupDetails(group);
  }


  closeMe() {
  }


  onAddUser(directoryId: number) {
    if (directoryId === null) {
      if (this.knownProviders.length === 1) {
        directoryId = this.knownProviders[0].id;
      }
    }

    this.dataService.licenceGroupDetails
      .pipe(take(1))
      .subscribe(licencePools => {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.minWidth = '450px';
        dialogConfig.data = {
          roles: this.roles,
          licencePools: licencePools,
          directoryId: directoryId,
          directories: this.knownProviders,
          groupOptions: [],
        };

        const addUserDialogRef = this.dialog.open(UserAddDialogComponent, dialogConfig);
        const killSubscriptions = new Subject<void>();
        addUserDialogRef.beforeClosed().subscribe(_ => {
          killSubscriptions.next();
          killSubscriptions.complete();
        });

        addUserDialogRef.componentInstance.addGroup
          .subscribe((event: any) => {
            this.dataService.addUserGroup(event.group)
              .pipe(map(id => {
                if (event.sync) {
                  event.group.id = id;
                  this.dataService.syncUserGroup(event.group);
                }
                addUserDialogRef.close();
              })).subscribe();
          });


        addUserDialogRef.componentInstance.addUser
          .subscribe((newUser: any) => {
            this.dataService.addUser(newUser).subscribe(_ => {
              addUserDialogRef.close();
            });
          });


        addUserDialogRef.componentInstance.searchGroups.subscribe(
          (value) => {
            this.activeDirectoryService.searchForGroups(value.id, value.groupName).subscribe(
              (groups: string[]) => {
                addUserDialogRef.componentInstance.updateGroupOptions(groups);
              }
            );
          }
        );
        addUserDialogRef.componentInstance.addLicencePool.subscribe(() => {
          const dialogConfig2 = new MatDialogConfig();
          dialogConfig2.minWidth = '400px';
          dialogConfig2.data = { groupOptions: [] };
          const addGroupDialogRef = this.dialog.open(AddLicenceGroupDialogComponent, dialogConfig2);
          addGroupDialogRef.componentInstance.groupAdded.subscribe(
            (group: LicenceGroup) => {
              this.dataService.addLicenceGroup(group)
                .subscribe(
                  () => {
                    this.dataService.licenceGroupDetails
                      .pipe(takeUntil(killSubscriptions))
                      .subscribe(pools => {
                        addUserDialogRef.componentInstance.licencePools = pools;
                      });
                  });
              addGroupDialogRef.close();
            }
          );
        }
        );
      });
  }

  onAddLicencePool() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.minWidth = '400px';
    dialogConfig.data = { groupOptions: [] };
    const addGroupDialogRef = this.dialog.open(AddLicenceGroupDialogComponent, dialogConfig);

    addGroupDialogRef.componentInstance.groupAdded.subscribe(
      (group: LicenceGroup) => {
        this.dataService.addLicenceGroup(group).subscribe(
          () => {
            addGroupDialogRef.close();
          }
        );
      }
    );
  }


  get connectionTestResult(): string {
    return this._connectionTestResult;
  }

  onConfigureDirectory(directory: Provider) {
    this._connectionTestResult = null;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '650px';
    dialogConfig.data = { directory: directory, connection: this.connectionTestResult };

    this.editProviderDialogRef = this.dialog.open(DirectoryEditDialogComponent,
      dialogConfig);


    this.editProviderDialogRef.componentInstance.testConnection.subscribe((userDirectory: Provider) => {
      if (userDirectory) {
        if (this.authService.isLoggedIn()) {
          this.activeDirectoryService.testProviderConnection(userDirectory).subscribe(response => {
            if (response instanceof HttpResponse) {
              this.editProviderDialogRef.componentInstance.connection = 'Pass';
            }
          },
            error => {
              this.editProviderDialogRef.componentInstance.connection = 'Fail';
            }
          );
        }
      }
    });

    this.editProviderDialogRef.componentInstance.updateProvider.subscribe((provider: Provider) => {
      if (provider) {
        this.dataService.connectToProvider(provider).subscribe(responseDirectory => {
          if (responseDirectory) {
            this.snackbarService.openSnackBar(
              provider.name + ' Directory successfully saved',
              { verticalPosition: 'bottom', horizontalPosition: 'center', duration: 6000 });
            this.editProviderDialogRef.componentInstance.connection = 'Pass';
          }
          this.editProviderDialogRef.componentInstance.onCloseClick();
        },
          error => {
            this.editProviderDialogRef.componentInstance.connection = 'Fail';
          }
        );
      }
    });

    this.editProviderDialogRef.beforeClosed().subscribe((result) => {
      this.editProviderDialogRef.componentInstance.data.connection = null;
    });
  }

  onSearchAd(directoryID: number) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '80%';
    dialogConfig.minWidth = '300px';
    dialogConfig.height = '85vh';
    this.adToolsDialogRef = this.dialog.open(ActiveDirectoryToolsDialogComponent, dialogConfig);

    this.adToolsDialogRef.componentInstance.searchAd.subscribe((search) => {
      this.activeDirectoryService.searchActiveDirectory(directoryID, search).subscribe((response: ActiveDirectoryUser[]) => {
        this.updateAdSearchResults(response);
      });
    });
  }

  updateAdSearchResults(response: ActiveDirectoryUser[]): any {
    if (this.adToolsDialogRef && this.adToolsDialogRef.componentInstance) {
      this.adToolsDialogRef.componentInstance.updateSearchResults(response);
    }
  }

  onDeleteDirectory(directoryId: number) {
    this.dataService.deleteProvider(directoryId);
    this.drawerService.closeOpenDrawers();
  }

}
