import { Component, Output, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatTableDataSource } from '@angular/material';

import { LicenceGroup } from 'src/app/models/licence-group.model';
import { ConfirmationDialogComponent } from 'src/app/modules/feature/common/confirmation-dialog/confirmation-dialog.component';

import { UserDetailData } from 'src/app/models/user-detail-data.model';
import { UserGroup } from 'src/app/models/user-group.model';
import { Directory } from 'src/app/models/identity-provider.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PerfectScrollbarComponent, PerfectScrollbarConfigInterface, PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { ProviderType } from 'src/app/models/provider-type.enum';


@Component({
  selector: 'app-licence-group-detail',
  template: `
  <div class="panel" fxLayout="column">
   <div class ="container" flex>
      <form [formGroup]="detailsFormGroup">
         <div mat-dialog-content fxLayout="column">
            <div >
               <h1>
                  <ng-container *ngIf='this.edittingDetails; else nameLabel'>
                     <mat-form-field>
                        <input matInput
                           type="text"
                           autocomplete="off"
                           formControlName="poolName"
                           value='{{this.currentLicenceGroup?.name}}'>
                     </mat-form-field>
                  </ng-container>
                  <ng-template #nameLabel>
                     {{this.currentLicenceGroup?.name}}
                  </ng-template>
               </h1>
            </div>
            <div class="spaced">
               <b>License Allotment:</b>
               &nbsp;
               <ng-container *ngIf='this.edittingDetails;else countLabel'>
                  <mat-form-field flex>
                     <input matInput
                        type="number"
                        min="0"
                        formControlName="poolSeats"
                        value='{{this.currentLicenceGroup?.seats}}'>
                  </mat-form-field>
               </ng-container>
               <ng-template #countLabel>
                  {{this.currentLicenceGroup?.seats}}
               </ng-template>
            </div>
            <div class="spaced">
               <b>Licenses Being Used:</b>
               &nbsp;
               {{currentLicenceGroup?.activeSeats}}
            </div>
            <div class="spaced" *ngIf="this.hasAdProvider()" fxLayout="column" fxLayoutAlign="stretch">
               <b>Associated Active Directory Groups:</b>
       <perfect-scrollbar flex style="max-height: 10vh;" [config]="this.config">
                  <ng-container *ngIf="this.formUserGroups?.length;else no_groups">
                     <ng-container
                        *ngFor="let group of this.formUserGroups; let i = index">
                        <div>
                           <button mat-flat-button [autofocus]="false"  (click)="this.showUserGroupDetails.emit(group)">
                           {{group.name}}&nbsp;[{{group.roles[0].description}}]
                           </button>
                           <button class="remove-icon"
                           mat-icon-button
                           *ngIf="this.edittingDetails"
                           (click)="this.removeGroup(group)">
                           <mat-icon>remove</mat-icon>
                           </button>
                        </div>
                     </ng-container>
                  </ng-container>
                  <ng-template #no_groups>
                     <div class="spaced">
                        (No User Groups Assigned to Pool)
                     </div>
                  </ng-template>
               </perfect-scrollbar>
            </div>
            <div class="spaced" fxLayout="column" fxLayoutAlign="stretch" >
               <b> Associated Users: </b>
               <br>
               <perfect-scrollbar flex style="max-height: 20vh;" [config]="this.config">
                  <ng-container *ngIf="formUsers?.length;else no_users">
                     <ng-container *ngFor="let user of formUsers; let i = index">
                        <div flex>
                           <button mat-flat-button [autofocus]="false" (click)="this.showUserDetails.emit(user)">
                           {{user.name}}
                           </button>
                           <button class="remove-icon"
                           mat-icon-button
                           *ngIf="this.edittingDetails && isNotADUser(user)"
                           (click)="this.removeUser(user)">
                           <mat-icon>remove</mat-icon>
                           </button>
                        </div>
                     </ng-container>
                  </ng-container>
                  <ng-template #no_users>
                     (No Users Assigned to Pool)
                  </ng-template>
               </perfect-scrollbar>
            </div>
            <div class="spaced" fxLayout="column" fxLayoutAlign="stretch" style="max-height: 20vh;">
               <b>Currently Active Pool Members:</b>
               <app-pool-active-user-table
               [displayedColumns]='this.userDisplayedColumns'
               [userDataSource]='this.getGroupSessions(this.activeUsers, this.currentLicenceGroup)'
               (showUserDetails)='this.showUserDetails.emit($event)'
               (refresh)='this.refresh.emit()'>
               </app-pool-active-user-table>
            </div>
         </div>
      </form>
   </div>
   <br />
   <br>
   <mat-divider></mat-divider>
   <div align="end">
      <button  mat-button (click)="save()" *ngIf="edittingDetails">SAVE</button>
      <div fxFlex></div>
      <button  mat-button (click)="delete()">DELETE</button>
      <button  mat-button (click)="toggleEnabled()">EDIT</button>
      <button  mat-button (click)="close()">CLOSE</button>
   </div>
</div>
  `,
  styleUrls: ['./licence-group-detail.component.scss']
})

export class LicenceGroupDetailComponent implements OnInit {

  @ViewChild(PerfectScrollbarComponent) componentRef?: PerfectScrollbarComponent;
  @ViewChild(PerfectScrollbarDirective) directiveRef?: PerfectScrollbarDirective;

  @Input() userDisplayedColumns: string[];
  @Input() activeUsers: UserDetailData[];
  @Input() providers: Directory[];
  @Input() currentLicenceGroup: LicenceGroup;
  @Input()
  set userGroups(userGroups: UserGroup[]) {
    if (userGroups) {
      this.originalUserGroups = userGroups;
      this.formUserGroups = userGroups;
    }
  }
  @Input()
  set users(users: UserDetailData[]) {
    if (users) {
      this.formUsers = users;
      this.originalUsers = users;
    }
  }

  @Output() closeGroupDetails = new EventEmitter();
  @Output() deleteGroupDetails = new EventEmitter();
  @Output() showUserDetails = new EventEmitter();
  @Output() showActiveSessionDetails = new EventEmitter();
  @Output() showUserGroupDetails = new EventEmitter();
  @Output() editLicencePool = new EventEmitter();
  @Output() editUserGroups = new EventEmitter();
  @Output() editUsers = new EventEmitter();
  @Output() refresh = new EventEmitter();

  public config: PerfectScrollbarConfigInterface = {};

  selectedRole: string;
  detailsFormGroup: FormGroup;
  edittingDetails = false;
  formUserGroups: UserGroup[] = [];
  originalUserGroups: UserGroup[] = [];
  formUsers: UserDetailData[] = [];
  originalUsers: UserDetailData[] = [];

  constructor(
    private dialog: MatDialog,
    private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.detailsFormGroup = this.formBuilder.group({
      poolName: ['', [Validators.required]],
      poolSeats: ['', [Validators.required]]
    });
  }

  hasAdProvider(): boolean {
    return this.providers.filter(provider => provider.type === ProviderType.ActiveDirectory).length > 0;
  }

  removeGroup(group: UserGroup) {
    this.formUserGroups = this.formUserGroups.filter(g => g.id !== group.id);
  }

  removeUser(user: UserDetailData) {
    this.formUsers = this.formUsers.filter(u => u.emailAddress !== user.emailAddress);
  }

  toggleEnabled() {
    this.edittingDetails = !this.edittingDetails;
    if (!this.edittingDetails) {
      this.detailsFormGroup.reset();
      this.userGroups = this.originalUserGroups;
      this.users = this.originalUsers;
    }
  }


  isNotADUser(user: UserDetailData) {
    const provider = this.providers.find(p => p.id === user.directoryId);
    if (provider) {
      return provider.type !== ProviderType.ActiveDirectory;
    }
    return false;
  }

  getGroupSessions(users: UserDetailData[], group: LicenceGroup) {
    const dataSource = new MatTableDataSource<UserDetailData>();
    if (group) {
      dataSource.data = users.filter(u =>
        u.assignedPool === group.name);
      return dataSource;
    }
    return dataSource;
  }

  save() {
    const newName: string = this.detailsFormGroup.get('poolName').value || this.currentLicenceGroup.name;
    let newSeats: number = this.detailsFormGroup.get('poolSeats').value || this.currentLicenceGroup.seats;
    // for some reason it will not set from the form if min value
    if (this.detailsFormGroup.get('poolSeats').value === 0) {
      newSeats = this.detailsFormGroup.get('poolSeats').value;
    }
    if (newName !== this.currentLicenceGroup.name ||
      newSeats !== this.currentLicenceGroup.seats) {
      const group: LicenceGroup = Object.assign({}, this.currentLicenceGroup);
      group.name = newName;
      group.seats = newSeats;
      this.editLicencePool.emit(group);
    }

    if (this.formUserGroups.length !== this.originalUserGroups.length) {
      const userGroupsToRemove = this.originalUserGroups.filter(item => this.formUserGroups.indexOf(item) < 0);
      userGroupsToRemove.forEach((group) => {
        group.licenceGroups.forEach((licenceGroup, index) => {
          if (licenceGroup.id === this.currentLicenceGroup.id) {
            group.licenceGroups.splice(index, 1);
          }
        });
      });

      this.editUserGroups.emit(userGroupsToRemove);

    }

    if (this.formUsers.length !== this.originalUsers.length) {
      const usersToRemove = this.originalUsers.filter(item => this.formUsers.indexOf(item) < 0);
      usersToRemove.forEach(user => {
        user.licenceGroups = user.licenceGroups.filter(lg => lg.id !== this.currentLicenceGroup.id);
      });

      this.editUsers.emit(usersToRemove);
    }

    this.toggleEnabled();
  }

  delete() {
    const confirmDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: 'auto',
      data: {
        title: 'Delete License Pool ',
        content: 'Are you sure you want to delete ' + this.currentLicenceGroup.name + ' from QTS?',
        confirmText: 'Delete',
        rejectText: 'Cancel'
      }
    });

    confirmDialogRef.componentInstance.confirmation.subscribe(_ => {
      this.deleteGroupDetails.emit(this.currentLicenceGroup);
      confirmDialogRef.close();
    });
  }

  close() {
    if (this.edittingDetails) {
      this.toggleEnabled();
    }
    this.closeGroupDetails.emit();
  }

}
