import { Component, EventEmitter, Inject, OnInit, Output, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { LicenceGroup } from 'src/app/models/licence-group.model';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-add-licence-group-dialog',
  template: `
  <h1 mat-dialog-title>Add License Pool</h1>
  <div mat-dialog-content fxLayout="column">
    <form [formGroup]="addGroupForm">
      <div class="groupNameContainer">
        <div class="groupInput">
          <mat-form-field>
            <input
              #groupNameInput
              matInput
              type="text"
              placeholder="Name"
              [formControl]="groupName"
              required>
          </mat-form-field>
        </div>
      </div>
      <div>
        <mat-form-field fxFlex>
          <input matInput
                  type="number"
                  ng-pattern="onlyNumbers"
                  min="0"
                  autocomplete="off"
                  placeholder="Number of Licenses in Pool"
                  [formControl]="licenceCount"
                  required>
        </mat-form-field>
      </div>
    </form>
  </div>
  <footer>
    <div mat-dialog-actions align="end">
      <button mat-button [disabled]="addGroupForm.invalid"  (click)="onSaveClick()">Save</button>
      <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Close</button>
    </div>
  </footer>
  `,
  styleUrls: ['./add-licence-group-dialog.component.scss']
})
export class AddLicenceGroupDialogComponent implements OnInit {
  @ViewChild('groupNameInput') groupNameInput: ElementRef;

  @Output() groupAdded = new EventEmitter();

  addGroupForm: FormGroup;
  displaySeats = false;
  validGroup = false;
  groupOptions: string[] = [];
  filteredGroups: Observable<string[]>;
  groupName: FormControl;
  licenceCount: FormControl;

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<AddLicenceGroupDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: {
      roles: { value: string, display: string, hasSeats: boolean }[], groupOptions: string[]
    }) {
    this.groupName = new FormControl('', [Validators.required/*, this.validAdGroupName()*/]);
    this.licenceCount = new FormControl(0, [Validators.min(0), Validators.required]);
    this.addGroupForm = this.formBuilder.group({ groupName: this.groupName, licenceCount: this.licenceCount });
    this.groupOptions = this.data.groupOptions;
  }

  ngOnInit() {
  }

  onSaveClick() {
    const group = new LicenceGroup();
    group.name = this.groupName.value;
    group.seats = this.addGroupForm.value.licenceCount;
    this.groupAdded.emit(group);
  }

}
