import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-login',
  template: `
  <form class='form'>
  <div fxLayout="column">
    <div class="left-column" fxLayout="column wrap" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
      <div class="input-row" fxLayout="row wrap" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
        <mat-form-field fxFlex>
           <input matInput type="text" placeholder="User Name/Email" [(ngModel)]="userName" name="userName" required>
        </mat-form-field>
        <mat-form-field fxFlex>
          <input matInput  type="password"  placeholder="Password"
            [(ngModel)]="password" name="password" required>
          </mat-form-field>
          <button mat-button (click)="attemptLogin()">LOGIN</button>
      </div>
    </div>
    <p class= 'message'>Forgot User Name and Password <mat-icon
      matTooltip="Please contact your QTS Admin for assistance.">
      <img src='/assets/images/QuestionMark.png' />
      </mat-icon>
    </p>
  </div>
  </form>
  `,
  styleUrls: ['./login-entry.component.scss']
})
export class LoginEntryComponent {

  @Output() loginAttempt = new EventEmitter();

  userName: string;
  password: string;

  constructor() { }

  attemptLogin() {
    if (this.userName && this.password) {
      this.loginAttempt.emit({ name: this.userName, password: this.password });
    }
  }

}
